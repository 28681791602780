import React, {useState} from 'react'
import {Speaker} from 'Event/Speakers'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {useConfig} from 'organization/Event/Configurable'
import {Controller} from 'react-hook-form'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import TextField from 'lib/ui/TextField'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Button from 'lib/ui/Button'
import {uuid} from 'lib/uuid'
import {useTownhallTemplate} from 'Event/template/Townhall'
import styled from 'styled-components'

export default function SpeakerConfig(props: {speaker: Speaker; id: string}) {
  const {featuredSpeakers} = useTownhallTemplate()
  const {speaker, id} = props
  const [rules, setRules] = useState(speaker.rules)
  const {toggle} = useConfig()

  const set = useSaveTemplate()

  const handleSave = (data: any) => {
    const saveData = {
      speakers: {
        ...data.speakers,
        items: {
          [id]: {
            ...data.speakers.items[id],
            rules,
          },
        },
      },
    }

    set(saveData)
  }

  const handleRemove = () => {
    set({
      speakers: {
        items: {
          [props.id]: REMOVE,
        },
      },
    })
  }

  const handleMoveToFeaturedSpeaker = () => {
    const numSpeakers = Object.keys(featuredSpeakers.items).length
    const position = numSpeakers + 1
    const id = uuid()

    set({
      speakers: {
        items: {
          [props.id]: REMOVE,
        },
      },
      featuredSpeakers: {
        items: {
          [id]: {
            ...speaker,
            position,
          },
        },
      },
    })

    toggle()
  }

  return (
    <Config title="Speaker Config" onSave={handleSave} onRemove={handleRemove}>
      <SettingsPanel>
        <Settings
          {...props}
          onMoveToFeaturedSpeaker={handleMoveToFeaturedSpeaker}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules} />
    </Config>
  )
}

function Settings(props: {
  speaker: Speaker
  id: string
  onMoveToFeaturedSpeaker: () => void
}) {
  const {speaker, id, onMoveToFeaturedSpeaker} = props
  const {
    form: {control, register},
  } = useConfig()
  return (
    <>
      <Controller
        name={`speakers.items.${id}.isActive`}
        control={control}
        defaultValue={speaker.isActive ?? true}
        render={({onChange, value}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <TextField
        name={`speakers.items.${id}.name`}
        label="Speaker Name"
        required
        fullWidth
        inputProps={{
          ref: register,
          'aria-label': 'set speaker name',
        }}
        defaultValue={speaker.name}
      />

      <Controller
        name={`speakers.items.${id}.text`}
        defaultValue={speaker.text || ''}
        control={control}
        render={({onChange, value}) => (
          <StyledTextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </StyledTextEditorContainer>
        )}
      />

      <Controller
        name={`speakers.items.${id}.image`}
        control={control}
        defaultValue={speaker.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Image"
            uploadInputProps={{
              'aria-label': 'speaker image upload',
            }}
            width={300}
            height={300}
            canResize
            disableAutoRemove
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onMoveToFeaturedSpeaker}
      >
        Move to Featured Speakers
      </Button>
    </>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
