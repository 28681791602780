import {Controller} from 'react-hook-form'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import styled from 'styled-components'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {Label} from 'lib/ui/typography'
import FontSelect from 'lib/FontSelect'
import {
  DEFAULT_BUTTON_TEXT_COLOR,
  DEFAULT_BUTTON_BACKGROUND_COLOR,
} from 'lib/ui/Button/CustomButton'
import Checkbox from 'lib/ui/Checkbox'
import Visible from 'lib/ui/layout/Visible'

const DEFAULT_BUTTON_HEIGHT = 64
const DEFAULT_FONT_SIZE = 29

export default function SubmitButtonConfig() {
  const save = useSaveTemplate()
  const {login} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Submit Button" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Submit Label"
          name="login.submitButton.label"
          defaultValue={login.submitButton.label}
          inputProps={{
            'aria-label': 'submit button label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}

export function StyleFields() {
  const {
    form: {control, watch},
  } = useConfig()

  const {login} = useTemplate()
  const showingHoverBackgroundInput = !watch(
    'disableHover',
    login.submitButton.disableHover,
  )

  return (
    <>
      <CollapsibleSection
        storageKey="login.config.styling.typography"
        label="Typography"
      >
        <Label>Font</Label>
        <Controller
          control={control}
          name="login.submitButton.font"
          defaultValue={login.submitButton.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name="login.submitButton.fontSize"
          defaultValue={login.submitButton.fontSize || DEFAULT_FONT_SIZE}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Font Size"
            />
          )}
        />
        <Controller
          name="login.submitButton.textColor"
          control={control}
          defaultValue={
            login.submitButton.textColor || DEFAULT_BUTTON_TEXT_COLOR
          }
          render={({value, onChange}) => (
            <ColorPicker
              label="Text Color"
              color={value}
              onPick={onChange}
              aria-label="submit button text color"
            />
          )}
        />
        <Controller
          name="login.submitButton.hoverTextColor"
          control={control}
          defaultValue={
            login.submitButton.hoverTextColor || DEFAULT_BUTTON_TEXT_COLOR
          }
          render={({value, onChange}) => (
            <ColorPicker
              label="Text Hover Color"
              color={value}
              onPick={onChange}
              aria-label="text hover color"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        storageKey="login.config.styling.background"
        label="Background"
      >
        <Controller
          name="login.submitButton.backgroundColor"
          control={control}
          defaultValue={
            login.submitButton.backgroundColor ||
            DEFAULT_BUTTON_BACKGROUND_COLOR
          }
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="login.submitButton.disableHover"
          control={control}
          defaultValue={login.submitButton.disableHover || false}
          render={({value, onChange}) => (
            <StyledCheckBox
              label="Customize Hover Background"
              // Because we're toggling 'enable' here, but the prop is a 'disable', we need
              // to invert the values here.
              checked={!value}
              onChange={(val) => onChange(!val)}
              aria-label="toggle custom hover background"
            />
          )}
        />
        <Visible when={showingHoverBackgroundInput}>
          <Controller
            name="login.submitButton.hoverBackgroundColor"
            control={control}
            defaultValue={
              login.submitButton.hoverBackgroundColor ||
              DEFAULT_BUTTON_BACKGROUND_COLOR
            }
            render={({value, onChange}) => (
              <BackgroundPicker
                label="Hover Background"
                background={value}
                onChange={onChange}
              />
            )}
          />
        </Visible>
      </CollapsibleSection>
      <CollapsibleSection label="Size" storageKey="login.config.styling.size">
        <Controller
          name="login.submitButton.height"
          defaultValue={login.submitButton.height || DEFAULT_BUTTON_HEIGHT}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={400}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Height"
            />
          )}
        />
        <Controller
          name="login.submitButton.width"
          defaultValue={login.submitButton.width || 12}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={12}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              unit="px"
              label="Width"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Border"
        storageKey="login.config.styling.border"
      >
        <Controller
          name="login.submitButton.borderColor"
          control={control}
          defaultValue={login.submitButton.borderColor || '#000000'}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="login.submitButton.hoverBorderColor"
          control={control}
          defaultValue={login.submitButton.hoverBorderColor || '#000000'}
          render={({value, onChange}) => (
            <ColorPicker label="Hover Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="login.submitButton.borderWidth"
          defaultValue={login.submitButton.borderWidth || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Width"
            />
          )}
        />
        <Controller
          name="login.submitButton.borderRadius"
          defaultValue={login.submitButton.borderRadius || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Radius"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
