import React, {useEffect, useState} from 'react'
import {useQueryParams} from 'lib/url'
import {useParams} from 'react-router-dom'
import {
  Answer,
  Block,
  Completion,
  Question,
  useObieService,
  useAnswerSetCreate,
} from 'organization/Obie/ObieServiceProvider'
import styled, {keyframes} from 'styled-components'
import Form from 'organization/Obie/Blocks/ProcessForm/Form'
import {ReactComponent as Bot} from 'assets/images/icons/white-bot.svg'
import Grid from 'lib/ui/Grid'
import {ArrowRight} from 'lib/ui/Icon'
import ProgressBar from 'organization/Obie/Blocks/ProcessForm/ProgressBar'
import {
  getPercentage,
  nextQuestion,
  previousQuestion,
} from 'organization/Obie/Blocks/ProcessForm/question'
import Complete from 'organization/Obie/Blocks/ProcessForm/Complete'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOrganization} from 'organization/OrganizationProvider'

export type QuesionForm = {
  block: Block
  answer_set_id: number
  currentStep: number
  question: Question
  answer?: Answer
  goToNext: (answer?: Answer) => void
  goToPrevious: () => void
  completing: boolean
}

export default function ProcessForm() {
  const {org_id: _, category: category_id, block: block_id} = useParams<{
    org_id: string
    category: string
    block: string
  }>()

  const {answer_set_id, dependencies} = useQueryParams()
  const {routes} = useOrganization()

  const {getBlock, createCompletion} = useObieService()
  const answerSetCreate = useAnswerSetCreate()

  const block = getBlock(parseInt(category_id), parseInt(block_id))

  const [currentStep, setCurrentStep] = useState(0)
  // There are cases where a Block will not have any Questions associated with
  // it - due to it's Prompt being composed of OTHER Completions. In this case,
  // user still needs a Block to trigger the Completion for, so we set this
  // current Block's state to "complete", so that the UI for triggering the
  // Completion is rendered, instead of trying to process through Questions.
  const [completing, setCompleting] = useState(!Boolean(block.questions.length))
  const [completion, setCompletion] = useState<Completion>()

  const [currentAnswerSetId, setCurrentAnswerSetId] = useState(
    answer_set_id ? parseInt(answer_set_id) : 0,
  )

  const [question, setQuestion] = useState(block.questions[0])

  const existingAnswerSet = block.answer_sets.find(
    (set) => set.id === currentAnswerSetId,
  )

  const [answers, setAnswers] = useState<Answer[]>(
    existingAnswerSet?.answers || [],
  )

  const [animation, setAnimation] = useState('slideInFromRight')

  const updateAnswers = (newAnswer: Answer) => {
    if (!currentAnswerSetId) {
      setCurrentAnswerSetId(newAnswer.answer_set_id)
    }

    const isExisting = answers.find(
      (a) => a.question_id === newAnswer.question_id,
    )
    if (!isExisting) {
      const updated = [...answers, newAnswer]
      setAnswers(updated)
    } else {
      const updated = answers.map((a) =>
        a.question_id === newAnswer.question_id ? newAnswer : a,
      )
      setAnswers(updated)
    }
  }

  const goToNext = (newAnswer?: Answer) => {
    setAnimation('slideOutToLeft')
    const nextQues = nextQuestion(question, newAnswer, block.questions)
    setCurrentStep(currentStep + 1)

    // TODO: Refactor the animation code.
    if (!nextQues) {
      setCompleting(true)
      setTimeout(() => {
        setAnimation('slideInFromRight')
      }, 200)
      return
    }
    if (newAnswer) {
      updateAnswers(newAnswer)
    }
    // setAnswer(answers.find((ans) => ans.question_id === nextQues.id))
    setCurrentStep(currentStep + 1)
    setQuestion(nextQues)

    setTimeout(() => {
      setAnimation('slideInFromRight')
    }, 200)
  }

  const goToPrevious = () => {
    const previousQues = previousQuestion(question, block.questions)
    if (!previousQues) {
      return
    }
    setAnimation('slideOutToRight')

    setCurrentStep(currentStep - 1)
    setQuestion(previousQues)

    setTimeout(() => {
      setAnimation('slideInFromLeft')
    }, 200)
  }

  const percentage = getPercentage(question, answers, block.questions)

  useEffect(() => {
    const submitCompletion = () => {
      if (!block.questions.length) {
        answerSetCreate(block.id).then((response) => {
          createCompletion(response.id, dependencies).then((res) => {
            setCompletion(res)
          })
        })
      } else {
        createCompletion(currentAnswerSetId, dependencies).then((res) => {
          setCompletion(res)
        })
      }
    }

    if (completing && !completion) {
      submitCompletion()
    }
  }, [
    answerSetCreate,
    block,
    completing,
    completion,
    createCompletion,
    currentAnswerSetId,
    dependencies,
  ])

  const answer = answers.find((ans) => ans.question_id === question.id)

  return (
    <Page>
      <ProgressBar value={percentage} hidden={completing} />
      <Header>
        <Logo>
          <TitleBox to={routes.obie.root}>
            <IconBox>
              <Bot />
            </IconBox>
            <Title>OBIE</Title>
          </TitleBox>
        </Logo>
      </Header>
      <FormContent
        block={block}
        currentStep={currentStep}
        question={question}
        goToNext={goToNext}
        goToPrevious={goToPrevious}
        answer_set_id={currentAnswerSetId}
        category_id={category_id}
        answer={answer}
        animation={animation}
        completing={completing}
        completion={completion}
      />
    </Page>
  )
}

function FormContent(
  props: QuesionForm & {
    category_id: string
    animation: string
    completion?: Completion
  },
) {
  const {currentStep, question, completing, completion, category_id} = props

  if (completing) {
    return (
      <Content>
        <Complete category_id={category_id} completion={completion} />
      </Content>
    )
  }

  return (
    <Content>
      <FormContainer animation={props.animation}>
        <Grid container spacing={3}>
          <Grid item md={2}>
            <Steper>
              <Title>{currentStep + 1}</Title>
              <IconBox>
                <ArrowRight iconSize={36} />
              </IconBox>
            </Steper>
          </Grid>
          <Grid item md={10}>
            <Title>{question.question}</Title>
            <Form {...props} />
          </Grid>
        </Grid>
      </FormContainer>
    </Content>
  )
}

const Page = styled.div`
  background: #131d34;
  width: 100%;
  overflow-x: hidden;
  min-height: 100%;
`

const Header = styled.div`
  display: flex;
`

const Logo = styled.div`
  display: block;
`

const Steper = styled.div`
  display: flex;
  float: right;
  color: ${(props) => props.theme.colors.white};
  gap: ${(props) => props.theme.spacing[2]};
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleBox = styled(RelativeLink)`
  display: flex;
  justify-content: start;
  width: 100%;
  padding: ${(props) => props.theme.spacing[8]};

  &:hover {
    text-decoration: none !important;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: ${(props) => props.theme.spacing[2]};
`

const Content = styled.div`
  display: block;
  padding-left: ${(props) => props.theme.spacing[30]};
  padding-right: ${(props) => props.theme.spacing[30]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
  }
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOutToLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOutToRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

const FormContainer = styled.div<{animation: string}>`
  animation: ${(props) =>
      props.animation === 'slideInFromRight'
        ? slideInFromRight
        : props.animation === 'slideOutToLeft'
        ? slideOutToLeft
        : props.animation === 'slideInFromLeft'
        ? slideInFromLeft
        : slideOutToRight}
    0.5s forwards;
`
