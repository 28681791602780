import React from 'react'
import {BlockComponentProps} from '../index'
import {usePurchaseForm} from './PurchaseFormProvider'
import CountrySelect from '../../lib/ui/CountrySelect'
import StateSelect from '../../lib/ui/StateSelect'
import {PurchaseFormBlock, PurchaseFormProps} from '.'
import Checkbox from '../../lib/ui/CheckBox'
import {usePurchase} from '../../PurchaseContext'
import SectionLabel from './SectionLabel'

interface BillingAddressFieldsProps extends PurchaseFormBlock {
  TextFieldComponent: React.FC<any>
  BillingFieldsLabelComponent?: React.FC<any>
}

export default function BillingaddressFields(
  props: BillingAddressFieldsProps & BlockComponentProps,
) {
  const {
    TextFieldComponent,
    BillingFieldsLabelComponent = (
      props: PurchaseFormProps & BlockComponentProps,
    ) => (
      <SectionLabel {...props}>{props.billingSectionLabel ?? ''}</SectionLabel>
    ),
    ...config
  } = props

  const {
    useSameAddressForShipping,
    setUseSameAddressForShipping,
    billingAddress1Error,
    billingCountryIdError,
    billingStateIdError,
    billingCityError,
    billingZipPostalError,
    isExistingAttendee,
    ...form
  } = usePurchaseForm()

  const {selectedTicket} = usePurchase()

  return (
    <div>
      <BillingFieldsLabelComponent {...props} />
      <TextFieldComponent
        aria-label={`billing_address address 1`}
        config={config}
        placeholder={'billingAddress1'}
        value={form.billingAddress1}
        onChange={(e: any) => form.setBillingAddress1(e)}
        error={!!billingAddress1Error}
        disabled={isExistingAttendee}
      />
      <TextFieldComponent
        aria-label={`billing_address address 2`}
        config={config}
        placeholder={'billingAddress2'}
        value={form.billingAddress2}
        onChange={(e: any) => form.setBillingAddress2(e)}
        disabled={isExistingAttendee}
      />
      <CountrySelect
        config={config}
        value={form.billingCountryId}
        onChange={(value) => form.setBillingCountryId(value)}
        defaultLabel={config.input.placeholder.country}
        error={!!billingCountryIdError}
        disabled={isExistingAttendee}
      />
      <StateSelect
        config={config}
        value={form.billingStateId}
        onChange={(value) => form.setBillingStateId(value)}
        countryId={form.billingCountryId}
        defaultLabel={config.input.placeholder.state}
        error={!!billingStateIdError}
        disabled={isExistingAttendee}
      />
      <TextFieldComponent
        aria-label={`billing_address city`}
        config={config}
        placeholder={'city'}
        value={form.billingCity}
        onChange={(e: any) => form.setBillingCity(e)}
        error={!!billingCityError}
        disabled={isExistingAttendee}
      />
      <TextFieldComponent
        aria-label={`billing_address postal`}
        config={config}
        placeholder={'zipcode'}
        value={form.billingZipPostal}
        onChange={(e: any) => form.setBillingZipPostal(e)}
        error={!!billingZipPostalError}
        disabled={isExistingAttendee}
      />
      {selectedTicket?.shipping_address_enabled && (
        <div style={{marginBottom: 10}}>
          <Checkbox
            aria-label="shipping same as billing"
            color={config.input.textColor}
            onChange={() =>
              setUseSameAddressForShipping(!useSameAddressForShipping)
            }
            checked={useSameAddressForShipping}
            label={props.questions.sameShippingAddress}
            disabled={isExistingAttendee}
          />
        </div>
      )}
    </div>
  )
}
