import React, {useEffect, useState, useCallback} from 'react'
import {useDeleteFile} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceUpload'
import {Resource} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItem'

import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {v4 as uuid} from 'uuid'
import {REMOVE, useRemoveIfEmpty} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'

export default function ResourceItemConfig(
  props: ComponentConfigProps & {
    resource: Resource
    id?: string
    list: ResourceListProps
  },
) {
  const {resource, id, showing, onClose} = props
  const {list} = props
  const {update: updateItem} = useEditSidebarItem()

  const deleteFile = useDeleteFile()

  const {handleSubmit, control, watch, formState, setValue} = useForm()

  const [rules, setRules] = useState(resource.rules)
  const [isUrl, setIsUrl] = useState(resource.isUrl)
  const [filePath, setFilePath] = useState(resource.filePath)

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(resource.rules)
    setIsUrl(resource.isUrl)
    setFilePath(resource.filePath)
  }, [resource, showing])

  const update = (id: string, data: Resource) => {
    updateItem({
      resources: {
        [id]: data,
      },
    })
  }

  const insert = (newResource: Resource) => {
    const id = uuid()
    const position = Object.keys(list.resources).length + 1
    updateItem({
      resources: {
        [id]: {...newResource, position},
      },
    })
  }

  const save = (
    formData: Pick<
      Resource,
      'name' | 'url' | 'isVisible' | 'icon' | 'actionId'
    >,
  ) => {
    const data: Resource = {
      rules,
      isUrl,
      filePath,
      ...formData,
    }

    if (id !== undefined) {
      update(id, data)
      onClose()
      return
    }

    insert(data)
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: id
      ? {
          resources: {
            [id]: watch(),
          },
        }
      : {},
    when: showing && Boolean(id),
  })

  const remove = useCallback(() => {
    if (id === undefined) {
      throw new Error('Missing resource item index')
    }

    if (resource.filePath) {
      deleteFile(resource.filePath).catch((e) => {
        // Log error, but prevent it from crashing
        // app
        console.error(e)
      })
    }

    onClose()
    updateItem({
      resources: {
        [id]: REMOVE,
      },
    })
  }, [id, updateItem, onClose, deleteFile, resource])

  useRemoveIfEmpty(remove, props.resource, {shouldSkip: !id})

  return (
    <ComponentConfig
      title="Resource"
      onClose={onClose}
      showing={showing}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          resource={resource}
          isUrl={isUrl}
          setIsUrl={setIsUrl}
          filePath={filePath}
          setFilePath={setFilePath}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove resource"
          onClick={remove}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}
