import React from 'react'
import styled from 'styled-components'
import ConfigDrawer, {Title} from 'lib/ui/ConfigDrawer'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import {SidebarIcon, WindowIcon} from 'lib/ui/Icon'
import grey from '@material-ui/core/colors/grey'
import IconButton from 'lib/ui/IconButton'
import Content from 'lib/ui/ConfigPanel/Content'
import {useToggleArray} from 'lib/toggle'
import Dialog, {DialogBody} from 'lib/ui/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import {Panel} from 'organization/Event/DashboardConfig/ComponentConfigPanel/Panel'

export type ConfigPanelProps = {
  title?: string
  open: boolean
  onClose: () => void
  container?: JSX.Element
  children: JSX.Element[]
  className?: string
  isDialogMode?: boolean
}

export default function ConfigPanel(props: ConfigPanelProps) {
  const {open, onClose} = props
  const [isDialogMode, toggleDialogMode] = useToggleArray(props.isDialogMode)

  if (isDialogMode) {
    return (
      <DarkThemeProvider>
        <Dialog
          open={open}
          onClose={onClose}
          disableEnforceFocus
          icons={
            <PanelButton
              aria-label="switch to panel"
              onClick={toggleDialogMode}
            >
              <SidebarIcon color={grey[500]} iconSize={18} />
            </PanelButton>
          }
        >
          <DialogTitle>{props.title}</DialogTitle>
          <StyledDialogBody>
            <DialogContent {...props} />
          </StyledDialogBody>
        </Dialog>
      </DarkThemeProvider>
    )
  }

  return (
    <ConfigDrawer open={open} onClose={onClose}>
      <DarkThemeProvider>
        <Title>{props.title}</Title>
        <DialogButton aria-label="switch to dialog" onClick={toggleDialogMode}>
          <WindowIcon color={grey[500]} iconSize={20} />
        </DialogButton>
        <Content {...props} />
      </DarkThemeProvider>
    </ConfigDrawer>
  )
}

const DialogButton = styled(IconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing[4]};
  right: ${(props) => props.theme.spacing[4]};
`

const PanelButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.spacing[3]};
`

const StyledDialogBody = styled(DialogBody)`
  padding: 0;
`

// In dialogs we want the content to determine the content height,
// so we need to turn OFF absolute positioning.
const DialogContent = styled(Content)`
  ${Panel} {
    position: relative !important;
  }
`
