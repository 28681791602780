import React from 'react'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {useToggle} from 'lib/toggle'
import {PointsSummaryConfig} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/PointsSummary/PointsSummaryConfig'
import Button from '@material-ui/core/Button'
import {RemoveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import Section from 'Event/template/Lefty/Dashboard/Sidebar/Section'
import {
  HasOffset,
  useEditSidebarItem,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import {Ordered} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useRemoveIfEmpty} from 'Event/TemplateUpdateProvider'
import {HasRules} from 'Event/attendee-rules'
import {HasSchedule} from 'lib/ui/layout/Scheduled'

export const POINTS_SUMMARY = 'Points Summary'
export interface PointsSummaryProps
  extends Ordered,
    HasRules,
    HasOffset,
    HasSchedule {
  type: typeof POINTS_SUMMARY
  description: string
  summary?: string
  isEnabled: boolean
  logo: string | null
}

export const createPointsSummary = (): PointsSummaryProps => ({
  type: POINTS_SUMMARY,
  description: '',
  summary: '',
  logo: null,
  isEnabled: true,
  offsetTop: 0,
  offsetBottom: 0,
})

export default function PointsSummary(props: PointsSummaryProps) {
  const template = useLeftyTemplate()
  const {sidebar} = template
  const {summary, description, isEnabled, offsetTop, offsetBottom} = props
  const v = useAttendeeVariables()
  const isEditMode = useEditMode()

  const content = (
    <>
      <PointsLogo logo={props.logo} />
      <Box textColor={sidebar.textColor}>
        <Summary aria-label="points summary" textColor={sidebar.textColor}>
          {v(summary)}
        </Summary>
        <Description textColor={sidebar.textColor}>
          {v(description)}
        </Description>
      </Box>
    </>
  )

  if (!isEditMode && !isEnabled) {
    return null
  }

  if (!isEditMode) {
    return (
      <Section offsetTop={offsetTop} offsetBottom={offsetBottom}>
        {content}
      </Section>
    )
  }

  return <Configurable pointsSummary={props}>{content}</Configurable>
}

function Configurable(props: {
  pointsSummary: PointsSummaryProps
  children: React.ReactElement
}) {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const {remove: removeItem} = useEditSidebarItem()
  const {pointsSummary} = props

  useRemoveIfEmpty(removeItem, pointsSummary)

  return (
    <Section
      offsetTop={pointsSummary.offsetTop}
      offsetBottom={pointsSummary.offsetBottom}
    >
      <PointsSummaryConfig
        showing={configVisible}
        onClose={toggleConfig}
        points={pointsSummary}
      />
      <EditButton
        onClick={toggleConfig}
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        aria-label="edit points summary"
      >
        Edit Points Summary
      </EditButton>
      <RemoveButton size="large" showing onClick={removeItem}>
        Remove Points Summary
      </RemoveButton>
      {props.children}
    </Section>
  )
}

function PointsLogo(props: {logo: string | null}) {
  if (!props.logo) {
    return null
  }
  return <HeaderImage src={props.logo} alt="Points Image" />
}

const Box = styled.div<{textColor: string | null}>`
  color: ${(props) => props.textColor};
`

const HeaderImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: string
    'aria-label'?: string
  }
>((props, ref) => {
  return (
    <div
      className={`ck-content ${props.className}`}
      ref={ref}
      aria-label={props['aria-label']}
      dangerouslySetInnerHTML={{
        __html: props.children,
      }}
    />
  )
})

const Summary = styled(Container)<{
  textColor: string | null
}>`
  color: ${(props) => props.textColor};
  font-weight: bold;
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[8]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    text-align: center;
  }
`

const Description = styled(Container)<{
  textColor: string | null
}>`
  color: ${(props) => props.textColor};
`

const EditButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[2]}!important;
`
