import React from 'react'
import {useForm} from 'react-hook-form'
import {TextProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/Text'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/Text/TextConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/Text/TextConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'

export function TextConfig(
  props: ComponentConfigProps & {
    text: TextProps
  },
) {
  const {showing, onClose, text} = props
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState,
    setValue,
  } = useForm()
  const {update} = useEditSidebarItem()

  const save = (data: TextProps) => {
    update(data)
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: watch(),
    when: showing,
  })

  return (
    <ComponentConfig
      title="Text"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} text={text} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} text={text} />
      </StylingPanel>
      <RulesPanel rules={text.rules} control={control} name="rules">
        <ScheduleInput setValue={setValue} control={control} values={text} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
