import classNames from 'classnames'
import {Font} from '../../lib/font'
import React, {CSSProperties} from 'react'
import {rgba} from '../../lib/color'

export const defaultTextColor = '#FFFFFF'
export const defaultBackgroundColor = '#000000'

export interface ButtonBaseProps {
  id: string
  children: React.ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  disabled?: boolean
  fullWidth?: boolean
  textTransform?: 'uppercase' | 'capitalize'
  backgroundColor?: string
  textColor?: string
  hoverTextColor?: string
  className: string
  hoverBackgroundColor?: string
  disableHover?: boolean
  borderRadius?: number
  borderWidth?: number
  borderColor?: string
  hoverBorderColor?: string
  variant?: 'text'
  opacity?: number
  height?: number | string
  minHeight?: number
  type?: 'submit' | 'button' | 'reset'
  width?: number
  fontSize?: number
  padding?: number
  'aria-label'?: string
  style?: CSSProperties
  disabledBackgroundColor?: string
  disabledTextColor?: string
  typographySecond?: {
    font: Font | null
    fontSize: number
    textColor: string
    hoverTextColor: string
  }
}

export function ButtonBase(props: ButtonBaseProps) {
  const {id} = props
  const className = `button-${id}`
  const color = props.disableHover
    ? rgba(hoverBackgroundColor(props), hoverOpacity(props))
    : hoverBackgroundColor(props)

  return (
    <>
      <style>
        {`
         .${className} .firstRow {
          color: ${textColor(props)} !important;
        }
        .${className} .secondText {
          color: ${textColor(props)} !important;
        }
        .${className}:hover {
          color: ${hoverTextColor(props)}  !important;
          background: ${color}  !important; 
          background-image: ${hoverBackgroundImage(props)}  !important;
          border: ${hoverBorder(props)}  !important;
          text-decoration: ${hoverTextDecoration(props)}  !important;
        }
        .${className}:hover .firstRow {
          color: ${hoverTextColor(props)} !important;
        }
        .${className}:hover .firstRow i {
          color: ${hoverTextColor(props)} !important;
        }
        .${className}:hover .secondText {
          color: ${hoverTextColor({
            ...props,
            textColor: props.typographySecond?.textColor,
            hoverTextColor: props.typographySecond?.hoverTextColor,
          })} !important;
        }
        `}
      </style>
      <button
        style={{
          width: width(props),
          textTransform: textTransform(props),
          padding: padding(props),
          background: backgroundColor(props),
          backgroundImage: backgroundImage(props),
          color: textColor(props),
          border: border(props),
          cursor: cursor(props),
          transition: transition(props),
          borderRadius: borderRadius(props),
          opacity: opacity(props),
          height: height(props),
          minHeight: minHeight(props),
          fontSize: fontSize(props),
          ...props.style,
        }}
        className={classNames(className, props.className)}
        onClick={props.onClick}
        type={props.type ?? 'button'}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </>
  )
}

function width(props: ButtonBaseProps) {
  if (props.width) {
    return `${props.width}%`
  }

  if (props.fullWidth) {
    return '100%'
  }

  return 'auto'
}

function height(props: ButtonBaseProps) {
  if (!props.height) {
    return 'auto'
  }

  if (typeof props.height === 'string') {
    return props.height
  }

  return props.height + 'px'
}

function minHeight(props: ButtonBaseProps) {
  if (!props.minHeight) {
    return 'auto'
  }

  return props.minHeight + 'px'
}

function textTransform(props: ButtonBaseProps) {
  if (props.textTransform) {
    return props.textTransform
  }

  return 'initial'
}

function padding(props: ButtonBaseProps) {
  if (props.padding) {
    return `${props.padding}px!important`
  }

  if (isText(props.variant)) {
    return '0'
  }

  return 'inherit'
}

function textColor(props: ButtonBaseProps) {
  if (props.disabled && props.disabledTextColor) {
    return props.disabledTextColor
  }

  if (props.textColor) {
    return props.textColor
  }

  if (isText(props.variant)) {
    return 'inherit'
  }

  return defaultTextColor
}

function hoverTextColor(props: ButtonBaseProps) {
  if (props.disabled && props.disabledTextColor) {
    return props.disabledTextColor
  }

  if (props.hoverTextColor) {
    return props.hoverTextColor
  }

  return textColor(props)
}

function backgroundColor(props: ButtonBaseProps) {
  if (isText(props.variant)) {
    return 'transparent'
  }

  if (props.disabled && props.disabledBackgroundColor) {
    return props.disabledBackgroundColor
  }

  if (props.backgroundColor) {
    return props.backgroundColor
  }

  return defaultBackgroundColor
}

function backgroundImage(props: ButtonBaseProps) {
  if (
    props.disabled &&
    props.disabledBackgroundColor &&
    props.disabledBackgroundColor?.startsWith('url')
  ) {
    return props.disabledBackgroundColor
  }

  if (props.disabled) {
    return backgroundColor(props)
  }

  if (props.backgroundColor?.startsWith('url')) {
    return props.backgroundColor
  }

  return backgroundColor(props)
}

function cursor(props: ButtonBaseProps) {
  if (props.disabled) {
    return 'not-allowed'
  }

  return 'pointer'
}

function transition(props: ButtonBaseProps) {
  if (props.disableHover || props.variant === 'text') {
    return 'none'
  }

  return 'opacity 0.2s ease-in-out'
}

function hoverOpacity(props: ButtonBaseProps) {
  if (!props.disableHover || props.variant === 'text') {
    return 1
  }

  // If there is no custom hover styles, we'll keep the default
  // of lightening the opacity.
  return 0.8
}

function hoverBackgroundColor(props: ButtonBaseProps) {
  if (props.disableHover) {
    return backgroundColor(props)
  }

  if (props.disabled && props.disabledBackgroundColor) {
    return props.disabledBackgroundColor
  }

  if (props.hoverBackgroundColor && !props.disableHover) {
    return props.hoverBackgroundColor || defaultBackgroundColor
  }

  return backgroundColor(props)
}

function hoverBackgroundImage(props: ButtonBaseProps) {
  if (props.disableHover) {
    return backgroundImage(props)
  }

  if (
    props.disabled &&
    props.disabledBackgroundColor &&
    props.disabledBackgroundColor?.startsWith('url')
  ) {
    return props.disabledBackgroundColor
  }

  if (props.disabled) {
    return hoverBackgroundColor(props)
  }

  if (props.hoverBackgroundColor?.startsWith('url')) {
    return props.hoverBackgroundColor
  }

  return hoverBackgroundColor(props)
}

function borderRadius(props: ButtonBaseProps) {
  if (!props.borderRadius) {
    return '0px'
  }

  return `${props.borderRadius}px`
}

function hoverBorder(props: ButtonBaseProps) {
  if (!props.hoverBorderColor) {
    return border(props)
  }

  return `${props.borderWidth}px solid ${hoverBorderColor(props)}`
}

function border(props: ButtonBaseProps) {
  if (!props.borderWidth) {
    return 'none'
  }

  return `${props.borderWidth}px solid ${borderColor(props)}`
}

function borderColor(props: ButtonBaseProps) {
  if (!props.borderColor) {
    return '#000000'
  }

  return props.borderColor
}

function hoverBorderColor(props: ButtonBaseProps) {
  if (!props.borderColor) {
    return '#000000'
  }

  return props.hoverBorderColor
}

function hoverTextDecoration(props: ButtonBaseProps) {
  if (isText(props.variant) && !props.disableHover) {
    return 'underline'
  }

  return 'none'
}

function isText(variant: ButtonBaseProps['variant']) {
  return variant === 'text'
}

function fontSize(props: ButtonBaseProps) {
  if (props.fontSize) {
    return `${props.fontSize}px`
  }
}

function opacity(props: ButtonBaseProps) {
  if (props.disabled) {
    return 0.6
  }

  if (props.opacity === undefined) {
    return 1
  }

  return props.opacity
}
