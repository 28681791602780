import {Question, Answer} from 'organization/Obie/ObieServiceProvider'

export const TYPE_LONG_FORM = 1
export const TYPE_RADIO_OPTIONS = 2
export const TYPE_PATH_PARENT = 3
export const TYPE_PATH_QUESTION = 4
export const TYPE_MULTIPLE_CHOICE = 5

export function nextQuestion(
  currentQuestion: Question,
  currentAnswer: Answer | undefined,
  questions: Question[],
) {
  if (
    currentQuestion.type === TYPE_LONG_FORM ||
    currentQuestion.type === TYPE_RADIO_OPTIONS ||
    currentQuestion.type === TYPE_MULTIPLE_CHOICE
  ) {
    return nextParentQuestion(currentQuestion, questions)
  }

  if (currentQuestion.type === TYPE_PATH_PARENT && currentAnswer) {
    const selectedOption = currentQuestion.options.find(
      (opt) => opt.value === currentAnswer.answer,
    )

    if (!selectedOption) {
      return null
    }

    if (selectedOption.path.length === 0) {
      return nextParentQuestion(currentQuestion, questions)
    }

    return selectedOption.path[0]
  }

  if (currentQuestion.type === TYPE_PATH_PARENT && !currentAnswer) {
    if (!currentQuestion.required) {
      return nextParentQuestion(currentQuestion, questions)
    }
  }

  if (currentQuestion.type === TYPE_PATH_QUESTION) {
    const next = nextChildQuestion(currentQuestion, questions)
    if (next) {
      return next
    }
    const parentQuestion = questions.find(
      (q) => q.id === currentQuestion.parent_question_id,
    )
    if (!parentQuestion) {
      return null // Bug
    }

    return nextParentQuestion(parentQuestion, questions)
  }

  return null
}

function nextParentQuestion(currentQuestion: Question, questions: Question[]) {
  const index = questions.indexOf(currentQuestion)
  if (index < 0) return null
  const nextIndex = index + 1
  if (nextIndex >= questions.length) {
    return null
  }
  return questions[nextIndex]
}

function nextChildQuestion(currentQuestion: Question, questions: Question[]) {
  const parentQuestion = questions.find(
    (q) => q.id === currentQuestion.parent_question_id,
  )
  if (!parentQuestion) {
    return null // Bug
  }
  const parentSelectOption = parentQuestion.options.find((opt) =>
    opt.path.find((p) => p.id === currentQuestion.id),
  )
  if (!parentSelectOption) {
    return null // bug
  }

  const index = parentSelectOption.path.indexOf(currentQuestion)

  if (index < 0) {
    return null //bug
  }

  const nextIndex = index + 1
  if (nextIndex >= parentSelectOption.path.length) {
    return null
  }
  return parentSelectOption.path[nextIndex]
}

export function previousQuestion(
  currentQuestion: Question,
  questions: Question[],
) {
  if (
    currentQuestion.type === TYPE_LONG_FORM ||
    currentQuestion.type === TYPE_RADIO_OPTIONS ||
    currentQuestion.type === TYPE_MULTIPLE_CHOICE
  ) {
    return previousParentQuestion(currentQuestion, questions)
  }

  if (currentQuestion.type === TYPE_PATH_PARENT) {
    return previousParentQuestion(currentQuestion, questions)
  }

  if (currentQuestion.type === TYPE_PATH_QUESTION) {
    const previous = previousChildQuestion(currentQuestion, questions)
    if (previous) {
      return previous
    }
    const parentQuestion = questions.find(
      (q) => q.id === currentQuestion.parent_question_id,
    )
    if (!parentQuestion) {
      return null // Bug
    }
    return parentQuestion
  }
}

function previousParentQuestion(
  currentQuestion: Question,
  questions: Question[],
) {
  const index = questions.indexOf(currentQuestion)
  if (index < 0) return null
  const nextIndex = index - 1
  if (nextIndex < 0) {
    return null
  }
  return questions[nextIndex]
}

function previousChildQuestion(
  currentQuestion: Question,
  questions: Question[],
) {
  const parentQuestion = questions.find(
    (q) => q.id === currentQuestion.parent_question_id,
  )
  if (!parentQuestion) {
    return null // Bug
  }
  const parentSelectOption = parentQuestion.options.find((opt) =>
    opt.path.find((p) => p.id === currentQuestion.id),
  )
  if (!parentSelectOption) {
    return null // bug
  }

  const index = parentSelectOption.path.indexOf(currentQuestion)

  if (index < 0) {
    return null //bug
  }

  const previousIndex = index - 1
  if (previousIndex < 0) {
    return null
  }
  return parentSelectOption.path[previousIndex]
}

// Need to calculate the process percentage dynamically because of Path_Parent / Path_Question questions.
export function getPercentage(
  currentQuestion: Question,
  answers: Answer[],
  questions: Question[],
) {
  const countsUntil = countsUntilCurrent(
    questions[0],
    currentQuestion,
    answers,
    questions,
  )

  const countsFrom = countsFromCurrent(currentQuestion, questions)

  return (countsUntil * 100) / (countsFrom + countsUntil)
}

// Get the counts of questions that the user answered.
function countsUntilCurrent(
  question: Question,
  currentQuestion: Question,
  answers: Answer[],
  questions: Question[],
): number {
  if (!question) {
    return 0
  }

  if (!question || question.id === currentQuestion.id) {
    return 0
  }

  const answer = answers.find((ans) => ans.question_id === question.id)
  const nextQues = nextQuestion(question, answer, questions)

  if (!nextQues) {
    return 0
  }

  return 1 + countsUntilCurrent(nextQues, currentQuestion, answers, questions)
}

// Get the counts of the questions that the user has to visit.
function countsFromCurrent(currentQuestion: Question, questions: Question[]) {
  if (!currentQuestion) {
    return 0
  }

  if (currentQuestion.type === TYPE_PATH_QUESTION) {
    const parentQuestion = questions.find(
      (q) => q.id === currentQuestion.parent_question_id,
    )
    if (!parentQuestion) {
      return 0 // Bug
    }
    const parentSelectOption = parentQuestion.options.find((opt) =>
      opt.path.find((p) => p.id === currentQuestion.id),
    )
    if (!parentSelectOption) {
      return 0 // bug
    }
    const index = parentSelectOption.path.indexOf(currentQuestion)

    if (index < 0) {
      return 0 //bug
    }

    const countsOfChildren = parentSelectOption.path.slice(index).length

    const nextParentQues = nextParentQuestion(parentQuestion, questions)
    const countsOfParents = countsFromCurrentParentQuestion(
      nextParentQues,
      questions,
    )

    return countsOfChildren + countsOfParents
  }
  return countsFromCurrentParentQuestion(currentQuestion, questions)
}

function countsFromCurrentParentQuestion(
  question: Question | null,
  questions: Question[],
) {
  if (!question) {
    return 0
  }
  // the type of question must be TYPE_PATH_QUESTION
  if (question.type === TYPE_PATH_QUESTION) {
    return 0
  }

  const index = questions.indexOf(question)

  if (index < 0) {
    return 0 // Bug
  }

  return questions.slice(index).length
}
