import React from 'react'
import LinkButton from './LinkButton'
import Container from '../Container'
import {BlockComponentProps} from '../index'
import {Layout} from 'react-grid-layout'
import {
  PurchasePageBlock,
  PurchasePageSection,
  PurchasePageTemplate,
} from '../../purchase-page'

import {ButtonBlock} from './types'
import {HashMap, orderedIdsByPosition} from '../../lib/list'
import {scrollIntoView} from 'seamless-scroll-polyfill'
import {useThankYouPageContext} from '../../ThankYouPageContext'

export type {ButtonBlock}

export default function Button(props: ButtonBlock & BlockComponentProps) {
  const {
    padding: _padding,
    link,
    destination,
    font,
    fontSize,
    textColor,
    template,
    ...otherProps
  } = props

  const {typography} = template
  const {loginUrl} = useThankYouPageContext()

  const targetBlockid = getTargetBlockId(template)

  const getDestination = () => {
    if (destination === 'ticket_selector') {
      return '#'
    }

    if (destination === 'login') {
      return loginUrl || '#'
    }

    return link
  }

  const onClick = () => {
    if (destination === 'ticket_selector' && targetBlockid) {
      const el = document.getElementById(targetBlockid)
      if (el) {
        scrollIntoView(el, {behavior: 'smooth'})
      }
    }
  }

  return (
    <Container {...props}>
      <LinkButton
        template={props.template}
        link={getDestination()}
        onClick={onClick}
        font={font || typography.font}
        fontSize={fontSize || typography.fontSize}
        textColor={textColor || typography.textColor}
        {...otherProps}
      />
    </Container>
  )
}

/**
 * Finds the block id that the button should scroll to when set to 'ticket_selector'.
 *
 * @returns string | null
 */
function getTargetBlockId(template: PurchasePageTemplate) {
  const {sections} = template

  // If there are no ticket selectors on the page, we'll just scroll
  // to the first purchase form
  return (
    getBlockOfType({
      sections,
      type: 'TicketSelector',
      position: 'last',
    }) ??
    getBlockOfType({
      sections,
      type: 'PurchaseForm',
      position: 'first',
    })
  )
}

function getBlockOfType(params: {
  sections: HashMap<PurchasePageSection>
  type: PurchasePageBlock['type']
  position: 'first' | 'last'
}) {
  const {sections, type: blockType, position} = params
  let currentBlockId: null | string = null
  let currentBlockLayout: Layout | null = null

  const sectionIds = orderedIdsByPosition(sections)

  for (const sectionId of sectionIds) {
    const blocks = sections[sectionId].blocks
    const layouts = sections[sectionId].layouts

    if (currentBlockId) {
      continue
    }

    for (const [id, block] of Object.entries(blocks)) {
      if (block.type !== blockType) {
        continue
      }

      const layout = layouts.desktop.find(({i}) => i === id)
      if (!layout) {
        continue
      }

      if (!currentBlockLayout) {
        currentBlockId = id
        currentBlockLayout = layout
        continue
      }

      const isAtPosition =
        position === 'first'
          ? layout.y < currentBlockLayout.y
          : layout.y > currentBlockLayout.y

      if (isAtPosition) {
        currentBlockId = id
        currentBlockLayout = layout
      }
    }
  }

  return currentBlockId
}
