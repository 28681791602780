import DashboardConfig from 'organization/Event/DashboardConfig'
import WaiverConfig from 'organization/Event/WaiverConfig'
import TechCheckConfig from 'organization/Event/TechCheckConfig'
import AttendeeManagement from 'organization/Event/AttendeeManagement'
import Emoji from 'organization/Event/EmojiPage'
import SpeakersConfig from 'organization/Event/SpeakersConfig'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import CreateAreaForm from 'organization/Event/AreaList/CreateAreaForm'
import {ObvioEvent} from 'Event'
import {useParamEventId} from 'Event/url'
import {routesWithValue} from 'lib/url'
import AreaRoutes from 'organization/Event/Area/AreaRoutes'
import PointsConfig from 'organization/Event/PointsConfig'
import LocalizationConfig from 'organization/Event/LocalizationConfig'
import AttendeesProvider from 'organization/Event/AttendeesProvider'
import Services from 'organization/Event/Services'
import Zapier from 'organization/Event/Services/Apps/Zapier'
import ServicesProvider from 'organization/Event/Services/ServicesProvider'
import AreasProvider from 'organization/Event/AreasProvider'
import FormsConfig from 'organization/Event/FormsConfig'
import Infusionsoft from 'organization/Event/Services/Apps/Infusionsoft'
import AuthorizedPage from 'organization/AuthorizedPage'
import {
  CHECK_IN_ATTENDEES,
  EMOJI_PAGE,
  EVENT_DESIGN,
  EVENT_SETTINGS,
  MANAGE_MARKETPLACE,
  SCHEDULE_BROADCASTS,
  START_AREAS,
  START_ROOMS,
  UPDATE_ATTENDEES,
  VIEW_STATISTICS,
  ZOOM_ATTENDANCE,
} from 'organization/PermissionsProvider'
import NameAppendageConfig from 'organization/Event/NameAppendageConfig'
import Event from 'organization/Event'
import AreaList from 'organization/Event/AreaList'
import {HideLiveChatSupport} from 'lib/WithLiveChatSupport'
import FAQsConfig from 'organization/Event/FAQsConfig'
import Form from 'organization/Event/Form'
import {FormProvider} from 'organization/Event/Form/FormProvider'
import {useEvent} from 'Event/EventProvider'
import TemplateProvider from 'Event/TemplateProvider'
import NameAppendageProvider from 'organization/Event/NameAppendageConfig/NameAppendageProvider'
import Reports from 'organization/Event/Reports'
import ReportsProvider from 'organization/Event/ReportsProvider'
import BackgroundsConfig from 'organization/Event/BackgroundsConfig'
import {StaticPointsProvider} from 'Event/PointsProvider'
import ImageEntries from 'organization/Event/ImageEntries'
import ImageEntriesProvider from 'organization/Event/ImageEntriesProvider'
import ImageWaterfallSettings from 'organization/Event/ImageWaterfall/ImageWaterfallSettings'
import ImageWaterfall from 'organization/Event/ImageWaterfall'
import Webhooks from 'organization/Event/Webhooks'
import WebhooksProvider, {
  PlanWebhooksProvider,
} from 'organization/Event/WebhooksProvider'
import EmojiPageSettings from 'organization/Event/EmojiPage/EmojiPageSettings'
import ActionsProvider from 'Event/ActionsProvider'
import Mailchimp from 'organization/Event/Services/Apps/Mailchimp'
import AccessTokensProvider from 'organization/Event/Services/AccessTokens/AccessTokensProvider'
import TemplateUpdateProvider from 'Event/TemplateUpdateProvider'
import DisconnectedDialog from 'organization/Event/DisconnectedDialog'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import Page from 'organization/Event/Page'
import EditModeProvider from 'Event/EditModeProvider'
import SetPasswordFormConfig from 'Event/Step1/SetPasswordForm/SetPasswordFormConfig'
import MarketplaceRoutes from 'organization/Marketplace/MarketplaceRoutes'
import LoginConfig from 'Event/auth/Login/LoginConfig'
import ForgotPasswordConfig from 'Event/auth/ForgotPassword/ForgotPasswordConfig'
import PlanRestrictedPage from 'organization/PlanRestrictedPage'
import AdditionalWaivers from 'organization/Event/WaiverConfig/AdditionalWaivers'
import EmailsProvider from 'organization/Settings/CommunicationSettings/EmailsProvider'
import SelectTemplatePage from 'organization/Event/SelectTemplatePage'
import ChangeTemplatePage from 'organization/Event/SelectTemplatePage/ChangeTemplatePage'
import BroadcastsProvider from 'organization/Event/Broadcasts/BroadcastsProvider'
import Broadcasts from 'organization/Event/Broadcasts'
import GroupsProvider from 'organization/Event/Groups/GroupsProvider'
import Statistics from 'Event/Statistics'
import GlobalStylesConfig from 'organization/Event/GlobalStylesConfig'
import CustomRedirects from 'organization/Event/Redirects'
import SponsorsConfig from 'organization/Event/SponsorsConfig'
import SignUpConfig from 'Event/auth/SignUp/SignUpConfig'
import Leaderboard from 'organization/Event/Leaderboard'
import LeaderboardProvider from 'organization/Event/Leaderboard/LeaderboardProvider'
import Hubspot from 'organization/Event/Services/Apps/Hubspot'
import Scripts from 'organization/Event/Scripts'
import ForgotPasswordEmailConfig from 'organization/Event/EmailConfig/ForgotPasswordEmailConfig'
import PurchaseConfirmationConfig from 'organization/Event/EmailConfig/PurchaseConfirmationConfig'
import WelcomeEmailConfig from 'organization/Event/EmailConfig/WelcomeEmailConfig'
import Highlevel from 'organization/Event/Services/Apps/HighLevel'
import EventOfflinePageConfig from 'organization/Event/EventOfflinePageConfig'
import ActiveCampaign from 'organization/Event/Services/Apps/ActiveCampaign'
import Ontraport from 'organization/Event/Services/Apps/Ontraport'
import ConvertKit from 'organization/Event/Services/Apps/ConvertKit'
import TemplateEditor from 'organization/Event/TemplateEditor'
import ZoomAttendanceProvider from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'
import ZoomAttendance from 'organization/Event/ZoomAttendance/ZoomAttendance'
import ChangePasswordConfig from 'Event/auth/ChangePassword/ChangePasswordConfig'
import DeepLinkProvider from 'DeepLinkProvider'
import FormModerate from 'organization/Event/Form/FormModeration'
import StageView from 'organization/Event/Form/FormModeration/StageView'
import LowerThird from 'organization/Event/Form/FormModeration/LowerThirdPage'
import FormStatistics from 'organization/Event/FormStatistics'
import CertificatesList from 'organization/Event/Certificates/CertificatesList'
import CreateCertificateForm from 'organization/Event/Certificates/CreateCertificateForm'
import CertificateRoutes from 'organization/Event/Certificates/CertificateRoutes'
import DuplicatingEventDialog from 'organization/Event/DuplicatingEventDialog'

export type EventRoutePaths = ReturnType<typeof useEventRoutes>

export function useEventRoutes(event?: Pick<ObvioEvent, 'id'>) {
  const {routes: organizationRoutes} = useOrganization()
  const id = useParamEventId()
  const value = event ? event.id : id

  return routesWithValue(
    ':event',
    String(value),
    organizationRoutes.events[':event'],
  )
}

export default function EventRoutes() {
  const {routes} = useOrganization()
  const {event} = useEvent()
  const {root: eventRoot} = useEventRoutes()

  if (!event.template) {
    return <SelectTemplatePage />
  }

  return (
    <EditModeProvider>
      <TemplateUpdateProvider template={event.template}>
        {(template) => (
          <TemplateEditor template={template}>
            {(template) => (
              <>
                <DisconnectedDialog />
                <DuplicatingEventDialog />
                <TemplateProvider template={template}>
                  <StaticPointsProvider>
                    <Switch>
                      <Route path={routes.events[':event'].marketplace.root}>
                        <MarketplaceRoutes />
                      </Route>
                      <Route
                        path={routes.events[':event'].change_template}
                        exact
                      >
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <ChangeTemplatePage />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].root} exact>
                        <Event />
                      </Route>
                      <Route path={routes.events[':event'].areas.root} exact>
                        <AuthorizedPage
                          permission={[
                            EVENT_SETTINGS,
                            START_AREAS,
                            START_ROOMS,
                            CHECK_IN_ATTENDEES,
                          ]}
                        >
                          <AreaList />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].dashboard}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <Page disablePadding>
                            <AreasProvider>
                              <PlanWebhooksProvider>
                                <DashboardConfig />
                              </PlanWebhooksProvider>
                            </AreasProvider>
                          </Page>
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].waiver.additional_waivers}
                      >
                        <PlanRestrictedPage plan={ENTERPRISE}>
                          <AuthorizedPage permission={EVENT_DESIGN}>
                            <AdditionalWaivers />
                          </AuthorizedPage>
                        </PlanRestrictedPage>
                      </Route>
                      <Route path={routes.events[':event'].waiver.root}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <WaiverConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={
                          routes.events[':event'].forms[':form'].moderation
                            .lower_third[':status'].root
                        }
                      >
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <FormProvider>
                            <LowerThird />
                          </FormProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={
                          routes.events[':event'].forms[':form'].moderation
                            .stage[':status'].root
                        }
                      >
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <FormProvider>
                            <StageView />
                          </FormProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={
                          routes.events[':event'].forms[':form'].moderation.root
                        }
                      >
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <FormProvider>
                            <FormModerate />
                          </FormProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].forms[':form'].statistics}
                      >
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <FormProvider>
                            <FormStatistics />
                          </FormProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].forms[':form'].root}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <FormProvider>
                            <Form />
                          </FormProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].forms.root}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <FormsConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].tech_check}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <AreasProvider>
                            <PlanWebhooksProvider>
                              <TechCheckConfig />
                            </PlanWebhooksProvider>
                          </AreasProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].leaderboard}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <LeaderboardProvider>
                            <Leaderboard />
                          </LeaderboardProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].attendees}>
                        <AuthorizedPage
                          permission={[
                            EVENT_SETTINGS,
                            UPDATE_ATTENDEES,
                            CHECK_IN_ATTENDEES,
                          ]}
                        >
                          <GroupsProvider>
                            <AttendeesProvider>
                              <AreasProvider>
                                <AttendeeManagement />
                              </AreasProvider>
                            </AttendeesProvider>
                          </GroupsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].emoji.settings}>
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <EmojiPageSettings />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].emoji.root}>
                        <AuthorizedPage
                          permission={[EVENT_SETTINGS, EMOJI_PAGE]}
                        >
                          <HideLiveChatSupport>
                            <Emoji />
                          </HideLiveChatSupport>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].redirects}>
                        <IfOwnerHasPlan plan={ENTERPRISE} redirect={eventRoot}>
                          <AuthorizedPage permission={EVENT_SETTINGS}>
                            <CustomRedirects />
                          </AuthorizedPage>
                        </IfOwnerHasPlan>
                      </Route>
                      <Route path={routes.events[':event'].speakers}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ActionsProvider>
                            <AreasProvider>
                              <SpeakersConfig />
                            </AreasProvider>
                          </ActionsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].sponsors}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ActionsProvider>
                            <AreasProvider>
                              <SponsorsConfig />
                            </AreasProvider>
                          </ActionsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].faqs}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ActionsProvider>
                            <AreasProvider>
                              <FAQsConfig />
                            </AreasProvider>
                          </ActionsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].points}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <PointsConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].localization}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <LocalizationConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].services.root}>
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <ServicesProvider>
                            <AccessTokensProvider>
                              <ServiceRoutes />
                            </AccessTokensProvider>
                          </ServicesProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].backgrounds}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ActionsProvider>
                            <AreasProvider>
                              <BackgroundsConfig />
                            </AreasProvider>
                          </ActionsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].areas.create}>
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <CreateAreaForm />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].areas[':area'].root}>
                        <AreaRoutes />
                      </Route>
                      <Route path={routes.events[':event'].name_appendage}>
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <NameAppendageProvider>
                            <NameAppendageConfig />
                          </NameAppendageProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].zoom_attendance}>
                        <AuthorizedPage permission={ZOOM_ATTENDANCE}>
                          <AreasProvider>
                            <ZoomAttendanceProvider>
                              <ZoomAttendance />
                            </ZoomAttendanceProvider>
                          </AreasProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].broadcasts}>
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <EmailsProvider>
                            <BroadcastsProvider>
                              <Broadcasts />
                            </BroadcastsProvider>
                          </EmailsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].purchase_confirmations}
                      >
                        <AuthorizedPage permission={MANAGE_MARKETPLACE}>
                          <PurchaseConfirmationConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].reports}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ReportsProvider>
                            <Reports />
                          </ReportsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].scripts}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <Scripts />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].image_entries}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ActionsProvider>
                            <ImageEntriesProvider>
                              <ImageEntries />
                            </ImageEntriesProvider>
                          </ActionsProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].image_waterfall.settings}
                      >
                        <AuthorizedPage permission={EVENT_SETTINGS}>
                          <ImageWaterfallSettings />
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].image_waterfall.root}
                      >
                        <AuthorizedPage
                          permission={[EVENT_SETTINGS, EMOJI_PAGE]}
                        >
                          <HideLiveChatSupport>
                            <ImageWaterfall />
                          </HideLiveChatSupport>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].webhooks}>
                        <IfOwnerHasPlan plan={ENTERPRISE} redirect={eventRoot}>
                          <AuthorizedPage permission={EVENT_SETTINGS}>
                            <AccessTokensProvider>
                              <WebhooksProvider>
                                <Webhooks />
                              </WebhooksProvider>
                            </AccessTokensProvider>
                          </AuthorizedPage>
                        </IfOwnerHasPlan>
                      </Route>
                      <Route path={routes.events[':event'].password_create}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <SetPasswordFormConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].login}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <LoginConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].change_password}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <DeepLinkProvider>
                            <ChangePasswordConfig />
                          </DeepLinkProvider>
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].forgot_password}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <ForgotPasswordConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].sign_up}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <SignUpConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].welcome_email}>
                        <AuthorizedPage permission={SCHEDULE_BROADCASTS}>
                          <WelcomeEmailConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].forgot_password_email}
                      >
                        <ForgotPasswordEmailConfig />
                      </Route>
                      <Route path={routes.events[':event'].statistics}>
                        <AuthorizedPage permission={VIEW_STATISTICS}>
                          <Statistics />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].event_offline}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <EventOfflinePageConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route path={routes.events[':event'].global_styles}>
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <GlobalStylesConfig />
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={routes.events[':event'].certificates.root}
                        exact
                      >
                        <CertificatesList />
                      </Route>
                      <Route
                        path={routes.events[':event'].certificates.create}
                        exact
                      >
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <CreateCertificateForm />
                        </AuthorizedPage>
                      </Route>
                      <Route
                        path={
                          routes.events[':event'].certificates[':certificate']
                            .root
                        }
                      >
                        <AuthorizedPage permission={EVENT_DESIGN}>
                          <CertificateRoutes />
                        </AuthorizedPage>
                      </Route>
                      <Redirect to={routes.events[':event'].root} />
                    </Switch>
                  </StaticPointsProvider>
                </TemplateProvider>
              </>
            )}
          </TemplateEditor>
        )}
      </TemplateUpdateProvider>
    </EditModeProvider>
  )
}

function ServiceRoutes() {
  const {routes} = useOrganization()

  return (
    <Switch>
      <Route path={routes.events[':event'].services.zapier}>
        <Zapier />
      </Route>
      <Route path={routes.events[':event'].services.infusionsoft}>
        <Infusionsoft />
      </Route>
      <Route path={routes.events[':event'].services.mailchimp}>
        <Mailchimp />
      </Route>
      <Route path={routes.events[':event'].services.hubspot}>
        <Hubspot />
      </Route>
      <Route path={routes.events[':event'].services.highlevel}>
        <Highlevel />
      </Route>
      <Route path={routes.events[':event'].services.active_campaign}>
        <ActiveCampaign />
      </Route>
      <Route path={routes.events[':event'].services.ontraport}>
        <Ontraport />
      </Route>
      <Route path={routes.events[':event'].services.convert_kit}>
        <ConvertKit />
      </Route>
      <Route path={routes.events[':event'].services.root}>
        <Services />
      </Route>
    </Switch>
  )
}
