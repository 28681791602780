import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {
  useOrganization,
  OrganizationRoutes,
} from 'organization/OrganizationProvider'
import Obie from 'organization/Obie'
import Blocks from 'organization/Obie/Blocks'
import ProcessForm from 'organization/Obie/Blocks/ProcessForm'
import {routesWithValue} from 'lib/url'
import ObieLayout from 'organization/Obie/Layout'
import ObieLockedPage from 'organization/Obie/ObieLockedPage'
import {useCanAccessObie} from 'organization/Obie/use-can-access-obie'

export function categoryRoute(routes: OrganizationRoutes, categoryId: string) {
  return routesWithValue(
    ':category',
    categoryId,
    routes.obie.categories[':category'],
  )
}

export default function ObieRoutes() {
  const {routes} = useOrganization()

  const canAccessObie = useCanAccessObie()
  if (!canAccessObie) {
    return <ObieLockedPage />
  }

  return (
    <Switch>
      <Route exact path={routes.obie.root}>
        <ObieLayout hideSidebar={false}>
          <Obie />
        </ObieLayout>
      </Route>
      <Route exact path={routes.obie.categories[':category'].root}>
        <ObieLayout hideSidebar={false}>
          <Blocks />
        </ObieLayout>
      </Route>
      <Route
        exact
        path={routes.obie.categories[':category'].blocks[':block'].process}
      >
        <ObieLayout hideSidebar={true}>
          <ProcessForm />
        </ObieLayout>
      </Route>
      <Redirect to={routes.obie.root} />
    </Switch>
  )
}
