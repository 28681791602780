import React from 'react'
import styled from 'styled-components'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {useAttendeeVariables} from 'Event'
import {useWithAttendeeData} from 'Event/auth/attendee-data'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'

export default function Event<T extends Agenda>(props: {
  agenda: T
  color?: string | null
}) {
  const withAttendeeData = useWithAttendeeData()
  const v = useAttendeeVariables()

  if (props.agenda.link) {
    return (
      <StyledAbsoluteLink
        newTab
        to={withAttendeeData(props.agenda.link)}
        textColor={props.color}
      >
        <EventText aria-label="agenda event">{v(props.agenda.text)}</EventText>
      </StyledAbsoluteLink>
    )
  }

  return <EventText aria-label="agenda event">{v(props.agenda.text)}</EventText>
}

const EventText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: 0.2px;
  display: block;
  margin-bottom: 6px;
`

const StyledAbsoluteLink = styled(AbsoluteLink)<{textColor?: string | null}>`
  color: ${(props) => props.textColor} !important;
  &:hover {
    text-decoration: none !important;
  }
`
