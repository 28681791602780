import React from 'react'
import {UseFormMethods} from 'react-hook-form'

import {QuesionForm} from 'organization/Obie/Blocks/ProcessForm'
import RadioOptions from 'organization/Obie/Blocks/ProcessForm/Form/RadioOptions'
import LongForm from 'organization/Obie/Blocks/ProcessForm/Form/LongForm'

export default function PathParent(
  props: QuesionForm & {
    register: UseFormMethods['register']
    control: UseFormMethods['control']
    watch: UseFormMethods['watch']
    setValue: UseFormMethods['setValue']
    getValues: UseFormMethods['getValues']
  },
) {
  const {question} = props
  if (question.options.length) {
    return <RadioOptions {...props} />
  }

  return <LongForm {...props} />
}
