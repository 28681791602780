import React from 'react'
import styled from 'styled-components'
import MuiTextField from '@material-ui/core/TextField'
import {UseFormMethods} from 'react-hook-form'
import {QuesionForm} from 'organization/Obie/Blocks/ProcessForm'

export default function LongForm(
  props: QuesionForm & {register: UseFormMethods['register']},
) {
  const {
    question: {required, example_text},
    answer,
    register,
  } = props
  return (
    <StyledTextField
      fullWidth
      multiline
      rows={12}
      name="answer"
      variant="filled"
      required={required}
      placeholder={example_text}
      defaultValue={answer?.answer || ''}
      inputProps={{
        ref: register,
      }}
    />
  )
}

const StyledTextField = styled(MuiTextField)`
  & .MuiFilledInput-input {
    color: white;
  }
`
