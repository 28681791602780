import React, {useEffect} from 'react'
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {QuesionForm} from 'organization/Obie/Blocks/ProcessForm'
import {UseFormMethods} from 'react-hook-form'
import {QuestionOption} from 'organization/Obie/ObieServiceProvider'
import styled from 'styled-components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: 'white',
      '&$checked': {
        color: 'white',
      },
    },
    formControl: {
      margin: theme.spacing(3),
      color: 'white',
    },
  }),
)

export default function CheckboxesGroup(
  props: QuesionForm & {
    control: UseFormMethods['control']
    register: UseFormMethods['register']
    watch: UseFormMethods['watch']
    setValue: UseFormMethods['setValue']
    getValues: UseFormMethods['getValues']
  },
) {
  const {answer, question, getValues, setValue, watch, register} = props
  const classes = useStyles()

  useEffect(() => {
    register('answer')
    setValue('answer', answer?.answer || [], {shouldDirty: true})
  }, [setValue, register, answer])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, name} = event.target
    const currentValues = getValues('answer') || []

    if (checked) {
      setValue('answer', [...currentValues, name], {shouldDirty: true})
    } else {
      setValue(
        'answer',
        currentValues.filter((v: string) => v !== name),
        {shouldDirty: true},
      )
    }
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {question.options.map((option: QuestionOption, index: number) => {
            const answered = (watch('answer') as string[]) || []

            const checked = answered.some((ans: string) => ans === option.value)

            return (
              <FormControlLabel
                key={`multi-choice-${index}`}
                control={
                  <StyledCheckBox
                    checked={checked}
                    name={option.value}
                    onChange={handleChange}
                  />
                }
                label={option.value}
              />
            )
          })}
        </FormGroup>
      </FormControl>
    </div>
  )
}
const StyledCheckBox = styled(Checkbox)`
  color: white;
`
