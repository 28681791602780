import {useEditMode} from 'Event/EditModeProvider'
import EditIconButton from 'lib/ui/IconButton/EditIconButton'
import CopyIconButton from 'lib/ui/IconButton/DuplicateIconButton'
import React from 'react'
import styled from 'styled-components'
import MoveIconButton from 'lib/ui/IconButton/MoveIconButton'
import AddIconButton from 'lib/ui/IconButton/AddIconButton'
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd'
import {DragHandle} from 'lib/ui/drag-and-drop'

export const EDIT_COMPONENT_CLASS = 'edit-component'
export const EDIT_COMPONENT_BUTTON_CLASS = 'edit-component-button'

export const DUPLICATE_COMPONENT_CLASS = 'duplicate-component'
export const DUPLICATE_COMPONENT_BUTTON_CLASS = 'duplicate-component-button'

export const MOVE_UP_COMPONENT_BUTTON_CLASS = 'move-up-component-button'
export const MOVE_DOWN_COMPONENT_BUTTON_CLASS = 'move-down-component-button'

export const ADD_ITEM_BUTTON_CLASS = 'add-item-button'

export type EditableProps = {
  children: React.ReactElement
  onEdit: () => void
  onCopy?: () => void
  moveUp?: () => void
  moveDown?: () => void
  addItem?: () => void
  className?: string
  dataTestId?: string
  ['aria-label']?: string
  copyRightSpacing?: number
  alwaysShowButtons?: boolean
  additionalButtons?: JSX.Element
  handleProps?: DraggableProvidedDragHandleProps
}

/**
 * A more generic version of EditComponent where handling the edit/config
 * dialog rendering is handled by the parent.
 *
 * @param props
 * @returns
 */
export function Editable(props: EditableProps) {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return props.children
  }

  return (
    <EditComponentOverlay
      onClick={props.onEdit}
      onCopy={props.onCopy}
      onMoveDown={props.moveDown}
      onMoveUp={props.moveUp}
      onAddItem={props.addItem}
      className={props.className}
      aria-label={props['aria-label']}
      dataTestId={props.dataTestId}
      copyRightSpacing={props.copyRightSpacing}
      alwaysShowButtons={props.alwaysShowButtons}
      additionalButtons={props.additionalButtons}
      handleProps={props.handleProps}
    >
      {props.children}
    </EditComponentOverlay>
  )
}

export function EditComponentOverlay(props: {
  onClick: () => void
  onCopy?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  onAddItem?: () => void
  children: React.ReactElement
  disableChildInteraction?: boolean
  className?: string
  ['aria-label']?: string
  dataTestId?: string
  copyRightSpacing?: number
  alwaysShowButtons?: boolean
  additionalButtons?: JSX.Element
  handleProps?: DraggableProvidedDragHandleProps
}) {
  const className = `${EDIT_COMPONENT_CLASS} ${props.className || ''}`
  const label = props['aria-label'] ?? 'edit component'

  return (
    <EditComponentBox className={className} data-testid={props.dataTestId}>
      <InteractionOverlay disable={props.disableChildInteraction} />
      <ButtonsOverlay alwaysShowButtons={props.alwaysShowButtons} />
      <ButtonsHolder>
        <StyledAddIconButton
          onClick={props.onAddItem}
          className={ADD_ITEM_BUTTON_CLASS}
          showing={Boolean(props.onAddItem)}
          type="button"
          aria-label="add item"
          rightSpacing={props.copyRightSpacing}
          alwaysShowButtons={props.alwaysShowButtons}
        />
        <StyledCopyIconButton
          onClick={props.onCopy}
          className={DUPLICATE_COMPONENT_BUTTON_CLASS}
          showing={Boolean(props.onCopy)}
          type="button"
          aria-label="duplicate component"
          rightSpacing={props.copyRightSpacing}
          alwaysShowButtons={props.alwaysShowButtons}
        />
        <StyledMoveIconButton
          onClick={props.onMoveUp}
          className={MOVE_UP_COMPONENT_BUTTON_CLASS}
          showing={Boolean(props.onMoveUp)}
          type="button"
          aria-label="move component up"
          direction="up"
          rightSpacing={10}
          alwaysShowButtons={props.alwaysShowButtons}
        />
        <StyledMoveIconButton
          onClick={props.onMoveDown}
          className={MOVE_DOWN_COMPONENT_BUTTON_CLASS}
          showing={Boolean(props.onMoveDown)}
          type="button"
          aria-label="move component down"
          direction="down"
          rightSpacing={Boolean(props.onMoveUp) ? 18 : 10}
          alwaysShowButtons={props.alwaysShowButtons}
        />

        {props.additionalButtons}

        {props.handleProps && <DragHandle handleProps={props.handleProps} />}

        <StyledEditIconButton
          onClick={props.onClick}
          className={EDIT_COMPONENT_BUTTON_CLASS}
          type="button"
          aria-label={label}
          alwaysShowButtons={props.alwaysShowButtons}
        />
      </ButtonsHolder>
      {props.children}
    </EditComponentBox>
  )
}

const ButtonsOverlay = styled.div<{alwaysShowButtons?: boolean}>`
  display: ${(props) => (props.alwaysShowButtons ? 'block' : 'none')};
  position: absolute;
  height: 34px;
  width: 100%;
  background-image: repeating-linear-gradient(
    145deg,
    #c3ddf3 10px,
    #c3ddf3 10px,
    transparent 12px,
    transparent 20px
  );
  border-bottom: 1px solid #c3ddf3;
  background-color: white;
  z-index: 2;
`

const InteractionOverlay = styled.div<{disable?: boolean}>`
  display: ${(props) => (props.disable ? 'block' : 'none')};
  position: ${(props) => (props.disable ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const StyledAddIconButton = styled(AddIconButton)<{
  rightSpacing?: number
  alwaysShowButtons?: boolean
}>`
  z-index: 4;
  display: ${(props) => (props.alwaysShowButtons ? 'inline-flex' : 'none')};

  &:hover {
    opacity: 0.8;
  }
`

const StyledEditIconButton = styled(EditIconButton)<{
  alwaysShowButtons?: boolean
}>`
  z-index: 4;
  display: ${(props) => (props.alwaysShowButtons ? 'inline-flex' : 'none')};

  &:hover {
    opacity: 0.8;
  }
`

const StyledCopyIconButton = styled(CopyIconButton)<{
  rightSpacing?: number
  alwaysShowButtons?: boolean
}>`
  z-index: 4;
  display: ${(props) => (props.alwaysShowButtons ? 'inline-flex' : 'none')};

  &:hover {
    opacity: 0.8;
  }
`

const StyledMoveIconButton = styled(MoveIconButton)<{
  rightSpacing: number
  alwaysShowButtons?: boolean
}>`
  z-index: 4;
  display: ${(props) => (props.alwaysShowButtons ? 'inline-flex' : 'none')};

  &:hover {
    opacity: 0.8;
  }
`

const ButtonsHolder = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  height: 34px;
  align-items: center;
  padding-right: 15px;
`

export const EditComponentBox = styled.div`
  position: relative;
  width: 100%;

  &:hover > ${ButtonsHolder} > button {
    display: inline-flex;
  }

  &:hover > ${ButtonsHolder} > div {
    display: inline-flex;
  }
`
