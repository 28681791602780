import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import Markdown from 'marked-react'
import styled from 'styled-components'
import MuiTextField from '@material-ui/core/TextField'
import Button from 'lib/ui/Button'
import {PlusIcon} from 'lib/ui/Icon'
import {routesWithValue} from 'lib/url'
import BlockCreateDependencies from 'organization/Obie/Blocks/BlockCreateDependencies'
import CompletionMenu from 'organization/Obie/Blocks/ProcessForm/CompletionMenu'
import {categoryRoute} from 'organization/Obie/ObieRoutes'
import {
  AnswerSet,
  Block,
  Completion,
  OBIE_NEW_LINE,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import BlockName, {
  useUncompletedDependencies,
} from 'organization/Obie/Blocks/BlockName'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'

export default function BlockListItem(props: {block: Block}) {
  const {block} = props
  const {completions: allCompletions, findBlock} = useObieService()
  const [selectedAnswerSet, setSelectedAnswerSet] = useState<AnswerSet>()
  const {routes} = useOrganization()
  const history = useHistory()
  const [completions, setCompletions] = useState<Completion[]>([])
  const [editing, setEditing] = useState<boolean>(false)
  const [showDependencySelector, setShowDependencySelector] = useState<boolean>(
    false,
  )
  const answerSets = block.answer_sets

  const onCloseDependencySelector = () => setShowDependencySelector(false)

  let needsSelection = false

  const dependencyData: object = (block.dependencies || []).map(
    (blockId: number) => {
      const checkBlock = findBlock(blockId)
      let dependencyData = {}

      // No answer sets, so we out.
      if (!checkBlock?.answer_sets?.length) {
        return {}
      }

      // If there is more than one answer set for the block we are depending on,
      // then we mark "needs selection", so we can end up showing the selector.
      if (checkBlock.answer_sets.length > 1) {
        needsSelection = true

        return {}
      }

      // Build an object of the dependency data, the way the formData would be
      // shaped, in case that we don't need to show a selector in the event that
      // all the blocks being depended on only have one completion. No need to pick
      // anything in this case, we already know the option they would pick.
      return {
        ...dependencyData,
        [checkBlock.block_raw]: checkBlock.answer_sets[0].id,
      }
    },
  )

  const handleCreate = () => {
    // If there are no dependencies associated with the block, don't need to do
    // anything here, just start the process.
    if (!block.dependencies) {
      startProcess()
    }

    // There are block dependencies, but we've already determined that we don't
    // require a selection(s) from user.
    if (!needsSelection) {
      return startProcess(dependencyData)
    }

    // Finally, there are block dependencies, but some of the users blocks that
    // are depended on have multiple completions, so we ultimately have to show
    // the dependency selector to the user.
    setShowDependencySelector(true)
  }

  const startProcess = (formData?: any) => {
    const cRoute = categoryRoute(routes, String(block.category.id))

    const processRoute = routesWithValue(
      ':block',
      String(block.id),
      cRoute.blocks[':block'],
    )

    const queryParam = formData
      ? `?dependencies=${encodeURIComponent(JSON.stringify(formData))}`
      : ''

    history.push(`${processRoute.process}${queryParam}`)
  }

  const onClickName = () => {
    if (selectedAnswerSet) {
      setSelectedAnswerSet(undefined)
    } else if (completions.length > 0) {
      setSelectedAnswerSet(answerSets[0])
    }
  }

  useEffect(() => {
    const updated = allCompletions.filter(
      (completion) =>
        answerSets.filter(
          (answerSet) =>
            String(answerSet.id) === String(completion.answer_set_id),
        ).length > 0,
    )
    setCompletions(updated)
  }, [allCompletions, answerSets])

  useEffect(() => {
    if (!selectedAnswerSet) {
      return
    }

    const updated = answerSets.filter(
      (answerSet) => answerSet.id === selectedAnswerSet.id,
    )[0]
    setSelectedAnswerSet(updated)
  }, [answerSets, selectedAnswerSet])

  return (
    <Container>
      <BlockCreateDependencies
        block={block}
        onClose={onCloseDependencySelector}
        startProcess={startProcess}
        open={showDependencySelector}
      />

      <Header>
        <BlockName
          block={block}
          showing={!selectedAnswerSet}
          onClick={onClickName}
        />
        <Completions
          selected={selectedAnswerSet}
          completions={completions}
          answerSets={answerSets}
          selectAnswerSet={setSelectedAnswerSet}
          setEditing={setEditing}
        />
        <CreateCompletionButton
          block={block}
          showing={!selectedAnswerSet}
          onCreate={handleCreate}
        />
        <CompletionMenu
          answerSet={selectedAnswerSet}
          onCollapse={() => setSelectedAnswerSet(undefined)}
          setEditing={setEditing}
          showing={!!selectedAnswerSet}
          block={block}
        />
      </Header>
      <MobileHeader selected={!!selectedAnswerSet}>
        <BlockName
          block={block}
          showing={!selectedAnswerSet}
          onClick={onClickName}
        />
        <CreateCompletionButton
          block={block}
          showing={!selectedAnswerSet}
          onCreate={handleCreate}
        />
        <CompletionMenu
          answerSet={selectedAnswerSet}
          onCollapse={() => setSelectedAnswerSet(undefined)}
          setEditing={setEditing}
          showing={!!selectedAnswerSet}
          block={block}
        />
      </MobileHeader>
      <MobileCompletionsContainer>
        <Completions
          selected={selectedAnswerSet}
          completions={completions}
          answerSets={answerSets}
          selectAnswerSet={setSelectedAnswerSet}
          setEditing={setEditing}
        />
      </MobileCompletionsContainer>
      <CompletionContent
        answerSet={selectedAnswerSet}
        completions={completions}
        editing={editing}
        setEditing={setEditing}
      />
    </Container>
  )
}

function CompletionContent(props: {
  answerSet?: AnswerSet
  completions: Completion[]
  editing: boolean
  setEditing: (state: boolean) => void
}) {
  const {answerSet, completions, editing, setEditing} = props
  const completion = completions.find(
    (c) => String(c.answer_set_id) === String(answerSet?.id),
  )
  const {
    register,
    control,
    formState: {isDirty},
    handleSubmit,
  } = useForm()
  const {updateAnswerSet, updateCompletion} = useObieService()

  const [submitting, setSubmitting] = useState(false)

  if (!answerSet || !completion) {
    return null
  }

  const onClickCancel = () => {
    setEditing(false)
  }

  const onSubmit = (formData: {name: string; completion: string}) => {
    setSubmitting(true)
    updateAnswerSet(answerSet.block_id, answerSet.id, formData.name).then(() =>
      updateCompletion(completion.id, formData.completion).then(() => {
        setSubmitting(false)
        setEditing(false)
      }),
    )
  }

  const processedText = completion.completion.replaceAll(OBIE_NEW_LINE, '\n')

  if (editing) {
    return (
      <Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledTextField
            fullWidth
            name="name"
            variant="filled"
            required
            defaultValue={answerSet.name || ''}
            inputProps={{
              ref: register,
            }}
          />
          <Controller
            name="completion"
            defaultValue={processedText || ''}
            control={control}
            render={({onChange, value}) => (
              <MarkdownEditor data={value} onChange={onChange} theme="Light" />
            )}
          />
          <ButtonBox>
            <Button
              type="button"
              variant="contained"
              color="grey"
              onClick={onClickCancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <SubmitButton isDirty={isDirty} submitting={submitting} />
          </ButtonBox>
        </form>
      </Body>
    )
  }

  return (
    <Body>
      <CompletionTitle>{answerSet.name}</CompletionTitle>
      <CompletionText>
        <Markdown breaks gfm>
          {processedText}
        </Markdown>
      </CompletionText>
    </Body>
  )
}

function SubmitButton(props: {isDirty: boolean; submitting: boolean}) {
  const {isDirty, submitting} = props

  if (isDirty) {
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={submitting}
      >
        Save
      </Button>
    )
  }

  return (
    <Button type="button" variant="contained" color="grey" disabled={true}>
      Save
    </Button>
  )
}

function Completions(props: {
  completions: Completion[]
  answerSets: AnswerSet[]
  selected?: AnswerSet
  selectAnswerSet: (answerSet?: AnswerSet) => void
  setEditing: (state: boolean) => void
}) {
  const {completions, answerSets, selectAnswerSet, selected, setEditing} = props

  const handleSelectAnswerSet = (answerSet: AnswerSet) => {
    if (selected && selected.id === answerSet.id) {
      selectAnswerSet()
    } else {
      selectAnswerSet(answerSet)
    }
    setEditing(false)
  }

  return (
    <CompletionsList selected={!!selected}>
      {answerSets.map((answerSet, index) => (
        <AnswerSetPill
          key={index}
          answerSet={answerSet}
          completions={completions}
          selected={!selected || selected.id === answerSet.id}
          onSelect={() => handleSelectAnswerSet(answerSet)}
        />
      ))}
    </CompletionsList>
  )
}

function AnswerSetPill(props: {
  answerSet: AnswerSet
  completions: Completion[]
  selected?: boolean
  onSelect: () => void
}) {
  return (
    <PillWrapper active={props.selected} onClick={props.onSelect}>
      <AnswerSetPillLabel answerSet={props.answerSet} />
    </PillWrapper>
  )
}

const AnswerSetPillLabel = (props: {answerSet: AnswerSet}) => {
  if (!props.answerSet.complete) {
    return <>{props.answerSet.name} - INCOMPLETE</>
  }

  return <>{props.answerSet.name}</>
}

function CreateCompletionButton(props: {
  block: Block
  showing?: boolean
  onCreate: () => void
}) {
  const {hasUnCompletedDependency} = useUncompletedDependencies(props.block)

  if (!props.showing) {
    return null
  }

  return (
    <StyledButton
      variant="contained"
      color="primary"
      onClick={props.onCreate}
      disablePadding
      disabled={hasUnCompletedDependency}
    >
      <PlusIcon
        iconSize={13}
        title={`Create new ${props.block.block} Completion`}
      />
    </StyledButton>
  )
}

const Container = styled.div`
  background: white;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const Header = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[4]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const MobileHeader = styled.div<{selected?: boolean}>`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.selected ? 'flex-end' : 'space-between'};
  padding: ${(props) => props.theme.spacing[4]};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const MobileCompletionsContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const CompletionsList = styled.div<{selected?: boolean}>`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.selected ? 'flex-start' : 'flex-end')};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: flex-start;
    padding: ${(props) => props.theme.spacing[2]};
  }
`

const PillWrapper = styled.div<{active?: boolean}>`
  border-radius: 20px;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[6]}`};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: white;
  background: ${(props) =>
    props.active ? '#131D34' : 'rgba(19, 29, 52, 0.7)'};
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  width: 28px;
  height: 28px;
  padding: ${(props) => props.theme.spacing[2]};
`

const Body = styled.div`
  padding: ${(props) => props.theme.spacing[6]};
`

const CompletionTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const CompletionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
`

const StyledTextField = styled(MuiTextField)`
  & .MuiFilledInput-multiline {
    padding: 0;
  }
  & .MuiFilledInput-input {
    padding: ${(props) => props.theme.spacing[3]};
  }
`

const ButtonBox = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing[2]};
  gap: ${(props) => props.theme.spacing[2]};
`
