import {useTownhallTemplate} from 'Event/template/Townhall'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import styled from 'styled-components'

export default function SpeakersHeaderConfig() {
  const save = useSaveTemplate()
  const {speakers} = useTownhallTemplate()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <Title>Menu</Title>
        <TextField
          name="speakers.menuTitle"
          defaultValue={speakers.menuTitle || 'Our Speakers'}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'menu title',
            ref: register,
          }}
        />
        <Title>Page</Title>
        <TextField
          name="speakers.title"
          defaultValue={speakers.title}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'speakers title',
            ref: register,
          }}
        />
        <TextField
          name="speakers.secondaryTitle"
          defaultValue={speakers.secondaryTitle}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'speakers secondary title',
            ref: register,
          }}
        />
        <InputLabel>Description</InputLabel>
        <StyledTextEditorContainer>
          <Controller
            name="speakers.description"
            defaultValue={speakers.description}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </StyledTextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
