import React from 'react'
import HelperText from '../HelperText'
import styles from '../styles/Select.module.css'
import classNames from 'classnames'
import {PurchaseFormBlock} from '../../../Block/PurchaseForm'

export type SelectProps = {
  value: any
  children?: React.ReactNode
  onChange: (value: any, obj?: any) => void
  className?: string
  helperText?: string
  error?: boolean
  disabled?: boolean
}

export default function Select(
  props: SelectProps & {
    config: PurchaseFormBlock
  },
) {
  const {disabled, className = '', helperText, config, onChange} = props

  return (
    <div
      className={classNames(className, styles.selectFormControl)}
      style={{
        marginBottom: config.input.spacing + 'px',
      }}
    >
      <select
        onChange={onChange}
        disabled={disabled}
        style={{
          backgroundColor: config.input.backgroundColor,
          border:
            props.config.input.border.color +
            ' ' +
            props.config.input.border.width +
            'px solid',
          borderRadius: props.config.input.border.radius + 'px',
          paddingLeft: props.config.input.padding.left + 'px',
          paddingRight: props.config.input.padding.right + 'px',
          paddingTop: props.config.input.padding.top + 'px',
          paddingBottom: props.config.input.padding.bottom + 'px',
          fontSize: props.config.input.fontSize + 'px',
          lineHeight: 'normal',
          width: 100 + '%',
        }}
      >
        {props.children}
      </select>
      <HelperText error={props.error}>{helperText}</HelperText>
    </div>
  )
}
