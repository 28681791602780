import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'lib/ui/Button'
import Dialog, {DialogBody} from 'lib/ui/Dialog'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import {uuid} from 'lib/uuid'
import React, {useState} from 'react'
import styled from 'styled-components'
import Icon from 'lib/ui/Icon'
import {Tiny} from 'lib/ui/typography'
import {
  PurchasePageBlock,
  PurchasePageSection,
} from 'Event/Marketplace/purchase-page'
import {createButtonBlock} from 'Event/Marketplace/Block/Button/create-button-block'
import {createFaqListBlock} from 'Event/Marketplace/Block/FaqList/create-faq-list-block'
import {createPurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm/create-purchase-form-block'
import {createTitleBlock} from 'Event/Marketplace/Block/Title/create-title-block'
import {createTextBlock} from 'Event/Marketplace/Block/Text/create-text-block'
import {createImageBlock} from 'Event/Marketplace/Block/Image/create-image-block'
import {createVideoBlock} from 'Event/Marketplace/Block/Video/create-video-block'
import {createTicketSelectorBlock} from 'Event/Marketplace/Block/TicketSelector/create-ticket-selector-block'
import {createBulletedListBlock} from 'Event/Marketplace/Block/BulletedList/create-bulleted-list.block'
import {createSeparatorBlock} from 'Event/Marketplace/Block/Separator/create-separator-block'
import {createCountdownTimerBlock} from 'Event/Marketplace/Block/CountdownTimer/create-countdown-timer-block'
import {createNumberedListBlock} from 'Event/Marketplace/Block/NumberedList/create-numbered-list-block'
import {createIconBlock} from 'Event/Marketplace/Block/Icon/create-icon-block'
import {createSpacerBlock} from 'Event/Marketplace/Block/Spacer/create-spacer-block'
import {createGridBlock} from 'Event/Marketplace/Block/Grid/create-grid-block'
import {
  ThankYouPageBlock,
  ThankYouPageSection,
} from 'Event/Marketplace/thank-you-page'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'

interface AddBlockDialogProps {
  open: boolean
  onClose: () => void
  layouts: PurchasePageSection['layouts'] | ThankYouPageSection['layouts']
  availableBlocks: Array<PurchasePageBlock['type'] | ThankYouPageBlock['type']>
}

type BlockDefinitions = {
  [T in PurchasePageBlock | ThankYouPageBlock as T['type']]: {
    label?: string
    icon: string
  }
}

const blockDefinitions: BlockDefinitions = {
  Title: {
    icon: 'fa-solid fa-book',
  },
  Text: {
    icon: 'fa-regular fa-align-justify',
  },
  Image: {
    icon: 'fa-solid fa-image',
  },
  Video: {
    icon: 'fa-solid fa-film',
  },
  Button: {
    icon: 'fa-solid fa-computer-mouse',
  },
  TicketSelector: {
    label: 'Ticket Selector',
    icon: 'fa-solid fa-ticket',
  },
  PurchaseForm: {
    label: 'Purchase Form',
    icon: 'fa-solid fa-credit-card',
  },
  BulletedList: {
    label: 'Bulleted List',
    icon: 'fa-solid fa-list',
  },
  CountdownTimer: {
    label: 'Countdown Timer',
    icon: 'fa-solid fa-timer',
  },
  NumberedList: {
    label: 'Numbered List',
    icon: 'fa-solid fa-list-ol',
  },
  FaqList: {
    label: 'Faq List',
    icon: 'fa-solid fa-square-question',
  },
  Icon: {
    label: 'Icon',
    icon: 'fa-solid fa-icons',
  },
  Separator: {
    icon: 'fa-solid fa-ruler-horizontal',
  },
  Spacer: {
    label: 'Spacer',
    icon: 'fa-solid fa-square-dashed',
  },
  Grid: {
    icon: 'fa-solid fa-grid',
    label: 'Grid',
  },
} as const

export const blockDefaultWidth = 12
export const blockDefaultHeight = 8
export const blockDefaultMobileWidth = 2
export const blockDefaultMobileHeight = 10
export const purchaseFormBlockDefaultHeight = 40

export default function AddBlockDialog(props: AddBlockDialogProps) {
  const {open, onClose, layouts, availableBlocks} = props
  const [block, setBlock] = useState<PurchasePageBlock['type'] | null>(null)

  const {update} = useConfigurableSection()

  const addBlock = () => {
    if (!block) {
      return
    }

    const createBlock = createBlockHandlers[block]
    const newBlock = createBlock()
    const id = uuid()

    const layout = {
      i: id,
      x: 0,
      y: 1000000000000, // place at bottom
      w: blockDefaultMobileWidth,
      h:
        newBlock.type === 'PurchaseForm'
          ? purchaseFormBlockDefaultHeight
          : blockDefaultMobileHeight,
    }

    // Spacer should not include a layout. Fixes an issue where invisible spacers (expected)
    // on mobile would still take up space in the editor.
    const mobileLayouts =
      newBlock.type === 'Spacer'
        ? layouts.mobile
        : [...(layouts.mobile ?? []), layout]

    const newLayouts = {
      desktop: [
        ...(layouts.desktop ?? []),
        {
          ...layout,
          w: blockDefaultWidth,
          h:
            newBlock.type === 'PurchaseForm'
              ? purchaseFormBlockDefaultHeight
              : blockDefaultHeight,
        },
      ],
      mobile: mobileLayouts ?? [],
    }

    update({
      blocks: {
        [id]: newBlock,
      },
      layouts: newLayouts,
    })

    onClose()
  }

  return (
    <DarkThemeProvider>
      <Dialog open={open} onClose={onClose} expandable disableEnforceFocus>
        <DialogTitle>Add Block</DialogTitle>
        <StyledDialogBody>
          <Blocks>
            {availableBlocks.map((type) => {
              const blockType = type as PurchasePageBlock['type']
              const definition = blockDefinitions[blockType]

              return (
                <Block
                  key={type}
                  type={type}
                  label={definition.label}
                  icon={definition.icon}
                  selected={block === type}
                  onClick={() => setBlock(blockType)}
                  onDoubleClick={() => {
                    setBlock(blockType)
                    addBlock()
                  }}
                />
              )
            })}
          </Blocks>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={addBlock}
            disabled={!block}
          >
            Add
          </Button>
        </StyledDialogBody>
      </Dialog>
    </DarkThemeProvider>
  )
}

function Block(props: {
  type: string
  label?: string
  icon: string
  selected: boolean
  onClick: () => void
  onDoubleClick: () => void
}) {
  const {label, type, icon, selected, onClick, onDoubleClick} = props

  return (
    <Container
      selected={selected}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <Icon className={icon} iconSize={24} />
      <Tiny color="white" align="center">
        {label ?? type}
      </Tiny>
    </Container>
  )
}

type CreateBlockHandlers = {
  [T in PurchasePageBlock as T['type']]: () => PurchasePageBlock
}

const createBlockHandlers: CreateBlockHandlers = {
  Title: createTitleBlock,
  Text: createTextBlock,
  Image: createImageBlock,
  Button: createButtonBlock,
  TicketSelector: createTicketSelectorBlock,
  Video: createVideoBlock,
  PurchaseForm: createPurchaseFormBlock,
  Separator: createSeparatorBlock,
  BulletedList: createBulletedListBlock,
  CountdownTimer: createCountdownTimerBlock,
  NumberedList: createNumberedListBlock,
  Icon: createIconBlock,
  FaqList: createFaqListBlock,
  Spacer: createSpacerBlock,
  Grid: createGridBlock,
}

const StyledDialogBody = styled(DialogBody)`
  padding-bottom: ${(props) => props.theme.spacing[8]}!important;
`

const Blocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing[4]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
`

const Container = styled.div<{selected: boolean}>`
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[2]};
  cursor: pointer;
  border-style: solid;
  border-width: ${(props) => (props.selected ? '4px' : '2px')};
  border-color: ${(props) =>
    props.selected ? props.theme.colors.primary : 'white'};
  border-radius: ${(props) => props.theme.spacing[2]};
  i,
  span {
    color: ${(props) =>
      props.selected ? props.theme.colors.primary : 'white'};
  }
  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
    i,
    span {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`
