import React from 'react'
import {Question} from 'organization/Event/QuestionsProvider'
import SingleMetric from './SingleMetric'
import Chart from './ChartStatisticsItem'
import {useFormStatistics} from 'lib/event-api/forms/use-form-statistics'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useWithGuestTranslations} from 'Event/LanguageProvider/translations'

export type QuestionStatisticsProps = {
  question: Question
}

export default function QuestionStatistics(props: QuestionStatisticsProps) {
  const {question} = props
  const {form} = useForm()
  const {data: formStatistics} = useFormStatistics({form})
  const v = useWithGuestTranslations()

  const questionStatistics = formStatistics?.questions.find(
    (q) => q.id === question.id,
  )

  if (!questionStatistics?.answers) {
    return (
      <SingleMetric
        name={v(question.label)}
        count={questionStatistics?.submission_count ?? 0}
      />
    )
  }

  if (!('options' in question)) {
    return <Chart name={v(question.label)} data={[]} />
  }

  const data = question.options
    .map((option) => {
      const answerCount =
        questionStatistics.answers?.[option.value]?.submission_count || 0

      return {
        name: v(option.value),
        value:
          questionStatistics.submission_count > 0
            ? parseInt(
                `${(answerCount / questionStatistics.submission_count) * 100}`,
              )
            : 0,
      }
    })
    .sort((a, b) => b.value - a.value)

  return <Chart name={v(question.label)} data={data} />
}
