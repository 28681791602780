import {Panels} from 'Event/template/Panels'
import setAtPath from 'lodash/set'
import {SimpleBlog} from 'Event/template/SimpleBlog'
import {InputStyles} from 'Event/Question'
import {Cards} from 'Event/template/Cards'
import {Rule} from 'Event/attendee-rules'
import requireContext from 'require-context.macro'
import {InputStyleProps} from 'Event/auth/TextField'
import {HashMap, Ordered} from 'lib/list'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {NiftyFifty} from 'Event/template/NiftyFifty'
import {Lefty} from 'Event/template/Lefty'
import {Font} from 'lib/FontSelect'
import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import {FAQ} from 'Event/FAQs'
import {Sponsor} from 'Event/Sponsors'
import {Speaker} from 'Event/Speakers'
import {Column} from 'lib/ui/layout'
import {Background} from 'organization/Event/BackgroundsConfig'
import {Script} from 'Event/Scripts'
import {Tags} from 'Event/attendee'
import {Group} from 'organization/Event/AttendeesProvider'
import {Townhall} from 'Event/template/Townhall'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {LoginButtonProps} from 'Event/auth/Login/SubmitButton/Button'

/**
 * Required details that every template should provide.
 */
export type Details = {
  // Label - template name that the user sees. No restrictions
  // on uniqueness (although it should be), or characters
  // that are allowed.
  label: string
  // Preview URL - a public URL where a user can access, and
  // view a live version of the template as an attendee.
  previewUrl: string
}

/**
 * Base properties shared by all templates
 */
export type BaseTemplate = {
  version: number
  font: Font | null
  bodyHTMLEmbed?: string | null
  textColor: string

  /**
   * Reward alert is the pop-up that tells the user
   * they have received points for an action.
   */
  rewardAlert?: {
    text?: string
    backgroundColor?: string
    textColor?: string
  }

  emojiPage?: {
    backgroundColor?: string
    backgroundOpacity?: number
    backgroundEnabled?: boolean
    backgroundImage?: string | null
  }

  postStyles?: {
    titleFont?: Font | null
    titleTextColor?: string | null
    titleFontSize?: number
    dateTextColor?: string
    dateFontSize?: number
    contentFontSize?: number
    contentTextColor?: string | null
    spacing?: number
  }

  postFormStyles?: {
    width?: number
    position?: string
    buttonSize?: number
    buttonRadius?: number
    buttonColor?: string
    buttonBackgroundColor?: string
    buttonHoverBackgroundColor?: string
    buttonPosition?: string
    buttonFontSize?: number
    buttonBorderWidth?: number
    buttonBorderColor?: string
    inputStyles?: InputStyles
  }

  signUp: {
    linkLabel: string
    buttonText: string
    description: string
    firstNameLabel: string
    lastNameLabel: string
    phoneNumberLabel?: string
    phoneNumberRequired?: boolean
    hasPhoneNumberField?: boolean
    groups?: Group[]
    tags?: Tags
    error: {
      emailAlreadyTakenMessage?: string
    }
  }

  login: {
    background: string | null
    backgroundColor: string
    backgroundOpacity: number
    backgroundEnabled: boolean
    logo: string | null
    logoEnabled: boolean
    logoSize: number
    input: InputStyleProps
    emailLabel: string
    passwordLabel: string
    description: {
      text: string
      color: string | null
      fontSize: number
    }
    passwordReset: {
      linkLabel: string
      buttonText: string
      description: string
      successMessage: string
      iconName: string | null
      iconColor?: string
    }
    submitButton: LoginButtonProps
    requiresPassword: boolean
    skipSetPasswordRules: Rule[]
  }
  changePassword: {
    input: InputStyleProps
    newPasswordLabel: string
    confirmNewPasswordLabel: string
    description: {
      text: string
      color: string
      fontSize: number
    }
    submitButton: {
      backgroundColor: string
      textColor: string
      hoverColor?: string
      label: string
      borderRadius?: number
    }
  }
  waiver: {
    isEnabled: boolean
    submittingText: string
    button: NavButtonWithSize
    skipRules: Rule[]
    checkboxColor: string
    targets: HashMap<Waiver>
    input?: InputStyleProps
    firstNameInputLabel?: string
    lastNameInputLabel?: string
    signature: {
      typeButtonText?: string
      typeButtonColor?: string
      typeInputLabel?: string
    }
    signatureRequired?: boolean
    formEnabled?: boolean
  }
  techCheck: {
    isEnabled: boolean
    body: string
    additionalContent: string
    // start: string
    areaKey: string | null
    startButton: NavButtonWithSize
    customButtons: HashMap<NavButtonWithSize>
    hasCustomButtons: boolean
    skipRules: Rule[]
  }
  logo: string | null
  points_unit: string
  blogPosts: HashMap<BlogPost>
  imageWaterfall: {
    title?: string
    description?: string
    backToDashboardText?: string
    backToDashboardTextColor?: string
    uploadFormTitle?: string
    uploadFormDescription?: string
    uploadButtonText?: string
    uploadButtonFontSize?: number
    uploadButtonBorderRadius?: number
    uploadButtonTextColor?: string
    uploadButtonBackgroundColor?: string
    uploadButtonBorderColor?: string
    actionId?: string | null
    isVisible?: boolean
    page?: {
      backgroundColor?: string
      backgroundOpacity?: number
      backgroundEnabled?: boolean
      backgroundImage?: string | null
      addImageIntervalSecs?: number
      numColumns?: number
    }
  }
  menu: {
    backgroundColor: string
    iconColor: string
    textColor: string
  }
  faq?: {
    isEnabled?: boolean
    title?: string
    description?: string
    items: HashMap<FAQ>
  }
  sponsors: {
    isEnabled?: boolean
    title?: string
    description?: string
    questionIcon?: string | null
    items: HashMap<Sponsor>
  }
  speakers: {
    isEnabled?: boolean
    title?: string
    description?: string
    items: HashMap<Speaker>
    imageSize?: Column
  }
  zoomBackgrounds: {
    isEnabled?: boolean
    title?: string
    description?: string
    items: HashMap<Background>
    borderColor?: string
    borderRadius?: number
    borderThickness?: number
    imagesPerRow?: number
  }
  scripts: HashMap<Script>
  offlinePage: {
    shouldRedirect?: boolean
    title?: string
    description?: string
    redirectUrl?: string
  }
  formModeration: {
    form: {
      [formId: string]: {
        lowerThird: FormModerationLowerThirdConfig
      }
    }
  }

  countDownTimers?: HashMap<CountDownTimerSettings>
}

export type FormModerationLowerThirdConfig = {
  size?: number
  position?: string
  logo?: string | null
  backgroundColor?: string
  borderColor?: string
  borderRadius?: number
  borderThickness?: number
  horizontalMargin?: number
  verticalMargin?: number
  names?: {
    font: Font | null
    fontSize?: number
    fontColor: string | null
  }
  answers?: {
    font: Font | null
    fontSize?: number
    fontColor: string | null
  }
}

export type Waiver = Ordered & {
  title: string
  body: string
  formId: number | null
  agreeStatement: string
  signaturePrompt: string
  rules: Rule[]
}

export const SIDEBAR_NAV = 'Sidebar Nav'
export type SidebarNavProps = Ordered & {
  type: typeof SIDEBAR_NAV
  buttons: HashMap<NavButtonWithSize>
}

export type Template =
  | SimpleBlog
  | Panels
  | Cards
  | NiftyFifty
  | Lefty
  | Townhall

/**
 * Build a tree of all the exports in the template directories.
 * It should handle index.tsx exports, as well as
 * default exports.
 *
 * Example: SimpleBlog/Step1/SetPasswordForm.tsx
 * {
 *   SimpleBlog: {
 *     Step1: {
 *        SetPasswordForm
 *     }
 *   }
 * }
 */
function importTemplate(r: __WebpackModuleApi.RequireContext) {
  const result = r.keys().reduce((modules, i) => {
    const paths = i.split('/')
    paths.shift() // remove leading '.' path

    // Create a keypath, and remove extension to avoid the extension
    // being set as the final key.
    const keyPath = paths.join('.').replace(/.tsx$/, '')

    const module = r(i)
    const hasDefaultExport = Object.prototype.hasOwnProperty.call(
      module,
      'default',
    )

    const component = hasDefaultExport ? module.default : module

    // Use lodash's mutating _.set to merge/set.
    setAtPath(modules, keyPath, component)

    return modules
  }, {} as Record<string, any>)

  return result
}

export const components = importTemplate(
  // Have to use a macro for require.context as jest
  // does not have require.context since its not
  // built via webpack.
  requireContext('./', true, /^.\/.*\/[a-zA-Z0-9]*.tsx$/),
)

/**
 * Templates
 * -----------------------------------------------
 * A dynamically imported object for each templates
 * template/index.ts
 */
export const templates: Record<Template['name'], any> = importTemplates(
  // Need to use babel's requireContext macro because jest runs in node (not weback)
  // and does not have in-built require.context.
  requireContext('./', true, /^.\/.*\/index.ts$/),
)

function importTemplates(r: __WebpackModuleApi.RequireContext) {
  const result = r.keys().reduce((modules, i) => {
    const paths = i.split('/')
    paths.shift() // remove leading '.' path

    // Create a keypath, and remove extension to avoid the extension
    // being set as the final key.
    const keyPath = paths.join('.').replace('.index', '').replace(/.ts$/, '')

    const root = r(i)

    // Use lodash's mutating _.set to merge/set.
    setAtPath(modules, keyPath, root)

    return modules
  }, {} as Record<string, any>)

  return result
}

export const details = (template: Template['name']): Details =>
  getRequiredTemplateExport(template, 'details')

export const createBlank = (template: Template['name']): Template =>
  getRequiredTemplateExport(template, 'createBlank')()

export const sampleEventURL = (template: Template['name']): string =>
  getRequiredTemplateExport(template, 'sampleEventURL')

export const defaults = (template: Template['name']) =>
  getRequiredTemplateExport(template, 'DEFAULTS')

export const convert = (
  current: Template,
  newTemplate: Template['name'],
): Template => getRequiredTemplateExport(newTemplate, 'convert')(current)

export function getTemplateExport(template: Template['name'], key: string) {
  try {
    return templates[template][key]
  } catch {
    return null
  }
}

/**
 * Returns a template property that is required for ALL templates
 * to export from their respective modules roots (index.ts).
 *
 * @param template
 * @param key
 * @returns
 */
export function getRequiredTemplateExport(
  template: Template['name'],
  key: string,
) {
  const value = getTemplateExport(template, key)
  if (!value) {
    throw new Error(
      `Missing '${key}' for ${template}. It should be exported from src/Event/template/${template}/index.ts`,
    )
  }

  return value
}
