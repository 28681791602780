import React from 'react'
import styled from 'styled-components'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {Publishable} from 'Event/Dashboard/editor/views/Published'
import {HasRules} from 'Event/attendee-rules'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Published from 'Event/Dashboard/editor/views/Published'
import {Draggable, DraggableProvidedDraggableProps} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {DragHandle, DraggableOverlay} from 'lib/ui/drag-and-drop'
import {useAttendeeVariables} from 'Event'
import {Icon} from 'lib/fontawesome/Icon'
import {ResourceItemConfig} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig'
import {useResourceUrl} from 'Event/Dashboard/components/resource'
import {Ordered} from 'lib/list'
import {useSubmitResourceAction} from 'Event/ActionsProvider'
import {HasSchedule} from 'lib/ui/layout/Scheduled'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {Column} from 'lib/ui/layout'
import Grid from '@material-ui/core/Grid'
import CustomButton, {CustomButtonProps} from 'lib/ui/Button/CustomButton'
import Configurable from 'organization/Event/Configurable'

export type Resource = Publishable &
  Ordered &
  HasRules &
  Pick<
    CustomButtonProps,
    | 'backgroundColor'
    | 'fontSize'
    | 'textColor'
    | 'hoverTextColor'
    | 'hoverBackgroundColor'
    | 'disableHover'
    | 'height'
    | 'borderColor'
    | 'hoverBorderColor'
    | 'borderWidth'
    | 'borderRadius'
  > &
  HasSchedule & {
    name: string
    filePath: string
    actionId: string | null
    icon: string | null
    isUrl?: boolean
    url?: string
    font: Font | null
    size: Column
    newLine?: boolean
  }

export const RESOURCE_ITEM = 'Resource Item'
export type ResourceItemProps = {
  id: string
  sectionId: string
  resource: Resource
  index: number
  draggableId: string
}

export default React.memo((props: ResourceItemProps) => {
  const {resource, index, id, draggableId, sectionId} = props
  const isEdit = useEditMode()
  const lineBreak = resource.newLine ? <SpacerGrid item xs={12} /> : null

  if (!isEdit)
    return (
      <>
        {lineBreak}
        <Container resource={resource} xs={12} md={resource.size}>
          <ResourceItemLink resource={resource} />
        </Container>
      </>
    )

  return (
    <>
      {lineBreak}
      <Draggable draggableId={draggableId} index={index}>
        {(provided) => (
          <Container
            resource={resource}
            ref={provided.innerRef}
            draggableProps={provided.draggableProps}
            xs={12}
            md={resource.size}
          >
            <DraggableOverlay>
              <>
                <StyledDragHandle handleProps={provided.dragHandleProps} />
                <Configurable aria-label="resource item config">
                  <ResourceItemConfig
                    resource={resource}
                    id={id}
                    sectionId={sectionId}
                  />
                  <ResourceItemLink resource={resource} />
                </Configurable>
              </>
            </DraggableOverlay>
          </Container>
        )}
      </Draggable>
    </>
  )
})

export function ResourceItemLink(props: {resource: Resource}) {
  const url = useResourceUrl(props.resource)
  const v = useAttendeeVariables()
  const awardPoints = useSubmitResourceAction(props.resource)
  const {resource} = props
  useLoadFont(resource.font)

  return (
    <>
      <StyledButton
        font={resource.font}
        fontSize={resource.fontSize}
        textColor={resource.textColor}
        hoverTextColor={resource.hoverTextColor}
        backgroundColor={resource.backgroundColor}
        hoverBackgroundColor={resource.hoverBackgroundColor}
        disableHover={resource.disableHover}
        minHeight={resource.height}
        borderColor={resource.borderColor}
        hoverBorderColor={resource.hoverBorderColor}
        borderWidth={resource.borderWidth}
        borderRadius={resource.borderRadius}
        hoverBorder={resource.hoverBorderColor!}
        hoverIcon={resource.hoverTextColor!}
        fullWidth
      >
        <ResourceLink
          aria-label="event resource"
          to={url}
          onClick={awardPoints}
          newTab
        >
          <ButtonContent>
            <StyledIcon
              iconClass={resource.icon}
              color={resource.textColor}
              icon={resource.icon}
            />
            <LinkText aria-label="resource link">{v(resource.name)}</LinkText>
          </ButtonContent>
        </ResourceLink>
      </StyledButton>
    </>
  )
}

export const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    resource: Resource
    xs: Column
    md: Column
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  return (
    <VisibleOnMatch rules={props.resource.rules}>
      <Published component={props.resource}>
        <Grid
          item
          xs={props.xs}
          md={props.md}
          ref={ref}
          {...props.draggableProps}
        >
          {props.children}
        </Grid>
      </Published>
    </VisibleOnMatch>
  )
})

const ResourceLink = styled(AbsoluteLink)`
  align-items: center;
  min-height: 20px;
  font-size: inherit;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[1]};
  color: inherit !important;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  &:hover {
    text-decoration: none !important;
  }
`

const LinkText = styled.span`
  font-weight: bold;
  margin: 0 auto;
`

const StyledIcon = styled(Icon)<{
  icon: string | null
}>`
  margin-right: ${(props) => props.theme.spacing[3]};
  display: ${(props) => (props.icon ? 'inline;' : 'none;')};
`

const StyledButton = styled(CustomButton)<{
  font: Font | null
  hoverBorder: string
  hoverIcon: string
}>`
  ${(props) => fontStyles(props.font)}
  display: flex;
  align-items: center;
  padding: 15px 30px;

  &:hover {
    border-color: ${(props) => props.hoverBorder} !important;
    i {
      color: ${(props) => props.hoverIcon};
    }
  }
`

const ButtonContent = styled.div`
  margin: 0 auto;
`

const SpacerGrid = styled(Grid)`
  padding: 0 !important;
`

const StyledDragHandle = styled(DragHandle)`
  position: absolute;
  right: 50px;
  top: 5px;
`
