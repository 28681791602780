import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react'
import styled from 'styled-components'
import {useLayout} from 'organization/Obie/Layout'
import {
  OBIE_NEW_LINE,
  OBIE_RESPONSE_SPLITER,
} from 'organization/Obie/ObieServiceProvider'
import ChatIcon from 'organization/Obie/Blocks/ProcessForm/ChatIcon'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'

const TYPE_WRITER_SPEED = 10

export default function TypeWriter(props: {
  text: string
  onFinish: () => void
  updateCompletion?: (newValue: string) => void
}) {
  const {text, onFinish} = props
  const [showSecond, setShowSecond] = useState<boolean>(false)
  const [blurbText, setBlurbText] = useState<string>('')
  const [contentText, setContentText] = useState<string>('')

  // Timeout to pause between typing out the "OBIE" blurb and the actual
  // Completion response text.
  const pauseThenRenderSecond = useCallback(() => {
    const timer = setTimeout(() => setShowSecond(true), 1000)

    return () => clearTimeout(timer)
  }, [setShowSecond])

  // Need this in a useEffect in case that user clicks "regenerate" and we end
  // up getting new text, we need to reset the state in here so that the type-
  // writer effect can start over again.
  useEffect(() => {
    if (!text) {
      return
    }

    let processedText = (text || '').replaceAll(OBIE_NEW_LINE, '\n')

    const textParts = processedText.split(OBIE_RESPONSE_SPLITER)

    setBlurbText(textParts[0])
    setContentText(textParts[1])
    setShowSecond(false)
  }, [setShowSecond, text])

  return (
    <TypedText>
      <HiddenMarkdown
        text={blurbText}
        onFinish={pauseThenRenderSecond}
        showing={true}
      />
      <HiddenMarkdown
        text={contentText}
        onFinish={onFinish}
        showing={showSecond}
        updateCompletion={props.updateCompletion}
      />
    </TypedText>
  )
}

const HiddenMarkdown = (props: {
  text: string
  onFinish: () => void
  showing: boolean
  updateCompletion?: (newValue: string) => void
}) => {
  const {text, onFinish, showing} = props
  const {contentRef} = useLayout()

  const [typingText, setTypingText] = useState<string>('')
  const finished = useRef<boolean>(false)
  const position = useRef<number>(0)
  const animationFrame = useRef<number>(0)

  const noChange = useMemo(() => (_value: string) => {}, [])

  const doTyping = useCallback(() => {
    const timer = setTimeout(() => {
      // If our current position is LESS THAN the length of the text we're typing,
      // we're good to process the character for whatever position we're at.
      if (position.current < text.length) {
        setTypingText(text.slice(0, position.current))
        position.current++

        // If we have a reference to the content container, scroll it with each
        // character type. It may not actually scroll because we're not on the
        // next line yet, but we don't know when we're on the next line... So just
        // scroll it.
        if (contentRef.current) {
          contentRef.current.scrollTop = contentRef.current.scrollHeight
        }

        // Recurse, because we're operating on a timeout, not an interval.
        animationFrame.current = requestAnimationFrame(doTyping)
      }

      // When the current position is GREATER THAN the length of the text being
      // typed, it's time to "finish up".
      if (position.current >= text.length) {
        finished.current = true
        onFinish()
        clearTimeout(timer)
        cancelAnimationFrame(animationFrame.current)
      }
    }, TYPE_WRITER_SPEED)

    // Standard cleanup on unload.
    return () => {
      clearTimeout(timer)
      cancelAnimationFrame(animationFrame.current)
    }
  }, [contentRef, onFinish, text])

  // When showing is false, we're not being told to show this HiddenMarkDown to
  // the user yet.
  if (!showing) {
    return null
  }

  // When typingText is empty (we haven't typed anything yet) and we have text to
  // start animating, kick off the process.
  if (!typingText && text) {
    animationFrame.current = requestAnimationFrame(doTyping)
  }

  // Even if there are no updates to make, we want to render the typing text in
  // a MarkdownEditor component because there is likely actual markdown to format.
  if (!props.updateCompletion) {
    return <MarkdownEditor onChange={noChange} data={typingText} theme="Dark" />
  }

  return (
    <div id="content-text">
      <MarkdownEditor
        data={typingText}
        onChange={props.updateCompletion}
        theme="Dark"
      />
      <ChatIcon showing={!finished.current && !!text} />
    </div>
  )
}

export const TypedText = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: white;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  line-height: 1.5;
  text-align: left;
  width: 100%;
  padding: 0 var(--ck-spacing-standard);
`

export const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: white;
  line-height: 1.5;
  text-align: left;
  width: 100%;
  padding: 0 var(--ck-spacing-standard);
`
