import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import TextField from 'lib/ui/TextField'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'

export default function SubmitButtonConfig() {
  const save = useSaveTemplate()
  const {
    waiver: {button, submittingText},
  } = useTemplate()
  const {
    form: {register, control, watch, getValues},
  } = useConfig()

  const submit = () => {
    save({
      waiver: {
        button: getValues(),
      },
    })
  }

  return (
    <Config title="Submit Button" onSave={submit}>
      <SettingsPanel>
        <ContentInput
          button={button}
          control={control}
          register={register}
          watch={watch}
        />
        <TextField
          label="Submitting Text"
          name="submittingText"
          defaultValue={submittingText}
          inputProps={{
            ref: register,
            'aria-label': 'submitting text',
          }}
          fullWidth
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={button}
          control={control}
          storageKey="waiver.submitButton.config.typography"
        />
        <BackgroundSection
          button={button}
          control={control}
          watch={watch}
          storageKey="waiver.submitButton.config.background"
        />
        <SizeSection
          button={button}
          control={control}
          storageKey="waiver.submitButton.config.size"
          disableNewLine
        />
        <BorderSection
          button={button}
          control={control}
          storageKey="waiver.submitButton.config.border"
        />
      </StylingPanel>
    </Config>
  )
}
