import styled from 'styled-components'
import React from 'react'
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd'
import DragHandleIcon from '@material-ui/icons/DragHandle'

export function DragHandle(props: {
  handleProps?: DraggableProvidedDragHandleProps
  className?: string
}) {
  return (
    <DragHandleBox
      {...props.handleProps}
      aria-label="button drag handle"
      className={`${props.className || ''} drag-handle`}
    >
      <DragHandleIcon />
    </DragHandleBox>
  )
}

const DragHandleBox = styled.div`
  z-index: 4;
  background: #ffffff;
  display: none;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: ${(props) => props.theme.colors.primary};
  }
`

/**
 * Handle the show drag handle on hover.
 * If an ID is passed in, we only want to target the draggable handle id to avoid
 * selecting all nested DragHandleBox. Fixes a bug where a single hover
 * would cause all drag handles to show.
 *
 */

export const DraggableOverlay = styled.div<{draggableId?: string}>`
  position: relative;

  &:hover
    ${(props) =>
      props.draggableId
        ? `[data-rbd-drag-handle-draggable-id="${props.draggableId}"]`
        : DragHandleBox} {
    display: inline-flex;
  }
`
