import React from 'react'
import {BlockComponentProps} from '../index'
import {PurchaseFormBlock} from '../PurchaseForm'
import {ButtonBase as Button} from '../Button/ButtonBase'
import {Body} from '../Button/Content'
import styles from './PurchaseForm.module.css'

export interface PurchaseFormButtonProps {
  config: PurchaseFormBlock & BlockComponentProps
  text: string
  disabled?: boolean
  onClick?: () => void
  button: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
  }
  'aria-label'?: string
  type?: 'submit' | 'button' | 'reset'
}

export default function PurchaseFormButton(props: PurchaseFormButtonProps) {
  const {config, disabled, onClick, button, type, text} = props

  const buttonId = `purchase-form-button-${config.id}`
  const buttonClassName = styles.purchaseFormButton

  const bodyProps = {
    ...button,
    ...config,
    ...config.buttonStyle,
    text: text,
  }

  const customStyles = {
    ...config.buttonStyle,
    height: '100%',
    minHeight: config.buttonStyle.height,
  }

  return (
    <div
      style={{
        marginTop: `${config.purchaseButton.margin?.top ?? 32}px`,
        marginRight: `${config.purchaseButton.margin?.right ?? 0}px`,
        marginBottom: `${config.purchaseButton.margin?.bottom ?? 0}px`,
        marginLeft: `${config.purchaseButton.margin?.left ?? 0}px`,
      }}
    >
      <Button
        {...customStyles}
        disabled={disabled}
        onClick={onClick}
        type={type}
        id={buttonId}
        className={buttonClassName}
      >
        <Body {...bodyProps} type="Button" />
      </Button>
    </div>
  )
}
