import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Button from 'lib/ui/Button'
import {uuid} from 'lib/uuid'
import React from 'react'

export default function NewCustomButton() {
  const save = useSaveTemplate()
  const {
    techCheck: {customButtons},
  } = useTemplate()

  const numButtons = Object.keys(customButtons).length

  const addButton = () => {
    const id = uuid()

    const button: NavButtonWithSize = {
      position: numButtons + 1,
      text: 'Button',
      link: '',
      size: 12,
      rules: [],
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      isCertificateButton: false,
      areaId: null,
      formId: null,
      certificateId: null,
      newTab: true,
      actionId: null,
      isVisible: true,
      infusionsoftTag: null,
      mailchimpTag: null,
      zapierTag: null,
      highLevelTag: null,
      webhook: null,
      hubspotTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
    }

    save({
      techCheck: {
        customButtons: {
          [id]: button,
        },
      },
    })
  }
  return (
    <Button
      variant="outlined"
      color="primary"
      aria-label="add tech check button"
      onClick={addButton}
      fullWidth
    >
      New Button
    </Button>
  )
}
