import React from 'react'
import {BlockComponentProps} from '../Block'
import {BlockBase} from './base'
import {rgba} from '../lib/color'

interface ContainerProps extends BlockBase, BlockComponentProps {
  children: React.ReactNode
  id: string
  className?: string
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    const {
      background,
      border,
      padding,
      alignment,
      id,
      children,
      className,
    } = props

    const backgroundColor = rgba(
      background.color || '#FFFFFF',
      background.opacity / 100,
    )

    return (
      <div
        className={className}
        id={id}
        ref={ref}
        style={{
          backgroundImage: background.image
            ? `url(${background.image})`
            : undefined,
          backgroundColor,
          boxShadow: `inset 0 0 0 100cqh ${backgroundColor}`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: `${border.width}px solid ${border.color}`,
          borderRadius: `${border.radius}px`,
          paddingTop: `${padding.top}px`,
          paddingRight: `${padding.right}px`,
          paddingBottom: `${padding.bottom}px`,
          paddingLeft: `${padding.left}px`,
          display: 'flex',
          justifyContent: alignment.horizontal,
          alignItems: alignment.vertical,
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </div>
    )
  },
)

export default Container
