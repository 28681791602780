import React from 'react'

export default function ChatIcon(props: {showing?: boolean}) {
  if (!props.showing) {
    return null
  }

  return (
    <i
      className="fa-sharp fa-regular fa-comment-dots fa-beat fa-xl"
      style={{
        //@ts-ignore
        '--fa-animation-duration': '3s',
        color: '#FFFFFF',
        opacity: 0.6,
      }}
    ></i>
  )
}
