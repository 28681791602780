import ConfigPanel, {
  CancelButton,
  Footer,
  RemoveButton,
  SaveButton,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useConfig} from 'organization/Event/Configurable'
import React from 'react'
import {
  useAutoUpdate,
  useHasUnsavedChanges,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {withoutPropagating} from 'lib/dom'
import {JsonSave} from 'lib/JsonUpdateProvider'

export default function Config<T>(props: {
  title: string
  onSave: JsonSave<T>
  children: JSX.Element | JSX.Element[]
  footer?: JSX.Element
  onRemove?: () => void
  /**
   * Current values to show for the preview. If this is not provided, we'll just
   * use the current form.watch() values.
   */
  preview?: Record<string, any>
}) {
  const {onSave, title, children} = props
  const {showing, toggle, form, getPreviewData} = useConfig()

  const footer = props.footer || <DefaultFooter onRemove={props.onRemove} />

  const values = form.watch()
  const previewValues = props.preview || values

  useAutoUpdate({
    values: getPreviewData ? getPreviewData(previewValues) : previewValues,
    when: showing,
  })

  const hasChanges = useHasUnsavedChanges(values)

  const save = (data: any) => {
    onSave(data)
    toggle()
  }

  const panels = Array.isArray(children) ? children : [children]

  const content = [...panels, footer].map((el, index) =>
    React.cloneElement(el, {
      key: index,
    }),
  )

  return (
    <ConfigPanel
      title={title}
      showing={showing}
      onClose={toggle}
      onSubmit={withoutPropagating(form.handleSubmit(save))}
      hasChanges={hasChanges}
    >
      {content}
    </ConfigPanel>
  )
}

function DefaultFooter(props: {onRemove?: () => void}) {
  const {clear: clearUpdates} = useTemplateEditor()
  const {toggle: closeConfig} = useConfig()
  const {onRemove} = props

  const rightButton = onRemove ? (
    <RemoveButton
      onClick={() => {
        clearUpdates()
        onRemove()
      }}
    />
  ) : (
    <CancelButton onClick={closeConfig} />
  )

  return (
    <Footer>
      <SaveButton />
      {rightButton}
    </Footer>
  )
}
