import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'lib/ui/Button'
import Dialog from 'lib/ui/Dialog'
import TextField from 'lib/ui/TextField'
import {Completion, useObieService} from 'organization/Obie/ObieServiceProvider'

export default function NameCompletionDialog(props: {
  completion: Completion
  onClose: () => void
  open: boolean
  viewCompletions: () => void
  completionText: string
}) {
  const {completion, onClose, open, viewCompletions} = props
  const {handleSubmit, control} = useForm()
  const {updateAnswerSet, updateCompletion} = useObieService()

  if (!open) {
    return null
  }

  const onSubmit = (formData: any) => {
    const {block_id, answer_set_id} = completion

    updateAnswerSet(block_id, answer_set_id, formData.name).then(() => {
      updateCompletion(completion.id, props.completionText).then(() => {
        onClose()
        viewCompletions()
      })
    })
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Name this Completion</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue={completion.answer_set_name}
            render={({value, onChange}) => (
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={onChange}
                required
                type="text"
                value={value}
                variant="outlined"
              />
            )}
          />
          <StyledButton type="submit" variant="contained" color="primary">
            Continue
          </StyledButton>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-right: ${(props) => props.theme.spacing[3]};
`
