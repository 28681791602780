import FormSelectInput from 'organization/Event/FormsProvider/FormSelect'
import {Question} from 'Event/Question'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import {useSubmissions} from 'Event/SubmissionsProvider'
import React from 'react'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import {useEvent} from 'Event/EventProvider'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import CapWaiverForm from 'Event/Step2/Waivers/Waiver/CapWavierForm'
import UpwMarch23Form from 'Event/Step2/Waivers/Waiver/UpwMarch23Form'
import {useTemplate} from 'Event/TemplateProvider'
import {useToggle} from 'lib/toggle'
import CreateFormDialog from 'organization/Event/FormsConfig/CreateFormDialog'
import MundoParaleloForm from 'Event/Step2/Waivers/Waiver/MundoParaleloForm'
import {useCheckQuestionRules} from 'Event/Question/use-is-visible-question'

export default function Form() {
  const {
    waiver: {formId},
  } = useWaiver()
  const {
    form: {
      control,
      register,
      errors: formErrors,
      watch,
      setValue,
      responseError,
    },
  } = useWaiver()
  const {answers} = useSubmissions()
  const {postFormStyles: formStyles} = useTemplate()

  const checkQuestionRules = useCheckQuestionRules({watch, answers})

  const {
    event: {forms},
  } = useEvent()

  const target = forms.find((f) => f.id === formId)

  if (!formId || !target) {
    return (
      <EditModeOnly>
        <FormSelect />
      </EditModeOnly>
    )
  }

  // TODO: Remove temporary event code for 'cap' June, 2022.
  if (formId === 2075) {
    return (
      <CapWaiverForm
        form={target}
        formErrors={formErrors}
        control={control}
        register={register}
        answers={answers}
        responseError={responseError}
        setValue={setValue}
        watch={watch}
      />
    )
  }

  // TODO: Remove temporary event code for 'mundoparalelo' July, 2023
  if (formId === 3735) {
    return (
      <MundoParaleloForm
        form={target}
        formErrors={formErrors}
        control={control}
        register={register}
        answers={answers}
        responseError={responseError}
        setValue={setValue}
        watch={watch}
      />
    )
  }

  // TODO: Remove temporary event code for 'upw0323' March, 2023
  if (formId === 3107) {
    return (
      <UpwMarch23Form
        form={target}
        formErrors={formErrors}
        control={control}
        register={register}
        answers={answers}
        responseError={responseError}
        setValue={setValue}
        watch={watch}
      />
    )
  }

  return (
    <>
      <EditModeOnly>
        <FormSelect />
      </EditModeOnly>
      {target.questions.map((question, index) => {
        // Return null instead of filter to preserve index with
        // conditional questions.
        if (!checkQuestionRules(question)) {
          return null
        }

        return (
          <Question
            formErrors={formErrors}
            key={question.id}
            index={index}
            control={control}
            question={question}
            answers={answers}
            register={register}
            responseError={responseError}
            setValue={setValue}
            watch={watch}
            inputStyles={formStyles.inputStyles}
          />
        )
      })}
    </>
  )
}

function FormSelect() {
  const {id, waiver} = useWaiver()
  const save = useSaveTemplate()
  const {flag: showingAddFormDialog, toggle: toggleAddFormDialog} = useToggle()

  const setForm = (formId: number | null) => {
    save({
      waiver: {
        targets: {
          [id]: {
            formId,
          },
        },
      },
    })
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Form</InputLabel>
      <FormSelectInput
        onChange={setForm}
        value={waiver.formId}
        onCreateNewForm={toggleAddFormDialog}
      />
      <CreateFormDialog
        isVisible={showingAddFormDialog}
        onCreate={(form) => setForm(form.id)}
        onClose={toggleAddFormDialog}
      />
    </FormControl>
  )
}
