import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import TextField from 'lib/ui/TextField'
import IconPicker from 'lib/ui/form/IconPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import AlignButtons from 'lib/ui/form/AlignButtons'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import {PurchaseFormButtonProps} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormButton'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import ColorPicker from 'lib/ui/ColorPicker'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'

export default function PurchaseFormButtonConfig(
  props: PurchaseFormButtonProps,
) {
  const {
    config: {purchaseButton, buttonStyle, id, buttonsAlignment},
  } = props
  const {
    form: {control, register, watch},
  } = useConfig()

  const {update, sectionId} = useConfigurableSection()

  const save = (data: any) => {
    update(data.sections[sectionId])
  }

  return (
    <Config title="Purchase Button" onSave={save}>
      <SettingsPanel>
        <CollapsibleSection
          label="Content"
          storageKey={`blocks-${id}-settings.purchaseButton`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.purchaseButton.icon`}
            defaultValue={purchaseButton.icon || ''}
            control={control}
            render={({value, onChange}) => (
              <IconPicker value={value} onChange={onChange} />
            )}
          />

          <Controller
            name={`sections.${sectionId}.blocks.${id}.purchaseButton.iconAlign`}
            defaultValue={purchaseButton.iconAlign ?? 'left'}
            control={control}
            render={({value, onChange}) => (
              <AlignButtons value={value} onChange={onChange} />
            )}
          />
          <TextField
            name={`sections.${sectionId}.blocks.${id}.purchaseButton.text`}
            defaultValue={purchaseButton.text}
            label="Title"
            inputProps={{
              'aria-label': 'purchase button text',
              ref: register,
            }}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.purchaseButton.image`}
            control={control}
            defaultValue={purchaseButton.image || ''}
            render={({value, onChange}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                alt="button image"
                uploadInputProps={{
                  'aria-label': 'upload button image',
                }}
                removeButtonProps={{
                  'aria-label': 'remove button image',
                }}
                canResize
                disableAutoRemove
              />
            )}
          />
        </CollapsibleSection>
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={buttonStyle}
          control={control}
          storageKey={`blocks-${id}-styling.typography`}
          namePrefix={`sections.${sectionId}.blocks.${id}.buttonStyle`}
          label="Typography"
        />
        <BackgroundSection
          button={buttonStyle}
          control={control}
          watch={watch}
          storageKey={`blocks-${id}-styling.background`}
          namePrefix={`sections.${sectionId}.blocks.${id}.buttonStyle`}
        />
        <SizeSection
          button={buttonStyle}
          control={control}
          storageKey={`blocks-${id}-styling.size`}
          sizeUnit="percent"
          namePrefix={`sections.${sectionId}.blocks.${id}.buttonStyle`}
          disableNewLine
        />
        <CollapsibleSection
          label="Disabled button"
          storageKey={`blocks-${id}-styling.disabled`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.buttonStyle.disabledTextColor`}
            control={control}
            defaultValue={buttonStyle.disabledTextColor || undefined}
            render={({value, onChange}) => (
              <ColorPicker label="Text Color" color={value} onPick={onChange} />
            )}
          />

          <Controller
            name={`sections.${sectionId}.blocks.${id}.buttonStyle.disabledBackgroundColor`}
            control={control}
            defaultValue={buttonStyle.disabledBackgroundColor || undefined}
            render={({value, onChange}) => (
              <BackgroundPicker
                label="Background"
                background={value}
                onChange={onChange}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.buttonsAlignment`}
            control={control}
            defaultValue={buttonsAlignment || ''}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onChange}
              >
                <Option value="flex-start">Left</Option>
                <Option value="center">Center</Option>
                <Option value="flex-end">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <BorderSection
          button={buttonStyle}
          control={control}
          storageKey={`blocks-${id}-styling.border`}
          namePrefix={`sections.${sectionId}.blocks.${id}.buttonStyle`}
        />

        <CollapsibleSection
          label="Spacing"
          noDivider
          storageKey={`blocks-${id}-styling.spacing`}
        >
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.purchaseButton.margin`}
            values={
              purchaseButton.margin ?? {
                top: 32,
                bottom: 0,
                right: 0,
                left: 0,
              }
            }
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
