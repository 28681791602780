import {withStyles} from '@material-ui/core/styles'
import styled from 'styled-components'
import {
  useHasMultipleTabs,
  useNiftyFiftyTemplate,
} from 'Event/template/NiftyFifty'
import React, {useEffect} from 'react'
import Tabs from '@material-ui/core/Tabs'
import MuiTab, {TabProps} from '@material-ui/core/Tab'
import {TOP_BAR_HEIGHT} from 'Event/template/NiftyFifty/Page'
import {useAttendeeVariables} from 'Event'
import {useEditMode} from 'Event/EditModeProvider'
import {useQueryParams} from 'lib/url'

/**
 * Register tab keys here in order here.
 */
const TABS = [
  'home',
  'speakers',
  'sponsors',
  'resources',
  'points',
  'image_waterfall',
  'faq',
  'agenda_list',
]

export default function Bar(props: {
  currentTab: number
  onChangeTab: (index: number) => void
  'aria-label'?: string
}) {
  const {
    rightPanel,
    homeMenuTitle,
    resourceList: {menuTitle: resourceMenuTitle, isVisible: showingResources},
    leaderboard: {menuTitle: pointsMenuTitle, isVisible: showingPoints},
    speakers: {menuTitle: speakersMenuTitle, isEnabled: showingSpeakers},
    sponsors: {menuTitle: sponsorsMenuTitle, isEnabled: showingSponsors},
    imageWaterfall: {
      menuTitle: imageWaterfallTitle,
      isVisible: showingImageWaterfall,
    },
    faq: {menuTitle: faqsMenuTitle, isEnabled: showingFaqs},
    agendaList: {menuTitle: agendaListMenuTitle, isVisible: showingAgendaList},
  } = useNiftyFiftyTemplate()

  const {tab} = useQueryParams()

  const hasMultipleTabs = useHasMultipleTabs()

  const {currentTab, onChangeTab} = props
  const v = useAttendeeVariables()

  const StyledTabs = withStyles({
    root: {
      minHeight: `${TOP_BAR_HEIGHT}px`,
      borderBottom: '1px solid #C4C4C4',
      backgroundColor: rightPanel.barBackgroundColor,
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      borderRadius: '10px',
      '& > span': {
        maxWidth: rightPanel.indicatorWidth + '%',
        width: '100%',
        backgroundColor: rightPanel.tabUnderlineColor,
        borderRadius: '10px',
      },
    },
  })((props) => (
    <Tabs {...props} TabIndicatorProps={{children: <span />}} />
  )) as typeof Tabs

  // Auto-select tab if one is set in the url `?tab=sponsors`
  useEffect(() => {
    if (!tab) {
      return
    }

    const index = TABS.findIndex((t) => tab === t)

    const hasMatch = index >= 0
    if (!hasMatch) {
      return
    }

    onChangeTab(index)
  }, [tab, onChangeTab])

  return (
    <StyledTabs
      onChange={(_, tabIndex) => onChangeTab(tabIndex)}
      value={currentTab}
      centered
      aria-label={props['aria-label']}
    >
      <Tab
        showing={hasMultipleTabs}
        label={v(homeMenuTitle)}
        aria-label="niftyfifty tab home"
      />
      <Tab
        label={v(speakersMenuTitle)}
        aria-label="niftyfifty tab speakers"
        showing={showingSpeakers}
      />
      <Tab
        label={v(sponsorsMenuTitle)}
        aria-label="niftyfifty tab sponsors"
        showing={showingSponsors}
      />
      <Tab
        label={v(resourceMenuTitle)}
        aria-label="niftyfifty tab resources"
        showing={showingResources}
      />
      <Tab
        label={v(pointsMenuTitle)}
        aria-label="niftyfifty tab points"
        showing={showingPoints}
      />
      <Tab
        label={v(imageWaterfallTitle)}
        aria-label="niftyfifty tab image water fall"
        showing={showingImageWaterfall}
      />
      <Tab
        label={v(faqsMenuTitle)}
        aria-label="niftyfifty tab faqs"
        showing={showingFaqs}
      />
      <Tab
        label={v(agendaListMenuTitle)}
        aria-label="niftyfifty tab agenda list"
        showing={showingAgendaList}
      />
    </StyledTabs>
  )
}

function Tab(props: {showing: boolean} & TabProps) {
  const {rightPanel} = useNiftyFiftyTemplate()

  const isEditMode = useEditMode()
  const showing = isEditMode ? true : props.showing // always show tab when editing

  return (
    <StyledTab {...props} color={rightPanel.barTextColor} showing={showing} />
  )
}

const StyledTab = styled(
  (props: {showing: boolean; color: string} & TabProps) => {
    const {showing: _, ...tabProps} = props

    return <MuiTab {...tabProps} />
  },
)`
  ${(props) => (props.showing ? '' : 'display: none;')}
  color: ${(props) => props.color};
  font-weight: bold;
  text-transform: none;
  height: ${TOP_BAR_HEIGHT}px;
  min-width: auto;
`
