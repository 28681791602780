import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from './Styling'
import {AgendaListSettings} from 'Event/template/NiftyFifty/Dashboard/AgendaList'
import Settings from './Settings'

export function AgendaListConfig(props: {agendaList: AgendaListSettings}) {
  const {agendaList} = props

  const save = useSaveTemplate()

  return (
    <Config title="Agendas" onSave={save}>
      <SettingsPanel>
        <Settings agendaList={agendaList} />
      </SettingsPanel>
      <StylingPanel>
        <Styling agendaList={agendaList} />
      </StylingPanel>
    </Config>
  )
}
