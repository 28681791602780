import React, {useMemo} from 'react'
import {StripeElementStyle} from '@stripe/stripe-js'
import {formatPrice} from '../../lib/currency'
import {usePurchaseForm} from './PurchaseFormProvider'
import {usePurchase} from '../../PurchaseContext'
import {PurchaseFormBlock, PurchaseFormProps} from '.'
import CardFields from '../../lib/ui/stripe/CardFields'
import {BlockComponentProps} from '../index'
import SectionLabel from './SectionLabel'
import PurchaseSummaryColumnLabel from './PurchaseSummaryColumnLabel'

interface OrderConfirmationProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  PurchaseSummaryLabelComponent?: React.FC<any>
  ItemLabelComponent?: React.FC<any>
  TotalAmountLabelComponent?: React.FC<any>
  CardFieldsLabelComponent?: React.FC<any>
}

export default function OrderConfirmation(props: OrderConfirmationProps) {
  const {
    input: {fontSize: inputFontSize, spacing},
    summaryColumnLabel,
    PurchaseSummaryLabelComponent = (
      props: PurchaseFormProps & BlockComponentProps,
    ) => (
      <SectionLabel {...props}>
        {props.purchaseSummaryLabel || 'Purchase Summary'}
      </SectionLabel>
    ),
    ItemLabelComponent = (props: PurchaseFormProps & BlockComponentProps) => (
      <PurchaseSummaryColumnLabel {...props}>
        {props.itemLabel || 'Item'}
      </PurchaseSummaryColumnLabel>
    ),
    TotalAmountLabelComponent = (
      props: PurchaseFormProps & BlockComponentProps,
    ) => (
      <PurchaseSummaryColumnLabel {...props}>
        {props.totalAmountLabel || 'Total'}
      </PurchaseSummaryColumnLabel>
    ),
    CardFieldsLabelComponent = (
      props: PurchaseFormProps & BlockComponentProps,
    ) => (
      <SectionLabel {...props}>{props.cardFieldsLabel || 'Card'}</SectionLabel>
    ),
  } = props

  const {selectedTicket} = usePurchase()

  const {setCardElement} = usePurchaseForm()

  const requiresCardInput = selectedTicket ? selectedTicket.price !== 0 : false

  const style: StripeElementStyle = useMemo(
    () => ({
      base: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: `${inputFontSize}px`,
        ':disabled': {
          color: 'rgba(0, 0, 0, 0.36)',
        },
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.36)',
        },
      },
      invalid: {
        color: '#f44336',
      },
    }),
    [inputFontSize],
  )

  const purchaseSummaryClassName = `purchase-summary-${props.id}`

  return (
    <>
      <style>
        {`
      .StripeElement {
        background-color: ${props.input.backgroundColor} !important;
        border: ${props.input.border.width}px solid !important;
        border-color: ${props.input.border.color} !important;
        border-radius: ${props.input.border.radius}px !important;
        padding-left: ${props.input.padding.left}px !important;
        padding-right: ${props.input.padding.right}px !important;
        padding-top: ${props.input.padding.top * 1.5}px !important;
        padding-bottom: ${props.input.padding.bottom * 1.5}px !important;
      }

      .${purchaseSummaryClassName} {
        tr {
          height:40px
        }
      }
        `}
      </style>
      {requiresCardInput && (
        <div>
          <CardFieldsLabelComponent {...props} />
          <CardFields style={style} onLoad={setCardElement} spacing={spacing} />
        </div>
      )}
      <PurchaseSummaryLabelComponent {...props} />
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
        }}
        className={purchaseSummaryClassName}
      >
        <thead>
          <tr
            style={{
              borderBottom: summaryColumnLabel
                ? `${summaryColumnLabel.border.width}px solid ${summaryColumnLabel.border.color}`
                : '1px solid #c7c7c7',
            }}
          >
            <th
              style={{
                textAlign: 'left',
              }}
            >
              {<ItemLabelComponent {...props} />}
            </th>
            <th
              style={{
                textAlign: 'right',
              }}
            >
              {<TotalAmountLabelComponent {...props} />}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: 'left',
              }}
            >
              {selectedTicket?.name}
            </td>

            <td
              style={{
                textAlign: 'right',
              }}
            >
              ${selectedTicket ? formatPrice(selectedTicket.price) : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
