import React from 'react'
import {PurchaseFormBlock} from '.'
import {getFontStyles} from '../../lib/font'

interface SectionLabelProps extends PurchaseFormBlock {
  children: string
}

export default function SectionLabel(props: SectionLabelProps) {
  const {children, sectionLabel} = props
  return (
    <h5
      style={{
        ...getFontStyles(sectionLabel?.font ?? null),
        fontSize: sectionLabel?.fontSize ?? '18px',
        marginTop: `${sectionLabel?.margin.top ?? 16}px`,
        marginRight: `${sectionLabel?.margin.right ?? 0}px`,
        marginBottom: `${sectionLabel?.margin.bottom ?? 16}px`,
        marginLeft: `${sectionLabel?.margin.left ?? 0}px`,
      }}
    >
      {children}
    </h5>
  )
}
