import React from 'react'
import {PurchaseFormBlock} from '.'
import {usePurchase} from '../../PurchaseContext'
import {formatPrice} from '../../lib/currency'
import {replace} from '../../lib/template'

interface ConfirmPurchaseButtonProps extends PurchaseFormBlock {
  disabled?: boolean
  ButtonComponent: React.FC<any>
}

export default function PurchaseButton(props: ConfirmPurchaseButtonProps) {
  const {disabled, ButtonComponent, ...config} = props

  const text = useTextWithTicketVariables(config.purchaseButton.text)

  return (
    <ButtonComponent
      config={config}
      text={text}
      disabled={disabled}
      button={config.purchaseButton}
      aria-label="purchase button"
      type="submit"
    />
  )
}

function useTextWithTicketVariables(text: string) {
  const {selectedTicket} = usePurchase()

  if (!selectedTicket) {
    return text
  }

  const attributes = {
    ticket_name: selectedTicket.name,
    ticket_price: `$${formatPrice(selectedTicket.price)}`,
  }
  let result = text
  // Replace attributes we know about.
  for (const [key, value] of Object.entries(attributes)) {
    result = replace(key, String(value), result)
  }
  return result
}
