import React from 'react'
import {makeStyles, createStyles} from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import {colors} from 'lib/ui/theme'

export default function CustomizedProgressBars(props: {
  value: number
  hidden?: boolean
}) {
  const useStylesLinerProgress = makeStyles(() =>
    createStyles({
      root: {
        height: 15,
      },
      colorPrimary: {
        backgroundColor: colors.sidebar.background,
      },
      bar: {
        backgroundColor: colors.primary,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    }),
  )

  const classes = useStylesLinerProgress()

  if (props.hidden) {
    return null
  }

  return (
    <LinearProgress
      classes={classes}
      variant="determinate"
      value={props.value || 0}
    />
  )
}
