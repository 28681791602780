import React, {useState} from 'react'
import CertificateSelect from 'organization/Event/Certificates/CertificateSelect'
import {TargetInputProps} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import styled from 'styled-components'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
  DEFAULT_SNACKBAR_SUCCESS_BACKGROUND_COLOR,
  DEFAULT_SNACKBAR_SUCCESS_COLOR,
} from 'lib/ui/SnackbarProvider'
import TextArea from 'lib/ui/TextField/TextArea'
import InputLabel from 'lib/ui/TextField/InputLabel'
import CertificateGeneratingConfig from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput/CertificateGeneratingConfig'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {Snackbar} from 'lib/ui/Snackbar'
import {defaultEmailSentText} from 'Event/Dashboard/components/NavButton/CertificateButton'

export default function CertificateConfig(props: TargetInputProps) {
  const {control, watch, button, register} = props

  const [emailSentText, setEmailSentText] = useState<string>(
    button.certificate?.emailSentText ?? defaultEmailSentText,
  )

  const emailSentBackgroundColor =
    watch('certificate.emailSentBackgroundColor') ??
    button.certificate?.emailSentBackgroundColor ??
    DEFAULT_SNACKBAR_SUCCESS_BACKGROUND_COLOR
  const emailSentTextColor =
    watch('certificate.emailSentTextColor') ??
    button.certificate?.emailSentTextColor ??
    DEFAULT_SNACKBAR_SUCCESS_COLOR

  if (!watch('isCertificateButton', button.isCertificateButton)) {
    return null
  }

  return (
    <>
      <Controller
        name="certificateId"
        defaultValue={button.certificateId}
        control={control}
        render={({value, onChange}) => (
          <CertificateSelect value={value} onChange={onChange} />
        )}
      />

      <CollapsibleSection label="Download Behavior">
        <CertificateGeneratingConfig
          control={control}
          button={button}
          register={register}
          watch={watch}
        />

        <Snackbar
          style={{marginBottom: '1.25rem'}}
          message={
            <Container>
              <CheckCircleIcon />
              <PopupText>{emailSentText}</PopupText>
            </Container>
          }
          color={emailSentTextColor}
          backgroundColor={emailSentBackgroundColor}
        />

        <Controller
          name="certificate.emailSentBackgroundColor"
          control={control}
          defaultValue={emailSentBackgroundColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Popup Background Color"
              color={value}
              onPick={onChange}
              aria-label="email sent background color"
            />
          )}
        />
        <Controller
          name="certificate.emailSentTextColor"
          control={control}
          defaultValue={emailSentTextColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Popup Color"
              color={value}
              onPick={onChange}
              aria-label="email sent text color"
            />
          )}
        />
        <TextAreaHolder>
          <InputLabel>Popup Text</InputLabel>
          <TextArea
            value={emailSentText}
            name="certificate.emailSentText"
            label="Popup Text"
            onChange={setEmailSentText}
            fullWidth
            rows={3}
            inputProps={{
              'aria-label': 'email sent text',
              ref: register({required: 'Email sent text is required'}),
            }}
          />
        </TextAreaHolder>
      </CollapsibleSection>
    </>
  )
}

const Container = styled.div`
  display: flex;
`
const PopupText = styled.div`
  font-size: 1.125rem;
`

const TextAreaHolder = styled.div`
  padding-bottom: ${(props) => props.theme.spacing[5]};
`
