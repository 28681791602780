import {BlockComponentProps} from '../'
import {PurchaseFormBlock} from '.'
import {getFontStyles} from '../../lib/font'
import React from 'react'

interface PurchaseFormTitleProps
  extends PurchaseFormBlock,
    BlockComponentProps {}

export default function PurchaseFormTitle(props: PurchaseFormTitleProps) {
  const {title} = props

  if (!title?.enabled) {
    return null
  }

  return (
    <h5
      style={{
        ...getFontStyles(title?.font ?? null),
        fontSize: title?.fontSize ?? '36px',
        marginTop: `${title?.margin.top ?? 0}px`,
        marginRight: `${title?.margin.right ?? 0}px`,
        marginBottom: `${title?.margin.bottom ?? 16}px`,
        marginLeft: `${title?.margin.left ?? 0}px`,
      }}
    >
      {title?.text ?? 'Your Order'}
    </h5>
  )
}
