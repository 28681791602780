import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import {createCountdown} from 'Event/Dashboard/components/CountDownTimer'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {v4 as uuid} from 'uuid'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function NewCountDownTimerButton(props: {
  sectionId: string
  className?: string
}) {
  const save = useSaveTemplate()

  const id = uuid()

  const {countDownTimers} = useTownhallTemplate()

  const numCountdowns = Object.keys(countDownTimers).length

  const addCountdownTimer = () => {
    save({
      dashboardSections: {
        [props.sectionId]: {
          items: {
            [id]: createCountdown(numCountdowns + 1),
          },
        },
      },
    })
  }

  return (
    <>
      <Grid item xs={12} className={props.className}>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          color="primary"
          aria-label="add countdown timer"
          onClick={addCountdownTimer}
        >
          Add Countdown Timer
        </Button>
      </Grid>
    </>
  )
}
