import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {UseFormMethods} from 'react-hook-form'
import {QuesionForm} from 'organization/Obie/Blocks/ProcessForm'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiTextField from '@material-ui/core/TextField'
import {QuestionOption} from 'organization/Obie/ObieServiceProvider'

export default function RadioOptions(
  props: QuesionForm & {
    control: UseFormMethods['control']
    watch: UseFormMethods['watch']
    setValue: UseFormMethods['setValue']
    getValues: UseFormMethods['getValues']
    register: UseFormMethods['register']
  },
) {
  const {register, setValue} = props

  const [showingOtherOption, setShowingOtherOption] = useState(false)

  const [answer, setAnswer] = useState(props.answer)
  const [question, setQuestion] = useState(props.question)

  useEffect(() => {
    setAnswer(props.answer)
    setQuestion(props.question)
  }, [props.answer, props.question, setAnswer, setQuestion])

  const [defaultSelectedOption, setDefaultSelectedOption] = useState<
    QuestionOption | undefined
  >()

  const options = question.options.map((opt, index) => (
    <FormControlLabel
      key={index}
      color="primary"
      value={opt.value}
      control={<Radio />}
      label={opt.value}
    />
  ))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const selectedOption = question.options.find((opt) => opt.value === value)

    if (selectedOption?.is_other) {
      setValue('answer', '')
      setShowingOtherOption(true)
      return
    }
    setShowingOtherOption(false)
    setValue('answer', value)
  }

  const onChangeText = (newValue: string) => {
    if (!showingOtherOption) {
      return
    }
    setValue('answer', newValue)
  }

  // Init states when rendering
  useEffect(() => {
    const selectedOption = question.options.find(
      (opt) => opt.value === answer?.answer,
    )

    const otherOption = question.options.find((opt) => opt.is_other)

    if (!selectedOption) {
      if (!answer) {
        setDefaultSelectedOption(undefined)
      } else if (otherOption) {
        setDefaultSelectedOption(otherOption)
      } else {
        setDefaultSelectedOption(undefined)
      }
    } else {
      setDefaultSelectedOption(selectedOption)
    }

    register('answer')
    setValue('answer', answer?.answer || '', {shouldDirty: true})
  }, [setValue, register, setShowingOtherOption, question, answer])

  useEffect(() => {
    if (!defaultSelectedOption) {
      setShowingOtherOption(false)
      return
    }

    if (defaultSelectedOption.is_other) {
      setShowingOtherOption(true)
      return
    }
    setShowingOtherOption(false)
  }, [defaultSelectedOption, setShowingOtherOption])

  return (
    <>
      <StyledFormControl component="fieldset">
        <RadioGroup
          aria-label="question-options"
          name={`question-${question.id}-options`}
          defaultValue={defaultSelectedOption?.value || ''}
          onChange={handleChange}
        >
          {options}
        </RadioGroup>
      </StyledFormControl>
      <OtherOptionTextField
        {...props}
        showing={showingOtherOption}
        onChange={onChangeText}
      />
    </>
  )
}

function OtherOptionTextField(
  props: QuesionForm & {
    control: UseFormMethods['control']
    watch: UseFormMethods['watch']
    getValues: UseFormMethods['getValues']
    showing: boolean
    onChange: (newValue: string) => void
  },
) {
  const {question, getValues} = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value)
  }

  if (!props.showing) {
    return null
  }

  return (
    <StyledTextField
      fullWidth
      multiline
      rows={5}
      variant="filled"
      placeholder={question.example_text}
      defaultValue={getValues('answer')}
      onChange={handleChange}
    />
  )
}

const StyledFormControl = styled((props) => {
  return <FormControl {...props} />
})`
  color: white;
  & .MuiIconButton-label {
    color: white;
  }
`
const StyledTextField = styled(MuiTextField)`
  & .MuiFilledInput-input {
    color: white;
  }
`
