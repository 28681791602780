import FormHelperText from '@material-ui/core/FormHelperText'
import {useAttendeeVariables} from 'Event'
import Checkbox, {CHECKBOX} from 'Event/Question/Checkbox'
import LongAnswerText, {LONG_ANSWER_TEXT} from 'Event/Question/LongAnswerText'
import Currency, {CURRENCY} from 'Event/Question/Currency'
import Radio, {RADIO} from 'Event/Question/Radio'
import Select, {SELECT} from 'Event/Question/Select'
import SelectPriority, {SELECT_PRIORITY} from 'Event/Question/SelectPriority'
import ShortAnswerText, {
  SHORT_ANSWER_TEXT,
} from 'Event/Question/ShortAnswerText'
import {Answer, findAnswer} from 'Event/SubmissionsProvider'
import {ValidationError} from 'lib/ui/api-client'
import {fieldError} from 'lib/form'
import {Question as QuestionDefinition} from 'organization/Event/QuestionsProvider'
import React, {useEffect} from 'react'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import {rgba} from 'lib/color'
import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import InputLabel, {InputLabelProps} from '@material-ui/core/InputLabel'

export interface InputStyles {
  labelColor?: string
  borderColor?: string
  backgroundColor?: string
  backgroundOpacity?: number
  textColor?: string
  helperTextColor?: string
}

export interface QuestionProps {
  index: number
  question: QuestionDefinition
  register?: UseFormMethods['register']
  control?: UseFormMethods['control']
  formErrors?: UseFormMethods['errors']
  setValue?: UseFormMethods['setValue']
  responseError?: ValidationError<any>
  answers?: Answer[]
  disabled?: boolean
  inputStyles?: InputStyles
  watch: UseFormMethods['watch']
}

export type FieldProps = QuestionProps & {
  name: string
  error?: string
  hasError: boolean
  HelperText: React.ReactElement
  answer: string | undefined
}

export const typesWithOptions: QuestionDefinition['type'][] = [
  'checkbox',
  'radio',
  'select',
  'select_priority',
]

export function Question(props: QuestionProps) {
  const name = `answers[${props.index}].question_id`
  const errorKey = `answers[${props.index}].value`
  const error = fieldError(errorKey, {
    form: props.formErrors || {},
    response: props.responseError || null,
  })
  const v = useAttendeeVariables()
  const errorWithVariables = v(error)

  const hasError = Boolean(error)
  const {inputStyles: questionFormStyle} = props

  const HelperText = (
    <StyledFormHelperText
      error={hasError}
      color={questionFormStyle?.helperTextColor}
    >
      {v(error || props.question.helper_text || '')}
    </StyledFormHelperText>
  )

  const answer = findAnswer(props.question, props.answers)

  return (
    <>
      <input
        type="hidden"
        name={name}
        value={props.question.id}
        ref={props.register}
      />
      <Field
        {...props}
        name={`answers[${props.index}].value`}
        error={errorWithVariables}
        hasError={hasError}
        HelperText={HelperText}
        answer={answer}
        disabled={props.disabled}
      />
    </>
  )
}

function Field(props: FieldProps) {
  const {question} = props
  const {type} = question

  switch (question.type) {
    case SHORT_ANSWER_TEXT:
      return <ShortAnswerText {...props} />
    case LONG_ANSWER_TEXT:
      return <LongAnswerText {...props} />
    case RADIO:
      return <Radio {...props} question={question} />
    case SELECT:
      return <Select {...props} question={question} />
    case CHECKBOX:
      return <Checkbox {...props} question={question} />
    case CURRENCY:
      return <Currency {...props} question={question} />
    case SELECT_PRIORITY:
      return <SelectPriority {...props} question={question} />
    default:
      throw new Error(`Missing field component for question type: ${type}`)
  }
}

export function useSavedValue(props: {
  setValue?: UseFormMethods['setValue']
  name: string
  answer: string | undefined
}) {
  const {setValue, answer, name} = props
  useEffect(() => {
    if (!setValue || !answer) {
      return
    }

    setValue(name, answer)
  }, [setValue, answer, name])
}

const StyledFormHelperText = styled((props) => {
  const {color: _, ...otherProps} = props
  return <FormHelperText {...otherProps} />
})`
  color: ${(props) => props.color} !important;
`

export function FormTextField(
  props: TextFieldProps & {
    styles?: InputStyles
    startAdornment?: JSX.Element
  },
) {
  const {startAdornment, styles, label, ...textFieldProps} = props

  const useStyles = makeStyles({
    root: {
      backgroundColor: rgba(
        styles?.backgroundColor || '#ffffff',
        (styles?.backgroundOpacity || 0) / 100,
      ),
      color: styles?.textColor,
    },
    labelRoot: {
      color: styles?.labelColor,
      zIndex: 2, // prevent background from hiding label
      '&$labelFocused': {
        color: styles?.labelColor,
      },
    },
    underline: {
      borderBottom: `1px solid ${styles?.borderColor}`,
      '&::after': {
        borderBottom: `1px solid ${styles?.borderColor}`,
      },
    },
    helper: {
      color: styles?.helperTextColor,
    },
    labelFocused: {},
  })

  const classes = useStyles()

  return (
    <>
      <Label
        classes={{
          root: classes.labelRoot,
        }}
        showing={!props.hiddenLabel}
      >
        {label}
      </Label>
      <StyledTextField
        InputProps={{
          classes: {
            root: classes.root,
            underline: classes.underline,
          },
          startAdornment: startAdornment,
        }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused,
          },
        }}
        FormHelperTextProps={{
          className: classes.helper,
        }}
        {...textFieldProps}
      />
    </>
  )
}

function Label(
  props: {showing?: boolean; children: React.ReactNode} & InputLabelProps,
) {
  const {children, showing = true, ...otherProps} = props

  if (!showing) {
    return null
  }

  return <StyledInputLabel {...otherProps}>{children}</StyledInputLabel>
}

const StyledInputLabel = styled(InputLabel)`
  font-family: inherit;
`

const StyledTextField = styled(TextField)`
  & > * {
    font-family: inherit;
  }
`
