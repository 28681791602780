import React, {useEffect, useState} from 'react'
import {
  Question,
  Answer,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {UseFormMethods, useForm} from 'react-hook-form'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import LongForm from 'organization/Obie/Blocks/ProcessForm/Form/LongForm'
import RadioOptions from 'organization/Obie/Blocks/ProcessForm/Form/RadioOptions'
import PathQuestion from 'organization/Obie/Blocks/ProcessForm/Form/PathQuestion'
import HelperText from 'lib/ui/TextField/HelperText'
import {
  TYPE_LONG_FORM,
  TYPE_RADIO_OPTIONS,
  TYPE_PATH_PARENT,
  TYPE_PATH_QUESTION,
  TYPE_MULTIPLE_CHOICE,
} from 'organization/Obie/Blocks/ProcessForm/question'

import {QuesionForm} from 'organization/Obie/Blocks/ProcessForm'
import {useHistory} from 'react-router'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'
import CheckboxesGroup from './CheckboxesGroup'
import {browserTimezone} from 'lib/date-time'

export default function Form(props: QuesionForm) {
  const {question, answer_set_id, answer} = props

  const history = useHistory()

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
  ] = useState(false)

  const {fetchBlocks} = useObieService()

  const [redirectPath, setRedirectPath] = useState<string | null>(null)

  const {
    register,
    unregister,
    control,
    formState: {isDirty},
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm()

  const submitAnswer = useSubmitAnswer(props.block.id, answer)

  const goToNext = (newAnswer?: Answer | {answer: Answer}) => {
    reset()
    unregister('answer')

    // Since the response types from put, post requests are not same, they are hardocded here.
    if (!newAnswer) {
      props.goToNext(answer)
      return
    }
    if (
      typeof newAnswer?.answer === 'string' ||
      Array.isArray(newAnswer.answer)
    ) {
      props.goToNext(newAnswer as Answer)
      return
    }
    props.goToNext(newAnswer?.answer as Answer)
  }

  const goToPrevious = () => {
    reset()
    unregister('answer')
    props.goToPrevious()
  }

  const submit = (formData: Pick<Answer, 'answer'>) => {
    setSubmitting(true)
    submitAnswer(formData.answer, question.id, answer_set_id)
      .then((res) => {
        setSubmitting(false)
        if (redirectPath) {
          setShowingUnsavedChangesDialog(false)
          fetchBlocks()
          history.block(() => true)
          history.push(redirectPath)
        } else {
          goToNext(res)
        }
      })
      .catch((err) => {
        setSubmitting(false)
        setError(err.message)
      })
  }

  usePreventNavigation(
    isDirty,
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
    setRedirectPath,
  )

  const clickOnCancel = () => {
    setShowingUnsavedChangesDialog(false)
    setRedirectPath(null)
  }

  const clickOnDiscard = () => {
    setShowingUnsavedChangesDialog(false)
    if (redirectPath) {
      history.block(() => true)
      history.push(redirectPath)
    }
  }

  return (
    <>
      <ConfirmUnsavedChangesDialog
        onDiscard={clickOnDiscard}
        onCancel={clickOnCancel}
        open={showingUnsavedChangesDialog}
        onSubmit={handleSubmit(submit)}
      />
      <form onSubmit={handleSubmit(submit)}>
        <FormContent
          {...props}
          register={register}
          control={control}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
        />
        <HelperText error={Boolean(error)}>
          {error || question.helper_text}
        </HelperText>
        <ButtonBox>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={goToPrevious}
          >
            Back
          </Button>
          <SubmitButton
            isDirty={isDirty}
            isSubmitting={submitting}
            onClick={goToNext}
            question={question}
            answer={answer}
          />
        </ButtonBox>
      </form>
    </>
  )
}

function FormContent(
  props: QuesionForm & {
    register: UseFormMethods['register']
    control: UseFormMethods['control']
    watch: UseFormMethods['watch']
    setValue: UseFormMethods['setValue']
    getValues: UseFormMethods['getValues']
  },
) {
  const {question} = props

  switch (question.type) {
    case TYPE_LONG_FORM:
      return <LongForm {...props} />
    case TYPE_RADIO_OPTIONS:
      return <RadioOptions {...props} />
    case TYPE_PATH_PARENT:
      return <RadioOptions {...props} />
    case TYPE_PATH_QUESTION:
      return <PathQuestion {...props} />
    case TYPE_MULTIPLE_CHOICE:
      return <CheckboxesGroup {...props} />
    default:
      break
  }
  return <LongForm {...props} />
}

function SubmitButton(props: {
  isDirty: boolean
  isSubmitting: boolean
  onClick: () => void
  question: Question
  answer?: Answer
}) {
  const {answer, isDirty, isSubmitting, onClick} = props

  if (isDirty) {
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Next
      </Button>
    )
  }

  const disabled = props.question.required && !Boolean(answer)

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={() => onClick()}
      disabled={disabled}
    >
      Next
    </Button>
  )
}

const ButtonBox = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing[2]};
  gap: ${(props) => props.theme.spacing[2]};
`

function useSubmitAnswer(block_id: number, answer?: Answer) {
  const {client, organization} = useOrganization()

  const baseUrl = api(
    `/organizations/${organization.id}/obie/blocks/${block_id}/answers`,
  )

  const url = answer ? `${baseUrl}/${answer.id}` : baseUrl

  const submit = (
    value: string | string[],
    question_id: number,
    answer_set_id?: number,
  ) => {
    const data = {
      answer_set_id: answer_set_id,
      answer: {
        question_id: question_id,
        answer: value,
      },
      browserTimezone: browserTimezone(),
    }
    return answer
      ? client.put<Answer>(url, data)
      : client.post<{answer: Answer}>(url, data)
  }

  return submit
}

function usePreventNavigation(
  isDirty: boolean,
  showingUnsavedChangesDialog: boolean,
  setShowingUnsavedChangesDialog: (flag: boolean) => void,
  setRedirectPath: (newLocation: string) => void,
) {
  const history = useHistory()

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
      }
    }

    const handleBlockNavigation = (blocker: any) => {
      if (!isDirty) {
        return true
      }

      if (!showingUnsavedChangesDialog) {
        setShowingUnsavedChangesDialog(true)
        setRedirectPath(blocker.pathname)
      }
      return false
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    const unblock = history.block(handleBlockNavigation)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      unblock()
    }
  }, [
    isDirty,
    history,
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
    setRedirectPath,
  ])
}
