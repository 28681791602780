import {flatten} from 'lib/object'
import getAtPath from 'lodash/get'
import {DeepPartialSubstitute} from 'lib/type-utils'
import React, {useState, useCallback, useEffect, useMemo} from 'react'
import setAtPath from 'lodash/set'
import unsetAtPath from 'lodash/unset'
import {useEventSocket} from 'organization/Event/EventSocketProvider'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {setIsConnected} from 'Event/Dashboard/editor/state/actions'
import {clone} from 'ramda'

// A dictionary of the updated keys, and their
// respective values.
export type KeyPaths = Record<string, any>

// When we want the server to remove a key, we'll set it to a
// specific remove value.
export const REMOVE = '__REMOVE__'

export type JsonSave<T> = (
  updates: DeepPartialSubstitute<T, typeof REMOVE>,
) => void

type Updatable = {
  updated_at: string
}

type SocketUpdate = Updatable & {
  data: KeyPaths
}

export default function JsonUpdateProvider<
  T extends Object,
  K extends Updatable
>(props: {
  Context: React.Context<JsonSave<any> | undefined>
  updatedSocketEventName: string
  children: (updated: T) => React.ReactElement
  value: T
  save: (keyPaths: Record<string, any>) => Promise<K>
  shouldSet?: (prev: T, next: T) => boolean
  onUpdate?: (updated: T) => void
}) {
  const {
    value: saved,
    updatedSocketEventName,
    Context,
    save,
    shouldSet,
    onUpdate,
  } = props
  const [current, setCurrent] = useState(saved)

  const {channel} = useEventSocket()
  const dispatch = useDispatch()

  // Every time we receive an updated 'saved' value, we need to check if the entire JSON
  // was updated, such as when changing templates, if so, we'll want to replace the
  // entire 'current' state rather than only applying partial updates.
  useEffect(() => {
    if (shouldSet && shouldSet(current, saved)) {
      setCurrent(saved)
    }
  }, [shouldSet, saved, current])

  // Updates are a dictionary of all successful property updates,
  // and their respective timestamps.
  const updates: Record<string, string> = useMemo(() => ({}), [])

  // Filter update data to only return keys that are NEWER than any updates
  // we've alredy saved. This prevents any flickering we may see if our
  // save went through, but an older update comes through after.
  const onlyNewUpdates = useCallback(
    (update: SocketUpdate) =>
      Object.entries(update.data).reduce((acc, [key, val]) => {
        const lastSaved = updates[key]
        if (!lastSaved) {
          acc[key] = val
          return acc
        }

        const lastSavedTime = moment(lastSaved)
        const updateTime = moment(update.updated_at)
        const isNewer = updateTime.isAfter(lastSavedTime)

        // We already have a save that should be newer than this, so
        // let's ignore it.
        if (!isNewer) {
          return acc
        }

        acc[key] = val
        return acc
      }, {} as KeyPaths),
    [updates],
  )

  // Since the last write wins, we'll record the timestamp on any successful save.
  // This way if any updates via Pusher come in for previous saves, we can
  // safely ignore, knowing our saved value will eventually arrive.
  const recordUpdates = useCallback(
    (lastSaved: string, keyPaths: KeyPaths) => {
      for (const key of Object.keys(keyPaths)) {
        updates[key] = lastSaved
      }
    },
    [updates],
  )

  // Set the values in the template for the given keypaths.
  const set = useCallback(
    (keyPaths: KeyPaths) => {
      setCurrent((current) => {
        // We're using lodash's _.set() func here which unfortunately is a
        // mutating function, so we'll need to clone the template before
        // modifying it to avoid potential shared state bugs. Note
        // we're using Ramda's clone, because Lodash's had a
        // weird bug that wouldn't clone an empty object.
        const updated = clone(current)

        for (const [keyPath, val] of Object.entries(keyPaths)) {
          if (val === REMOVE) {
            unsetAtPath(updated, keyPath)
            continue
          }
          setAtPathWithObjectCasting(updated, keyPath, val)
        }

        onUpdate?.(updated)
        return updated
      })
    },
    [onUpdate],
  )

  // Update the template, this is where the type-safety checks happen, so this
  // should be the only exposed method to other parts of the app.
  const update: JsonSave<typeof current> = useCallback(
    (updates) => {
      const keyPaths = flatten(updates)
      set(keyPaths)
      save(keyPaths)
        .then(({updated_at}) => recordUpdates(updated_at, keyPaths))
        .catch(() => {
          // Save failed for some reason, let's force a user refresh
          dispatch(setIsConnected(false))
        })
    },
    [dispatch, recordUpdates, set, save],
  )

  // Handle live updates from server
  useEffect(() => {
    channel?.listen(updatedSocketEventName, (data: SocketUpdate) => {
      const newUpdates = onlyNewUpdates(data)
      recordUpdates(data.updated_at, newUpdates)
      set(newUpdates)
    })

    return () => {
      channel?.stopListening(updatedSocketEventName)
    }
  }, [channel, onlyNewUpdates, recordUpdates, set, updatedSocketEventName])

  /**
   * Sets a value on an object at the given key path. This is an extension that
   * will automatically convert an [] into a {} if you are trying to set a
   * key that is a string.
   *
   * Fixes a bug where the server may cast objects, such as `speakers.items` from {} -> [].
   * When we call `setAtPath` on an array with a string it attempts to use it as an
   * array index which results in weird downstream.
   */
  function setAtPathWithObjectCasting<T extends Record<string, any>>(
    object: T,
    path: string,
    value: any,
  ): T {
    // keypaths are in the format `speakers.items.{uuid}.name`
    const parts = path.split('.')
    const key = parts[parts.length - 2] // {uuid}

    // If the last key is a number, we'll assume no casting is required as
    // we're only worried about setting a string on an [].
    const isNumberKey = Number.isInteger(Number(key))
    if (isNumberKey) {
      setAtPath(object, path, value)
      return object
    }

    const isList = parts.length > 2 // at least 3 parts
    if (!isList) {
      setAtPath(object, path, value)
      return object
    }

    // Finally, we want to check if we're setting a string on an Array[] here,
    // and if we are, let's convert it into an object{} before we do.
    const listKey = parts.filter((_p, i) => i < parts.length - 2).join('.')
    const listVal = getAtPath(object, listKey)
    if (Array.isArray(listVal) && listVal.length === 0) {
      setAtPath(object, listKey, {})
    }

    setAtPath(object, path, value)
    return object
  }

  return (
    <Context.Provider value={update}>
      {props.children(current)}
    </Context.Provider>
  )
}
