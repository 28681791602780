import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import MuiRadio from '@material-ui/core/Radio'
import {
  FieldProps,
  useSavedValue,
  FormTextField,
  InputStyles,
} from 'Event/Question'
import React from 'react'
import {onChangeStringHandler} from 'lib/dom'
import {useAttendeeVariables} from 'Event'
import styled from 'styled-components'
import {
  HasOptions,
  Question,
  QuestionBase,
} from 'organization/Event/QuestionsProvider'
import {Controller} from 'react-hook-form'

export const RADIO = 'radio'

export type RadioQuestion = QuestionBase &
  HasOptions & {
    type: typeof RADIO
    has_other_option: boolean
  }

export type RadioProps = FieldProps & {
  question: RadioQuestion
}

/**
 * 'other' value. We set a default here that shouldn't conflict with
 * any user values.
 */
export const OTHER = '__other__'

export default function Radio(props: RadioProps) {
  const {control, watch, answer} = props
  useSavedValue(props)
  const v = useAttendeeVariables()

  const value = watch(props.name) || answer

  const otherInputVisible =
    Boolean(value) &&
    !props.question.options.map(({value}) => value).includes(value)

  return (
    <FormControl
      error={props.hasError}
      component="fieldset"
      fullWidth
      required={props.question.is_required}
      disabled={props.disabled}
    >
      <StyledFormLabel component="legend" color={props.inputStyles?.labelColor}>
        {v(props.question.label)}
      </StyledFormLabel>

      <Controller
        name={props.name}
        defaultValue={answer || ''}
        control={control}
        rules={{
          required: props.question.is_required && 'This is required',
        }}
        render={({value, onChange}) => (
          <RadioInput
            otherInputVisible={otherInputVisible}
            otherOptionVisible={otherInputVisible}
            question={props.question}
            inputStyles={props.inputStyles}
            value={value}
            onChange={onChange}
          />
        )}
      />

      {props.HelperText}
    </FormControl>
  )
}

export function RadioInput(props: {
  question: RadioQuestion
  inputStyles?: InputStyles
  otherOptionVisible: boolean
  otherInputVisible: boolean
  value: string
  onChange: (value: string) => void
}) {
  const v = useAttendeeVariables()

  const {
    question,
    inputStyles,
    otherInputVisible,
    otherOptionVisible,
    value,
    onChange,
  } = props

  return (
    <RadioGroup
      aria-label="question"
      onChange={onChangeStringHandler(onChange)}
      value={value}
    >
      {question.options.map((option, index) => (
        <StyledFormControlLabel
          key={index}
          value={option.value}
          control={<MuiRadio />}
          label={v(option.value)}
          color={inputStyles?.textColor}
        />
      ))}
      <OtherOption
        isVisible={question.has_other_option}
        hasOtherValue={otherOptionVisible}
        inputStyles={inputStyles}
      />
      <OtherInput
        isVisible={otherInputVisible}
        onChange={onChange}
        value={value}
        inputStyles={inputStyles}
      />
    </RadioGroup>
  )
}

function OtherOption(props: {
  isVisible: boolean
  hasOtherValue: boolean
  inputStyles?: InputStyles
}) {
  if (!props.isVisible) {
    return null
  }

  return (
    <>
      <StyledFormControlLabel
        value={OTHER}
        control={<MuiRadio checked={props.hasOtherValue} />}
        label="Other"
        color={props.inputStyles?.textColor}
      />
    </>
  )
}

function OtherInput(props: {
  inputStyles?: InputStyles
  isVisible: boolean
  value: string
  onChange: (value: string) => void
}) {
  if (!props.isVisible) {
    return null
  }

  /**
   * Text input value. We need to check for __other__, otherwise that's the
   * text that will be visible in the input.
   */
  const value = props.value === OTHER ? '' : props.value

  const handleOnChange = (text: string) => {
    /**
     * Setting value also needs to handle blank '' text case to avoid
     * de-selecting the 'other' option.
     */
    const value = text || OTHER
    props.onChange(value)
  }

  return (
    <FormTextField
      inputProps={{
        'aria-label': 'other value',
      }}
      hiddenLabel
      value={value}
      onChange={onChangeStringHandler(handleOnChange)}
      styles={props.inputStyles}
    />
  )
}

export function isRadioQuestion(
  question?: Question,
): question is RadioQuestion {
  if (!question) {
    return false
  }

  return question.type === RADIO
}

const StyledFormLabel = styled((props) => {
  const {color: _, ...otherProps} = props

  return <FormLabel {...otherProps} />
})`
  color: ${(props) => (props.color ? `${props.color} !important;` : '')};
  font-family: inherit;
`
const StyledFormControlLabel = styled((props) => {
  const {color: _, ...otherProps} = props

  return <FormControlLabel {...otherProps} />
})`
  color: ${(props) => (props.color ? `${props.color} !important;` : '')};
  & > * {
    font-family: inherit;
  }
`
