import React from 'react'
import styled from 'styled-components'
import {useObieService, Block} from 'organization/Obie/ObieServiceProvider'
import {useParams} from 'react-router-dom'
import BlockListItem from 'organization/Obie/Blocks/BlockListItem'

export default function BlockList() {
  const {category: categoryId} = useParams<{category: string}>()
  const {blocks: allBlocks, categories} = useObieService()

  const category = categories.find(
    (category) => category.id === parseInt(categoryId),
  )
  const blocks = allBlocks.filter(
    (block) => block.category.id === parseInt(categoryId),
  )

  if (!category) {
    return null
  }

  return (
    <Container>
      <TitleBox>
        <Title>{category.name}</Title>
      </TitleBox>
      {blocks.map((block: Block, index: number) => (
        <BlockListItem key={index} block={block} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 10px;
`

const TitleBox = styled.div`
  margin: 60px 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 20px 0;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
`
