import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@obvio/ckeditor'
import {
  TextEditorProps,
  DarkCKEditor,
  LightCKEditor,
} from 'lib/ui/form/TextEditor'
import styled from 'styled-components'
import {Theme} from 'lib/ui/form/TextEditor/TextEditorProvider'

export default function MarkdownEditor(
  props: Omit<TextEditorProps, 'onChange'> & {
    theme?: Theme
    onChange?: (value: string) => void
  },
) {
  const updateValue = (_: any, editor: any) => {
    if (!props.onChange) {
      return
    }

    props.onChange(editor.getData())
  }
  const theme = props.theme || 'Dark'
  const disabled = props.disabled || props.onChange === undefined

  return (
    <Container className={props.className}>
      <CKEditor
        disabled={disabled}
        editor={ClassicEditor}
        // In case DB returns 'null' for a text field, we don't
        // want to crash the app so let's just set is as a
        // blank string as a precaution.
        data={props.data || ''}
        onChange={updateValue}
        config={
          {
            toolbar: {
              items: [],
            },
            balloonToolbar: [
              'heading',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
            ],
            heading: {
              options: [
                {
                  model: 'paragraph',
                  title: 'Paragraph',
                  class: 'ck-heading_paragraph',
                },
                {
                  model: 'heading1',
                  view: 'h1',
                  title: 'Heading 1',
                  class: 'ck-heading_heading1',
                },
                {
                  model: 'heading2',
                  view: 'h2',
                  title: 'Heading 2',
                  class: 'ck-heading_heading2',
                },
                {
                  model: 'heading3',
                  view: 'h3',
                  title: 'Heading 3',
                  class: 'ck-heading_heading3',
                },
                {
                  model: 'heading4',
                  view: 'h4',
                  title: 'Heading 4',
                  class: 'ck-heading_heading4',
                },
                {
                  model: 'heading5',
                  view: 'h5',
                  title: 'Heading 5',
                  class: 'ck-heading_heading5',
                },
                {
                  model: 'heading6',
                  view: 'h6',
                  title: 'Heading 6',
                  class: 'ck-heading_heading6',
                },
              ],
            },
          } as any
        }
      />
      <CustomThemeCkditor theme={theme} />
    </Container>
  )
}

function CustomThemeCkditor(props: {theme: Theme}) {
  // If a user has explicitly selected a text editor theme, we'll always
  // prefer that...
  if (props.theme === 'Dark') {
    return <DarkCKEditor backgroundColor="#131d34" />
  } else {
    return <LightCKEditor />
  }
}

/**
 * Fix CKEditor scroll when within a dialog. CKEditor has a min-width, and
 * anything below will show blank whitespace with scroll. This container
 * will fix alignment, and hide whitespace.
 */

export const Container = styled.div`
  .ck.ck-toolbar {
    border: 0;
  }
`
