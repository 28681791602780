import React, {useCallback, useState, useEffect} from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {now, date, duration, inThreeDays, Duration} from 'lib/date-time'
import {rgba} from 'lib/color'
import {useInterval} from 'lib/interval'
import {HashMap, Ordered} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {withDefaults} from 'lib/object'
import {DeepRequired} from 'lib/type-utils'
import {useAttendeeVariables} from 'Event'
import {Font} from 'lib/FontSelect'

export interface CountDownTimerSettings extends Ordered {
  enabled: boolean
  end: string
  backgroundColor: string
  textColor: string
  backgroundOpacity: number
  description: string
  numberColor: string
  numberBackgroundColor: string
  numberBackgroundOpacity: number
  numberBackgroundRadius: number
  numberBackgroundWidth: number
  numberBackgroundHeight: number
  separator: string
  labels: Duration
  font: Font | null
  fontSize: number
}

export const createCountdown = (
  numCountdowns: number,
): CountDownTimerSettings => ({
  enabled: true,
  description: '',
  end: inThreeDays(),
  backgroundColor: '#FFFFFF',
  backgroundOpacity: 100,
  textColor: '#000000',
  numberColor: '#000000',
  numberBackgroundColor: '#FFFFFF',
  numberBackgroundOpacity: 0,
  numberBackgroundRadius: 0,
  numberBackgroundWidth: 100,
  numberBackgroundHeight: 100,
  separator: ':',
  labels: {
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
  },
  position: numCountdowns,
  font: null,
  fontSize: 16,
})

const countDownTimerDefaults = createCountdown(0)

export function fillCountDownTimerDefaults(
  timers: HashMap<CountDownTimerSettings>,
) {
  return Object.entries(timers).reduce((acc, [id, timer]) => {
    acc[id] = withDefaults(
      countDownTimerDefaults,
      timer,
    ) as DeepRequired<CountDownTimerSettings>
    return acc
  }, {} as DeepRequired<HashMap<CountDownTimerSettings>>)
}

type CountDownTimerProps = CountDownTimerSettings & {
  id: string
  narrow?: boolean
  onRender?: () => void
  onHidden?: () => void
}

export default function CountDownTimer(props: CountDownTimerProps) {
  const [showing, setShowing] = useState(true)
  const [timeLeft, setTimeLeft] = useState<Duration>({
    hours: '00',
    minutes: '00',
    seconds: '00',
  })
  const isEditMode = useEditMode()
  const v = useAttendeeVariables()

  const {
    textColor,
    backgroundColor,
    backgroundOpacity: opacity,
    end,
    enabled,
    description,
    narrow,
    onRender,
    onHidden,
  } = props

  const background = backgroundColor
    ? rgba(backgroundColor, opacity / 100)
    : '#000000'

  const update = useCallback(() => {
    if (!showing || !enabled || !end) {
      // Nothing to update
      return
    }

    const current = now()
    const isAfter = date(end).isAfter(current)
    const diff = duration(current, end)

    setShowing(isAfter)
    setTimeLeft(diff)
  }, [enabled, end, showing])

  useInterval(update, 1000)

  const checkHidden = () => {
    if (isEditMode) {
      return false
    }

    return !showing || !enabled
  }

  const isHidden = checkHidden()

  /**
   * Notify any parents that we're now rendering content
   * in case they need to re-calculate sizes.
   */
  useEffect(() => {
    if (isHidden) {
      onHidden && onHidden()
      return
    }

    onRender && onRender()
  }, [isHidden, onRender, onHidden])

  if (isHidden) {
    return null
  }

  return (
    <StyledCountDownTimer
      color={textColor}
      background={background}
      narrow={narrow}
      aria-label="count down timer"
    >
      <Body {...props} timeLeft={timeLeft} />
      <Typography>{v(description)}</Typography>
    </StyledCountDownTimer>
  )
}

function Body(
  props: CountDownTimerProps & {
    timeLeft: Duration
  },
) {
  const {
    numberColor,
    numberBackgroundColor,
    numberBackgroundOpacity,
    numberBackgroundRadius,
    separator,
    description,
    id,
    timeLeft,
    narrow,
    labels,
  } = props

  const numberBackground = numberBackgroundColor
    ? rgba(numberBackgroundColor, numberBackgroundOpacity / 100)
    : '#FFFFFF'

  const hasDescription = Boolean(description)

  // Check whether a duration's index is the last unit. Useful to check if
  // we need to render a separator.
  const isLast = (index: number) => index === Object.keys(timeLeft).length - 1

  const v = useAttendeeVariables()

  return (
    <Box bottomSpacing={hasDescription}>
      {Object.entries(timeLeft).map(([key, value], index) => (
        <React.Fragment key={`${id}-${key}`}>
          <StyledContent>
            <StyledCountDownNumber
              narrow={narrow}
              color={numberColor}
              background={numberBackground}
              radius={numberBackgroundRadius}
              width={props.numberBackgroundWidth}
              height={props.numberBackgroundHeight}
            >
              <StyledNumber>{value}</StyledNumber>
              <StyledCountDownNumberLabel>
                {v(labels[key as keyof Duration])}
              </StyledCountDownNumberLabel>
            </StyledCountDownNumber>
            <StyledCountDownSeparator showing={!isLast(index)} panel={narrow}>
              {key === 'days' ? ' ' : separator}
            </StyledCountDownSeparator>
          </StyledContent>
        </React.Fragment>
      ))}
    </Box>
  )
}

const Box = styled.div<{bottomSpacing: boolean}>`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) =>
    props.bottomSpacing ? props.theme.spacing[4] : 0};
`

const StyledCountDownTimer = styled.div<{
  narrow?: boolean
  color: string
  background: string
}>`
  text-align: center;
  padding: ${(props) =>
    props.narrow ? props.theme.spacing[4] : props.theme.spacing[6]};
  font-size: ${(props) => (props.narrow ? 24 : 36)}px;
  font-weight: bold;
  line-height: 1em;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 36px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 36px;
    padding: ${(props) => props.theme.spacing[4]};
  }
`

const StyledCountDownNumber = styled.span<{
  narrow?: boolean
  color: string
  background: string
  radius: number
  width: number
  height: number
}>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  padding: ${(props) =>
    props.narrow ? props.theme.spacing[2] : props.theme.spacing[4]};
  border-radius: ${(props) => props.radius * 100}%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  min-width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => props.theme.spacing[2]};
  }
`

const StyledCountDownNumberLabel = styled.div<{}>`
  width: 100%;
  font-size: 16px;
`

const StyledContent = styled.div`
  display: flex;
`

const StyledNumber = styled.div`
  width: 100%;
`

const StyledCountDownSeparator = styled.span<{
  panel?: boolean
  showing: boolean
}>`
  ${(props) => (props.showing ? 'display: flex;' : 'display: none;')}
  align-items: center;
  min-width: 12px;
  padding: ${(props) =>
    props.panel ? props.theme.spacing[2] : props.theme.spacing[4]};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => props.theme.spacing[2]};
  }
`
