import {Attendee} from 'Event/attendee'
import {useEvent} from 'Event/EventProvider'
import {PaginatedCollection} from 'lib/ui/api-client'
import {useAsync} from 'lib/async'
import {
  api,
  queryParser,
  useQueryParams,
  useSetBulkQueryParam,
  useSetQueryParam,
} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useMemo} from 'react'
import {useCallback, useEffect, useState} from 'react'
import * as Sentry from '@sentry/react'
import {Certificate} from 'lib/event-api/certificates/types'

const DEFAULT_NUM_PER_PAGE = 20

export type Tag = string
export type Group = {
  key: string
  value: string
}

type Filters = {
  activeOnly: boolean | null
  passwordCreated: boolean | null
  waiverSigned: boolean | null
  techCheckCompleted: boolean | null
  checkedIn: boolean | null
  includedTags: Tag[]
  excludedTags: Tag[]
  correspondingGroups: Group[]
  oppositeGroups: Group[]
  filledGroups: Group[]
  blankGroups: Group[]
}

export interface AttendeesContextProps {
  attendees: Attendee[]
  update: (attendee: Attendee) => void
  insert: (attendee: Attendee[] | Attendee) => void
  remove: (attendee: Attendee) => void
  loading: boolean
  groups: string[]
  page: number
  perPage: number
  total: number
  searchTerm: string
  search: (term: string) => void
  query: string
  filters: Filters
  setFilters: (values: Partial<Filters>) => void
  setPage: (index: number) => void
  setPerPage: (count: number) => void
  toggleTechCheckComplete: (attendee: Attendee) => () => void
  error: string | null
  clearError: () => void
  exportAttendees: () => Promise<AttendeeExportResult>
  importAttendees: (file: File) => Promise<AttendeeImportResult>
  clearAttendees: () => Promise<ClearAttendeesResult>
  downloadCertificate: (
    certificate: Certificate,
  ) => Promise<DownloadCertificateResult>
  sendCertificate: (certificate: Certificate) => Promise<SendCertificateResult>
}

export interface AttendeeImportResult {
  message: string
}

export interface AttendeeExportResult {
  message: string
}

export interface ClearAttendeesResult {
  message: string
}

export interface DownloadCertificateResult {
  message: string
}
export interface SendCertificateResult {
  message: string
}

export const AttendeesContext = React.createContext<
  AttendeesContextProps | undefined
>(undefined)

export default function AttendeesProvider(props: {
  children: React.ReactElement
}) {
  const url = useUrlParams()

  const [attendees, setAttendees] = useState<Attendee[]>([])

  // DynamoDB -> RDS sync could error resulting in `null` values here. This is not
  // expected, and we should aim to have 0 of these, but in any case this
  // check is here to make sure users are not affected if it happens.
  const visibleAttendees = attendees.filter((attendee) => !!attendee)

  const [total, setTotal] = useState(0)

  const setQueryParam = useSetQueryParam()
  const setBulkQueryParam = useSetBulkQueryParam()

  const query = buildQuery({
    search: url.search,
    page: url.page,
    perPage: url.per_page,
    includedTags: url.tags_includes,
    excludedTags: url.tags_excludes,
    correspondingGroups: url.group_is,
    oppositeGroups: url.group_is_not,
    filledGroups: url.group_is_filled,
    blankGroups: url.group_is_blank,
    activeOnly: url.is_active,
    checkedIn: url.checked_in_status,
    passwordCreated: url.password_created,
    waiverSigned: url.waiver_signed,
    techCheckCompleted: url.tech_check_completed,
  })

  const setParam = (key: string, options = {resetPage: true}) => (val: any) => {
    if (options.resetPage) {
      setBulkQueryParam({
        [key]: val,
        page: '1',
      })

      return
    }

    setQueryParam(key, val)
  }

  const {data: results, loading} = useFetchAttendees(query)
  const [groups, setGroups] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const MarkTechCheckComplete = useMarkTechCheckComplete()
  const markTechCheckIncomplete = useMarkTechCheckIncomplete()
  const exportAttendees = useExport({query, onError: setError})
  const clearAttendees = useClearAttendees({onError: setError})
  const downloadCertificate = useDownloadCertificate({query, onError: setError})
  const sendCertificate = useSendCertificate({query, onError: setError})

  const insert = (items: Attendee[] | Attendee) => {
    const itemsArray = Array.isArray(items) ? items : [items]
    const updates = itemsArray.filter(isExisting)
    const newAttendees = itemsArray.filter((a) => !isExisting(a))

    const updatedExisting = attendees.map((existing) => {
      const updated = updates.find((a) => a.id === existing.id)
      if (!updated) {
        return existing
      }

      return updated
    })

    const updatedList = [...updatedExisting, ...newAttendees]
    setAttendees(updatedList)
  }

  const importAttendees = useImport({onError: setError})

  const clearError = () => setError(null)

  useEffect(() => {
    if (!results) {
      return
    }

    setAttendees(results.data)
    setTotal(results.total)
  }, [results])

  const addGroups = useCallback(
    (attendee: Attendee) => {
      // In case attendee is `null` due to missing dynamodb data
      if (!attendee) {
        return
      }

      for (const key of Object.keys(attendee.groups)) {
        /**
         * Checking groups as we modify it, so we'll always need the
         * current version. ie. use callback version of setState
         */
        setGroups((groups) => {
          const isNewKey = !groups.includes(key)
          if (!isNewKey) {
            return groups
          }

          return [...groups, key]
        })
      }
    },
    [setGroups],
  )

  useEffect(() => {
    setGroups([])

    for (const attendee of attendees) {
      addGroups(attendee)
    }
  }, [attendees, addGroups])

  useLogMissingAttendees(attendees)

  const update = (target: Attendee) => {
    setAttendees((current) => {
      const updated = current.map((a) => {
        const isTarget = a.id === target.id
        if (isTarget) {
          return target
        }

        return a
      })

      return updated
    })
  }

  const isExisting = (target: Attendee) =>
    !!attendees.find((existing) => target.id === existing.id)

  const remove = (attendee: Attendee) => {
    const updated = attendees.filter((a) => a.id !== attendee.id)
    setAttendees(updated)
  }

  const toggleTechCheckComplete = (attendee: Attendee) => () => {
    const process = attendee.has_completed_tech_check
      ? markTechCheckIncomplete
      : MarkTechCheckComplete

    clearError()

    process(attendee)
      .then(update)
      .catch((e) => setError(e.message))
  }

  const setFilters = (value: Partial<Filters>) => {
    const params = buildFilterParams(value)

    setBulkQueryParam({
      ...params,
      page: '1', // always reset pagination on filter changes
    })
  }

  return (
    <AttendeesContext.Provider
      value={{
        attendees: visibleAttendees,
        update,
        insert,
        remove,
        loading,
        page: url.page,
        perPage: url.per_page,
        total,
        query,
        searchTerm: url.search,
        search: setParam('search'),
        filters: {
          activeOnly: url.is_active,
          passwordCreated: url.password_created,
          waiverSigned: url.waiver_signed,
          techCheckCompleted: url.tech_check_completed,
          checkedIn: url.checked_in_status,
          includedTags: url.tags_includes,
          excludedTags: url.tags_excludes,
          correspondingGroups: url.group_is,
          oppositeGroups: url.group_is_not,
          filledGroups: url.group_is_filled,
          blankGroups: url.group_is_blank,
        },
        setFilters,
        setPage: setParam('page', {resetPage: false}),
        setPerPage: setParam('per_page'),
        groups,
        toggleTechCheckComplete,
        error,
        clearError,
        exportAttendees,
        importAttendees,
        clearAttendees,
        downloadCertificate,
        sendCertificate,
      }}
    >
      {props.children}
    </AttendeesContext.Provider>
  )
}

export function useAttendees() {
  const context = React.useContext(AttendeesContext)
  if (context === undefined) {
    throw new Error('useAttendees must be used within an AttendeeListProvider')
  }

  return context
}

/**
 * Relevant parameters parsed from the URL.
 *
 * @returns
 */
function useUrlParams() {
  const queryParams = useQueryParams()
  return useMemo(() => parseAttendeeUrlParams(queryParams), [queryParams])
}

export function parseAttendeeUrlParams(params: Record<string, any>) {
  const parse = queryParser(params)

  const parseGroups = (key: string): Group[] => {
    const dict = parse.dictionary(key) || {}

    return Object.entries(dict).map(([key, value]) => ({
      key,
      value,
    }))
  }

  const checkedInStatus = parse.string('checked_in_status')

  return {
    search: parse.string('search') || '',
    is_active: parse.boolean('is_active'),
    password_created: parse.boolean('password_created'),
    waiver_signed: parse.boolean('waiver_signed'),
    tech_check_completed: parse.boolean('tech_check_completed'),
    checked_in_status:
      checkedInStatus === null ? null : checkedInStatus === 'checked_in',
    tags_includes: parse.list('tags_includes') || [],
    tags_excludes: parse.list('tags_excludes') || [],
    group_is: parseGroups('group_is'),
    group_is_not: parseGroups('group_is_not'),
    group_is_filled: parseGroups('group_is_filled'),
    group_is_blank: parseGroups('group_is_blank'),
    page: parse.number('page') || 1,
    per_page: parse.number('per_page') || DEFAULT_NUM_PER_PAGE,
  }
}

function useFetchAttendees(query: string) {
  const {client} = useOrganization()
  const {event} = useEvent()

  let baseUrl = api(`/events/${event.id}/attendees`)
  const url = `${baseUrl}?${query}`

  const request = useCallback(
    () => client.get<PaginatedCollection<Attendee>>(url),
    [client, url],
  )
  return useAsync(request)
}

function buildQuery(options: {
  search: string
  page: number
  perPage: number
  includedTags: Tag[]
  excludedTags: Tag[]
  correspondingGroups: Group[]
  oppositeGroups: Group[]
  filledGroups: Group[]
  blankGroups: Group[]
  activeOnly: boolean | null
  checkedIn: boolean | null
  passwordCreated: boolean | null
  waiverSigned: boolean | null
  techCheckCompleted: boolean | null
}) {
  const {
    search,
    page,
    perPage,
    includedTags,
    excludedTags,
    correspondingGroups,
    oppositeGroups,
    filledGroups,
    blankGroups,
    activeOnly,
    checkedIn,
    passwordCreated,
    waiverSigned,
    techCheckCompleted,
  } = options
  const params = new URLSearchParams(`page=${page}&per_page=${perPage}`)

  if (activeOnly !== null) {
    params.append('is_active', `${activeOnly}`)
  }
  if (checkedIn !== null) {
    params.append(
      'checked_in_status',
      checkedIn ? 'checked_in' : 'not_checked_in',
    )
  }
  if (passwordCreated !== null) {
    params.append('password_created', `${passwordCreated}`)
  }
  if (waiverSigned !== null) {
    params.append('waiver_signed', `${waiverSigned}`)
  }
  if (techCheckCompleted !== null) {
    params.append('tech_check_completed', `${techCheckCompleted}`)
  }
  if (includedTags.length > 0) {
    params.append('tags_includes', includedTags.join(','))
  }
  if (excludedTags.length > 0) {
    params.append('tags_excludes', excludedTags.join(','))
  }
  if (correspondingGroups.length > 0) {
    params.append(
      'group_is',
      correspondingGroups
        .map((group) => `${group.key}:${group.value}`)
        .join(','),
    )
  }
  if (oppositeGroups.length > 0) {
    params.append(
      'group_is_not',
      oppositeGroups.map((group) => `${group.key}:${group.value}`).join(','),
    )
  }
  if (filledGroups.length > 0) {
    params.append(
      'group_is_filled',
      filledGroups.map((group) => `${group.key}:${group.value}`).join(','),
    )
  }
  if (blankGroups.length > 0) {
    params.append(
      'group_is_blank',
      blankGroups.map((group) => `${group.key}:${group.value}`).join(','),
    )
  }
  const query = params.toString()
  if (!search) {
    return query
  }

  return `${query}&search=${search}`
}

export function useMarkTechCheckComplete() {
  const {event} = useEvent()
  const {client} = useOrganization()

  return (attendee: Attendee) => {
    const url = api(`/events/${event.id}/attendees/${attendee.id}/tech_check`)
    return client.patch<Attendee>(url, {})
  }
}

export function useMarkTechCheckIncomplete() {
  const {event} = useEvent()
  const {client} = useOrganization()

  return (attendee: Attendee) => {
    const url = api(`/events/${event.id}/attendees/${attendee.id}/tech_check`)

    return client.delete<Attendee>(url)
  }
}

export function useExport(options: {
  query: string
  onSuccess?: (result: AttendeeExportResult) => void
  onError: (error: string | null) => void
}) {
  const {query, onSuccess, onError} = options
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/attendees/export?${query}`)

  return () =>
    client
      .get<AttendeeExportResult>(url)
      .then((res) => {
        if (onSuccess) {
          onSuccess(res)
        }
        return res
      })
      .catch((e) => {
        onError(e.message)
        throw e
      })
}

export function useImport(options: {
  onError: (error: string | null) => void
  onSuccess?: (result: AttendeeImportResult) => void
}) {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/attendees/import`)

  return (file: File) => {
    const formData = new FormData()

    formData.set('file', file)
    return client
      .post<AttendeeImportResult>(url, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res: AttendeeImportResult) => {
        if (options.onSuccess) {
          options.onSuccess(res)
        }

        return res
      })
      .catch((e) => {
        options.onError(e.message)
        throw e
      })
  }
}

export function useClearAttendees(options: {
  onSuccess?: (result: ClearAttendeesResult) => void
  onError: (error: string | null) => void
}) {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/attendees `)

  return () =>
    client
      .delete<ClearAttendeesResult>(url)
      .then((res) => {
        if (options.onSuccess) {
          options.onSuccess(res)
        }
        return res
      })
      .catch((e) => {
        options.onError(e.message)
        throw e
      })
}

export function useDownloadCertificate(options: {
  query: string
  onSuccess?: (result: DownloadCertificateResult) => void
  onError: (error: string | null) => void
}) {
  const {query, onSuccess, onError} = options
  const {client} = useOrganization()

  return (certificate: Certificate) => {
    const url = api(`/certificates/${certificate.id}/export?${query}`)

    return client
      .post<DownloadCertificateResult>(url)
      .then((res) => {
        if (onSuccess) {
          onSuccess(res)
        }
        return res
      })
      .catch((e) => {
        onError(e.message)
        throw e
      })
  }
}

export function useSendCertificate(options: {
  query: string
  onSuccess?: (result: SendCertificateResult) => void
  onError: (error: string | null) => void
}) {
  const {query, onSuccess, onError} = options
  const {client} = useOrganization()

  return (certificate: Certificate) => {
    const url = api(`/certificates/${certificate.id}/send?${query}`)

    return client
      .post<SendCertificateResult>(url)
      .then((res) => {
        if (onSuccess) {
          onSuccess(res)
        }
        return res
      })
      .catch((e) => {
        onError(e.message)
        throw e
      })
  }
}

export function buildFilterParams(values: Partial<Filters>) {
  const params: Record<string, any> = {
    tags_includes: values.includedTags?.join(','),
    tags_excludes: values.excludedTags?.join(','),
    group_is: values.correspondingGroups
      ? dictParam(values.correspondingGroups)
      : undefined,
    group_is_not: values.oppositeGroups
      ? dictParam(values.oppositeGroups)
      : undefined,
    group_is_filled: values.filledGroups
      ? dictParam(values.filledGroups)
      : undefined,
    group_is_blank: values.blankGroups
      ? dictParam(values.blankGroups)
      : undefined,
  }

  params['is_active'] = values.activeOnly
  params['password_created'] = values.passwordCreated
  params['waiver_signed'] = values.waiverSigned
  params['tech_check_completed'] = values.techCheckCompleted

  const checkedInValue = () => {
    if (typeof values.checkedIn === 'boolean') {
      return values.checkedIn ? 'checked_in' : 'not_checked_in'
    }

    return values.checkedIn
  }

  params['checked_in_status'] = checkedInValue()

  // Remove undefined values to avoid clearing unintended params:
  // - any value = set
  // - null = unset
  // - undefined = unchanged
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return acc
    }

    if (!value || typeof value === 'string') {
      acc[key] = value
      return acc
    }

    acc[key] = String(value)
    return acc
  }, {} as Record<string, string | null>)
}

export function dictParam(groupRules: Record<string, string>[]) {
  // Dictionaries / Objects in the URL are defined as comma separated lists
  // with a colon (:) separating key/value pairs.
  // ie. ?groups=Ticket:VIP,status:is_active

  return groupRules.reduce((acc, {key, value}, index) => {
    const isFirst = index === 0
    const comma = isFirst ? '' : ','

    const pair = `${key}:${value}`
    return `${acc}${comma}${pair}`
  }, '')
}

function useLogMissingAttendees(attendees: Attendee[]) {
  const missingAttendees = attendees.filter((attendee) => !attendee)
  const numMissingAttendees = missingAttendees.length
  const {
    event: {id: eventId},
  } = useEvent()

  useEffect(() => {
    if (numMissingAttendees > 0) {
      Sentry.captureException('Missing attendees', {
        extra: {
          event_id: eventId,
        },
      })
    }
  }, [numMissingAttendees, eventId])
}
