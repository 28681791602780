import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'

interface GetBlockLayoutParams {
  template: PurchasePageTemplate
  sectionId: string | null
  blockId: string | null
}

export function getBlockLayout(params: GetBlockLayoutParams) {
  const {template, sectionId, blockId} = params
  if (!sectionId || !blockId) {
    return null
  }
  const sourceSection = template.sections[sectionId]

  const desktopIndex = sourceSection.layouts.desktop.findIndex(
    (layout) => layout.i === blockId,
  )

  const mobileIndex = sourceSection.layouts.mobile.findIndex(
    (layout) => layout.i === blockId,
  )

  const desktop = sourceSection.layouts.desktop[desktopIndex]
  const mobile = sourceSection.layouts.mobile[mobileIndex]

  if (!desktop || !mobile) {
    return null
  }

  return {
    desktop: {
      index: desktopIndex,
      ...desktop,
    },
    mobile: {
      index: mobileIndex,
      ...mobile,
    },
  }
}
