import React from 'react'
import Button from 'lib/ui/Button'
import {now} from 'lib/date-time'
import {v4 as uuid} from 'uuid'
import {usePanelsUpdate} from 'Event/template/Panels'

export default function AddAgendaButton(props: {className?: string}) {
  const save = usePanelsUpdate()

  const id = uuid()

  const addAgenda = () => {
    save({
      agendaList: {
        items: {
          [id]: {
            text: 'Event',
            description: '',
            startDate: now(),
            endDate: now(),
            link: null,
            isVisible: true,
            speakers: [],
          },
        },
      },
    })
  }

  return (
    <Button
      size="large"
      variant="contained"
      color="secondary"
      fullWidth
      aria-label="add agenda event"
      onClick={addAgenda}
      className={props.className}
    >
      Add Agenda Event
    </Button>
  )
}
