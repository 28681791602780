import React from 'react'
import styled from 'styled-components'
import {Label, Description} from 'lib/ui/typography'
import {useObieService, Category} from 'organization/Obie/ObieServiceProvider'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOrganization} from 'organization/OrganizationProvider'
import {routesWithValue} from 'lib/url'
import bot from 'assets/images/icons/bot.svg'

export default function Obie() {
  const {categories} = useObieService()
  const {routes} = useOrganization()

  const obieRoute = (category: Category) =>
    routesWithValue(
      ':category',
      String(category.id),
      routes.obie.categories[':category'],
    )

  return (
    <Container>
      <TitleBox>
        <img src={bot} alt="OBIE robot" />
        <Title>OBIE</Title>
        <BetaBadge>Beta</BetaBadge>
      </TitleBox>
      <Box>
        <StyledLabel>
          Welcome to Obie, the Offer Builder Inspiration Engine!
        </StyledLabel>
        <StyledDescription>
          Obie can help you create the building blocks for your Live Event!
          Select the block type that you wish to create by clicking on it below!
        </StyledDescription>
      </Box>

      <List>
        {categories.map((category: Category, index: number) => {
          return (
            <Item key={index} disableStyles to={obieRoute(category).root}>
              <ItemText>{category.name}</ItemText>
            </Item>
          )
        })}
      </List>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[15]};
  padding-bottom: ${(props) => props.theme.spacing[13]};
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #000000;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => `0 ${props.theme.spacing[4]}`};
`

const BetaBadge = styled.span`
  margin: 10px;
  padding: 2px 4px;
  background-color: #3490dc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: white;
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${(props) => props.theme.spacing[2]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledDescription = styled(Description)`
  max-width: 600px;
`

const Item = styled(RelativeLink)`
  flex: 0 1 calc(33.33% - 10px);
  background-color: #ffffff;
  padding: ${(props) => props.theme.spacing[2]};
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
`

const ItemText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  text-align: center;
`
