import React from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import Configurable from 'organization/Event/Configurable'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {uuid} from 'lib/uuid'
import {
  PurchasePageBlock,
  PurchasePageTemplate,
} from 'Event/Marketplace/purchase-page'
import {
  ThankYouPageBlock,
  ThankYouPageTemplate,
} from 'Event/Marketplace/thank-you-page'
import {JsonSave} from 'lib/JsonUpdateProvider'
import SectionConfig from 'organization/Marketplace/config/SectionConfig'
import ConfigurableSection from 'organization/Marketplace/config/ConfigurableSection'

interface MoveableSectionProps {
  sectionId: string
  canMoveUp: boolean
  canMoveDown: boolean
  template: PurchasePageTemplate | ThankYouPageTemplate
  sectionIds: string[]
  updateTemplate: JsonSave<PurchasePageTemplate | ThankYouPageTemplate>
  availableBlocks: Array<PurchasePageBlock['type'] | ThankYouPageBlock['type']>
  ButtonConfig: React.FC<any>
}

export default function MoveableSection(props: MoveableSectionProps) {
  const {
    sectionId,
    canMoveDown,
    canMoveUp,
    template,
    sectionIds,
    updateTemplate,
    availableBlocks,
    ButtonConfig,
  } = props

  const {moveUp, moveDown} = useMoveSection(sectionId, template, updateTemplate)
  const {sections} = template

  const copy = () => {
    updateTemplate({
      sections: {
        [uuid()]: sections[sectionId],
      },
    })
  }

  return (
    <Configurable
      editableProps={{
        moveDown: canMoveDown ? moveDown : () => {},
        moveUp: canMoveUp ? moveUp : () => {},
        onCopy: copy,
        copyRightSpacing: 27,
      }}
      aria-label="section config"
      alwaysShowButtons={true}
    >
      <SectionConfig
        id={sectionId}
        section={sections[sectionId]}
        updateTemplate={updateTemplate}
      />
      <ConfigurableSection
        section={sections[sectionId]}
        id={sectionId}
        key={sectionId}
        template={template}
        sectionIds={sectionIds}
        updateTemplate={updateTemplate}
        availableBlocks={availableBlocks}
        ButtonConfig={ButtonConfig}
      />
    </Configurable>
  )
}

function useMoveSection(
  sectionId: string,
  template: PurchasePageTemplate | ThankYouPageTemplate,
  updateTemplate: JsonSave<PurchasePageTemplate | ThankYouPageTemplate>,
) {
  const {sections} = template

  const ids = orderedIdsByPosition(sections)
  const index = ids.indexOf(sectionId)
  const moved = Array.from(ids)

  const insertAt = (newIndex: number) => {
    const [removed] = moved.splice(index, 1)
    moved.splice(newIndex, 0, removed)
    updateTemplate({
      sections: createPositions(moved),
    })
  }

  const moveUp = () => insertAt(index - 1)
  const moveDown = () => insertAt(index + 1)

  return {
    moveUp,
    moveDown,
  }
}
