import React from 'react'
import {PurchaseFormBlock} from '.'
import {getFontStyles} from '../../lib/font'

interface PurchaseSummaryColumn extends PurchaseFormBlock {
  children: string
}

export default function PurchaseSummaryColumnLabel(
  props: PurchaseSummaryColumn,
) {
  const {children, summaryColumnLabel} = props
  return (
    <span
      style={{
        ...getFontStyles(summaryColumnLabel?.font ?? null),
        fontSize: summaryColumnLabel?.fontSize ?? '16px',
      }}
    >
      {children}
    </span>
  )
}
