import {onChangeCheckedHandler, handleAutocomplete} from 'lib/dom'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import MuiTextField from '@material-ui/core/TextField'
import {Label} from 'lib/ui/typography'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import React from 'react'
import {Controller} from 'react-hook-form'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {Autocomplete} from '@material-ui/lab'
import withStyles from '@material-ui/core/styles/withStyles'
import styled from 'styled-components'
import {spacing} from 'lib/ui/theme'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {HashMap, orderedIdsByPosition} from 'lib/list'
import {useConfig} from 'organization/Event/Configurable'
import {Speaker} from 'Event/Speakers'

export default function Settings(props: {
  agenda: Agenda
  id: string
  sectionId: string
}) {
  const {agenda, id, sectionId} = props
  const {
    form: {control, register, watch, setValue},
  } = useConfig()
  const {speakers, featuredSpeakers} = useTownhallTemplate()

  const allSpeakers: HashMap<Speaker> = {
    ...featuredSpeakers.items,
    ...speakers.items,
  }

  const availableSpeakerIds = orderedIdsByPosition(allSpeakers)
  const agendaSpeakerIds = agenda.speakers

  // If the start date has been modified we'll use that, else
  // we'll use whatever the current set date is.
  const startDate = watch('startDate') || agenda.startDate

  const endDate = watch('endDate')
  const hasUpdatedEndDate = Boolean(endDate)

  const handleStartDate = (onChange: (...event: any[]) => void) => (
    date: MaterialUiPickersDate,
  ) => {
    if (!date) {
      throw new Error('Date is required')
    }

    const value = date.toISOString()

    onChange(value)

    // If end date hasn't been set, we'll automatically set it forward
    // to the start date.
    if (!hasUpdatedEndDate) {
      setValue('endDate', value)
    }
  }

  const handleEndDate = (onChange: (...event: any[]) => void) => (
    date: MaterialUiPickersDate,
  ) => {
    if (!date) {
      throw new Error('Date is required')
    }

    onChange(date.toISOString())
  }

  return (
    <>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.isVisible`}
        control={control}
        defaultValue={agenda.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />
      <Label>Event</Label>
      <TextField
        name={`dashboardSections.${sectionId}.items.${id}.text`}
        defaultValue={agenda.text}
        aria-label="agenda text"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Label>Description</Label>
      <TextField
        name={`dashboardSections.${sectionId}.items.${id}.description`}
        defaultValue={agenda.description}
        aria-label="agenda description"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.startDate`}
        control={control}
        defaultValue={agenda.startDate}
        render={({value, onChange}) => {
          return (
            <LocalizedDateTimePicker
              value={value}
              onChange={handleStartDate(onChange)}
              fullWidth
              label="Start"
              minDate={new Date()}
              inputProps={{
                'aria-label': 'agenda start date',
              }}
            />
          )
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.endDate`}
        control={control}
        defaultValue={agenda.endDate}
        render={({value, onChange}) => (
          <LocalizedDateTimePicker
            value={value}
            onChange={handleEndDate(onChange)}
            fullWidth
            label="End"
            minDate={startDate}
            inputProps={{
              'aria-label': 'agenda end date',
            }}
          />
        )}
      />
      <Label>Link</Label>
      <TextField
        name={`dashboardSections.${sectionId}.items.${id}.link`}
        defaultValue={agenda.link || ''}
        aria-label="agenda link"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <label>Speakers</label>
      <Controller
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.speakers`}
        defaultValue={agendaSpeakerIds}
        render={({value, onChange}) => (
          <StyledAutocomplete
            multiple
            filterSelectedOptions
            options={availableSpeakerIds}
            value={value}
            onChange={handleAutocomplete(onChange)}
            aria-label="speaker selector"
            noOptionsText="No Speakers"
            getOptionLabel={(option) => allSpeakers[option].name}
            getOptionSelected={(option) => option === value}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'speaker input',
                }}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(id) => <div>{allSpeakers[id].name}</div>}
          />
        )}
      />
    </>
  )
}

const StyledAutocomplete = withStyles({
  root: {
    flex: 1,
    marginRight: spacing[3],
    marginBottom: 0,
  },
})(Autocomplete) as typeof Autocomplete

const StyledTextField = styled(MuiTextField)`
  margin-bottom: 0;
`
